import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { CompanyDto } from "app/api/octopus-web/models";
import { CompanyService } from "app/api/octopus-web/services";
import { FieldConfig } from "app/layouts/naxos-framework/form/field.interface";
import { hasValue, isGuid } from "app/layouts/naxos-framework/services";
import { AuthService } from "app/security/services";
import { ContactData } from "../models/contact-data.interface";
import { FormService } from "../models/form-service.interface";

@Injectable({
  providedIn: "root",
})
export class ContactFormService implements FormService {
  isDynamic: boolean = false;
  formData: ContactData;
  title = "Contact";
  icon = "contact_page";

  constructor(
    private companyService: CompanyService,
    private authService: AuthService,
  ) { }

  onSave(value: any): Promise<any> {
    return new Promise<any>(resolve => resolve(value));
  }

  onCancel(): Promise<void> {
    return new Promise<void>(resolve => resolve());
  }

  onDelete(): Promise<void> {
    return new Promise<void>(resolve => resolve());
  }

  async getFields(): Promise<FieldConfig[]> {
    const fields = [
      {
        type: "input",
        inputType: "text",
        name: "Name",
        label: "Name",
        value: this.formData?.name,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Name is required",
          },
        ],
      },
      {
        type: "input",
        inputType: "email",
        name: "email",
        label: "Email",
        value: this.formData?.email,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Email is required",
          },
          {
            name: "pattern",
            validator: Validators.pattern(
              "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
            ),
            message: "Invalid email",
          },
        ],
      },
      {
        type: "input",
        inputType: "text",
        name: "subject",
        label: "Subject",
        value: this.formData?.subject,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Subject is required",
          },
        ],
      },
      {
        type: "textarea",
        name: "message",
        label: "Message",
        value: this.formData?.message,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Message is required",
          },
        ],
      },
    ];

    return new Promise<FieldConfig[]>(resolve => resolve(fields));
  }
}
