import { Component, OnInit } from "@angular/core";
import { NaxosNotificationService } from "app/security/services/NaxosNotification.service";
import { SortContentItemsService } from "app/services/sort-content-items.service";

@Component({
  selector: "app-support",
  templateUrl: "./support.component.html",
  styleUrls: ["./support.component.css"],
})
export class SupportComponent implements OnInit {
  support;

  constructor(
    private itemsService: SortContentItemsService,
    private notifications: NaxosNotificationService,
  ) {}

  ngOnInit() {
    this.loadDescription();
  }

  loadDescription() {
    this.itemsService
      .GetFlattenItemByCode({
        applicationCode: "octopus-web",
        itemCode: "page-support",
      })
      .subscribe((support) => {
        this.itemsService.sortSubItems(support);
        this.support = support;
      });
  }
}
