import { CountryDTO } from "app/api/address/models/country-dto";
import {
  Component,
  OnInit,
  Input,
  Inject,
  Output,
  EventEmitter,
  Optional,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { CompanyDto, UserDto } from "app/api/octopus-web/models";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { hasValue, isGuid } from "app/layouts/naxos-framework/services";
import { CompanyService } from "app/api/octopus-web/services";
import { CitiesService, CountriesService } from "app/api/address/services";
import { CityDTO } from "app/api/address/models";
import { NgForm } from "@angular/forms";
import { AuthService } from "app/security/services";
import { FixedSizeVirtualScrollStrategy } from "@angular/cdk/scrolling";
import {Title} from '@angular/platform-browser';
import PlaceResult = google.maps.places.PlaceResult;
import { Subscription } from "rxjs";
import { GoogleMapService } from "app/layouts/shared/services/GoogleMap.service";

@Component({
  selector: "app-company-form",
  templateUrl: "./company-form.component.html",
  styleUrls: ["./company-form.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class CompanyFormComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() EnableSave = true;
  @Input() EnableDelete = true;
  @Input() EnableCancel = true;
  @Input() isDialog = false;

  @Input() formData: CompanyDto = null;

  @Output() OnSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnCancel: EventEmitter<any> = new EventEmitter<any>();

  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;
  
  IsReadOnly = false;
  model: CompanyDto = null;
  title = "Entreprise";
  icon = "nature_people";
  allCities: CityDTO[];
  allCountries: CountryDTO[];
  public isFormReady = false;
  public disableCitiesFromZipCode = true;
  public disableCitiesFromCity = true;
  @ViewChild("companyForm") public companyForm: NgForm;
  @ViewChild('addressText') addressText!: ElementRef;
  protected placeSubscription: Subscription;
  
  constructor(
    private companyService: CompanyService,
    public addressService: CitiesService,
    public countriesService: CountriesService,
    private authService: AuthService,
    private googleMapService: GoogleMapService
  ) {}
  ngAfterViewInit(): void {
    this.googleMapService.getPlaceAutocomplete(this.addressText);
  }

  ngOnInit(): void {
    this.zoom = 10;
    this.latitude = 52.520008;
    this.longitude = 13.404954;

    this.setCurrentPosition();

    this.setModel();
    this.IsReadOnly = !this.authService.isNaxosAdmin && !this.authService.isCompanyAdmin;
    this.countriesService.GetAll().subscribe((countries) => {
      this.allCountries = countries;
      this.enableCountryFields();
      this.isFormReady = true;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setModel();
  }

  onAddressChange(): void {
    this.placeSubscription =
    this.googleMapService.placeObservable.subscribe(
      (place) => {   
        this.model.lng = place.geometry.location.lng();
        this.model.lat = place.geometry.location.lat();
        this.model.institutionStreet = this.googleMapService.getStreet(place);
        this.model.institutionStreetNumber = this.googleMapService.getStreetNumber(place);
        this.model.institutionCity = this.googleMapService.getCity(place);
        this.model.institutionCountry = this.googleMapService.getCountry(place);
        this.model.institutionZipCode = this.googleMapService.getPostCode(place);
      }
    );
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  OnCountryAutocompleteChoose(country) {
    this.model.institutionCountry = country.isoCountryCode;
    this.enableCountryFields();
  }
  OnZipCodeAutocompleteChoose(city) {
    this.model.institutionCity = city.name;
  }
  OnCityAutocompleteChoose(city) {
    this.model.institutionZipCode = city.zipCode;
  }

  onKeyCountry(event: any) {
    this.disableCountryFields();

    if (this.model.institutionCountry == "") {
      this.model.institutionCity = "";
      this.model.institutionZipCode = "";
    } else {
      this.allCities = null;
    }

    if (
      event.key !== "ArrowDown" &&
      event.key !== "ArrowUp" &&
      this.model.institutionCountry !== ""
    ) {
      this.countriesService
        .GetCountryByLanguageAndName({
          isoLanguageCode: "fr",
          name: this.model.institutionCountry,
        })
        .toPromise()
        .then((data) => {
          this.allCountries = data;
        });
    } else {
      this.allCountries = null;
    }
  }

  disableCountryFields() {
    this.disableCitiesFromZipCode = true;
    this.disableCitiesFromCity = true;
  }

  enableCountryFields() {
    if (
      this.model.institutionCountry !== null &&
      this.model.institutionCountry !== ""
    ) {
      this.disableCitiesFromZipCode = false;
      this.disableCitiesFromCity = false;
    } else {
      this.disableCitiesFromZipCode = true;
      this.disableCitiesFromCity = true;
    }
  }

  //Search on zip code
  OnSearchZipCode(event: any): void {
    if (
      event.key !== "ArrowDown" &&
      event.key !== "ArrowUp" &&
      this.model.institutionZipCode !== ""
    ) {
      this.addressService
        .GetCityByZipCode({
          zipCode: this.model.institutionZipCode,
          isoCountryCode: this.model.institutionCountry,
        })
        .toPromise()
        .then((data) => {
          this.allCities = data;
        });
    } else {
      this.allCities = null;
    }
  }

  //Search on city name
  OnSearchCity(event: any): void {
    if (
      event.key !== "ArrowDown" &&
      event.key !== "ArrowUp" &&
      this.model.institutionCity !== ""
    ) {
      this.addressService
        .GetCityByZipCode({
          zipCode: this.model.institutionZipCode,
          isoCountryCode: this.model.institutionCountry,
        })
        .toPromise()
        .then((data) => {
          this.allCities = data;
        });
    } else {
      this.allCities = null;
    }
  }

  public onSave() {
    event.preventDefault();
    event.stopPropagation();
    if (this.companyForm.valid) {
      const value = this.companyForm.form.getRawValue();
      this.OnSave.emit(value);
    }
  }

  public onCancel(): void {
    this.OnCancel.emit();
  }

  setModel() {
    if (hasValue(this.formData)) {
      this.model = JSON.parse(JSON.stringify(this.formData));
    } else {
      this.model = {} as CompanyDto;
    }
    this.model.currency = 'EUR';
  }


}
