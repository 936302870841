/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CmApiConfiguration, CmApiConfiguration as __Configuration } from '../cm-api-configuration';
import { StrictHttpResponse, StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { BehaviorSubject, Observable, Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter, filter, map } from 'rxjs/operators';
import { RequestBuilder } from 'app/api/octopus-web/request-builder';
import { apiSettings } from 'environments/environment';
import { LicenceDto } from 'app/api/licence/models';
import { InfoDto } from '../models/info-dto';

@Injectable({
  providedIn: 'root',
})
class CmService extends __BaseService {

  public isCmServiceEnable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  licenseKey: string = "";

  constructor(
    config: CmApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  public isServiceEnable() {
    return apiSettings.OctopusCmApiEndPoint != "";
  }

  public setService(rootUrl: string, license: LicenceDto) {
    this.rootUrl = rootUrl;
    this.licenseKey = license.licenceKey;
    this.isCmServiceEnable.next(true);
  }

  /**
     * Path part for operation GetAllInfo
  */
  static readonly GetAllInfo = '/api/Info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAllInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllInfo$Response(): Observable<StrictHttpResponse<InfoDto[]>> {
    const rb = new RequestBuilder(this.rootUrl, CmService.GetAllInfo, 'get');

    rb.header("licence", this.licenseKey);

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InfoDto[]>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetAllDomainOfApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllInfo(): Observable<InfoDto[]> {

    return this.GetAllInfo$Response().pipe(
      map((r: StrictHttpResponse<InfoDto[]>) => r.body as InfoDto[])
    );
  }
}

module CmService {
}

export { CmService }
