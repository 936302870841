/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RequestDto } from '../models/request-dto';

@Injectable({
  providedIn: 'root',
})
export class RequestsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation requestsGetAll
   */
  static readonly RequestsGetAllPath = '/api/Requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAll$Response(params?: {
  }): Observable<StrictHttpResponse<Array<RequestDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RequestsService.RequestsGetAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequestDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAll(params?: {
  }): Observable<Array<RequestDto>> {

    return this.GetAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RequestDto>>) => r.body as Array<RequestDto>)
    );
  }

  /**
   * Path part for operation requestsPost
   */
  static readonly RequestsPostPath = '/api/Requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Post()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Post$Response(params: {
    body: RequestDto
  }): Observable<StrictHttpResponse<RequestDto>> {

    const rb = new RequestBuilder(this.rootUrl, RequestsService.RequestsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Post$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Post(params: {
    body: RequestDto
  }): Observable<RequestDto> {

    return this.Post$Response(params).pipe(
      map((r: StrictHttpResponse<RequestDto>) => r.body as RequestDto)
    );
  }

  /**
   * Path part for operation requestsGetById
   */
  static readonly RequestsGetByIdPath = '/api/Requests/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<RequestDto>> {

    const rb = new RequestBuilder(this.rootUrl, RequestsService.RequestsGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetById(params: {
    id: string;
  }): Observable<RequestDto> {

    return this.GetById$Response(params).pipe(
      map((r: StrictHttpResponse<RequestDto>) => r.body as RequestDto)
    );
  }

  /**
   * Path part for operation requestsPut
   */
  static readonly RequestsPutPath = '/api/Requests/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Put()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Put$Response(params: {
    id: string;
    body: RequestDto
  }): Observable<StrictHttpResponse<RequestDto>> {

    const rb = new RequestBuilder(this.rootUrl, RequestsService.RequestsPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Put$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Put(params: {
    id: string;
    body: RequestDto
  }): Observable<RequestDto> {

    return this.Put$Response(params).pipe(
      map((r: StrictHttpResponse<RequestDto>) => r.body as RequestDto)
    );
  }

  /**
   * Path part for operation requestsDelete
   */
  static readonly RequestsDeletePath = '/api/Requests/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  Delete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<RequestDto>> {

    const rb = new RequestBuilder(this.rootUrl, RequestsService.RequestsDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Delete(params: {
    id: string;
  }): Observable<RequestDto> {

    return this.Delete$Response(params).pipe(
      map((r: StrictHttpResponse<RequestDto>) => r.body as RequestDto)
    );
  }

  /**
   * Path part for operation requestsOnGoingRequest
   */
  static readonly RequestsOnGoingRequestPath = '/api/Requests/OnGoingRequest{companyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `OnGoingRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  OnGoingRequest$Response(params: {
    companyId: string;
  }): Observable<StrictHttpResponse<RequestDto>> {

    const rb = new RequestBuilder(this.rootUrl, RequestsService.RequestsOnGoingRequestPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `OnGoingRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  OnGoingRequest(params: {
    companyId: string;
  }): Observable<RequestDto> {

    return this.OnGoingRequest$Response(params).pipe(
      map((r: StrictHttpResponse<RequestDto>) => r.body as RequestDto)
    );
  }

  /**
   * Path part for operation requestsGetAllOpen
   */
  static readonly RequestsGetAllOpenPath = '/api/Requests/GetAllOpen';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAllOpen()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllOpen$Response(params?: {
  }): Observable<StrictHttpResponse<Array<RequestDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RequestsService.RequestsGetAllOpenPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequestDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetAllOpen$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllOpen(params?: {
  }): Observable<Array<RequestDto>> {

    return this.GetAllOpen$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RequestDto>>) => r.body as Array<RequestDto>)
    );
  }

}
