import { LicencesService } from 'app/api/licence/services';
import { Component, OnInit } from '@angular/core';
import { RequestsService } from 'app/api/licence/services';
import { RequestDto } from 'app/api/licence/models';
import { UserService, CompanyService } from 'app/api/octopus-web/services';
import { CompanyDto } from 'app/api/octopus-web/models';
import { from, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { hasValue } from 'app/layouts/naxos-framework/services';

@Component({
  selector: 'app-requests-view',
  templateUrl: './requests-view.component.html',
  styleUrls: ['./requests-view.component.scss']
})
export class RequestsViewComponent implements OnInit {
  title = "Current requests";
  icon = "receipt";

  items: {
    request: RequestDto,
    company: CompanyDto
  }[];
  isFormReady = false;
  canUpdate = false;

  constructor(
    private licenseService: LicencesService,
    private requestService: RequestsService,
    private userInfoService: UserService,
    private companyService: CompanyService
  ) { }

  ngOnInit() {
    this.loadData();

    this.userInfoService
      .GetCurrentUser()
      .toPromise()
      .then(data => {
        this.canUpdate = data.userRole === 0;
      });
  }

  loadData(): any {
    forkJoin(
      this.requestService.GetAll(),
      this.companyService.GetAll())
    .subscribe((values: [RequestDto[], CompanyDto[]]) => {
        this.items =
          values[0].map((val: RequestDto) =>
            ({ request: val, company: values[1].find(x => x.id === val.companyId) }));
      });
  }

  approveLicenceRequest(item) {
    if(!hasValue(item.request))
      return;
    item.request.status = 'Validated';
    this.requestService.Put({ id: item.request.id, body: item.request })
    .subscribe((request) => {
      this.loadData();
      if (request.isTrialLicence) {
        this.licenseService.PostTrial({ companyId: request.companyId }).subscribe();
      }
      else {
        this.licenseService.GetAllForCompany({ companyId: request.companyId }).subscribe((licences) => {
          this.licenseService.Upgrade({ key: licences[0].licenceKey }).subscribe();
        });
      }
    })
  }

  deleteLicenceRequest(item) {
    if(!hasValue(item.request))
      return;
    this.requestService.Delete(item.request.id).subscribe((request) => {
      this.loadData();
    });
  }
}
