import { ManagementModule } from './layouts/management/management.module';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { PublicModule } from './layouts/public/public.module';
import { MsalGuard } from '@azure/msal-angular';
import { LoginRedirectComponent } from './components/login-redirect/login-redirect.component';
import { LogoutComponent } from './components/logout/logout.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { OctopusTourModule } from './layouts/octopus-tour/octopus-tour.module';
import { OctopusTourLayoutComponent } from './layouts/octopus-tour/components/octopus-tour-layout/octopus-tour-layout.component';
import { PublicLayoutComponent } from './layouts/public/public-layout/public-layout.component';
import { AdminLayoutComponent } from './layouts/management/components/admin-layout/admin-layout.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: "public",
    pathMatch: "full"
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "auth",
    component: LoginRedirectComponent,
    canActivate: [MsalGuard]
  },
  {
    path: "signup",
    component: SignupComponent,
  },
  {
    path: "logout",
    component: LogoutComponent,
    canActivate: [MsalGuard]
  },
  {
    path: "management",
    component: AdminLayoutComponent,
    canActivate: [MsalGuard],
    loadChildren: () => ManagementModule,
  },
  {
    path: "public",
    component: PublicLayoutComponent,
    loadChildren: () => PublicModule,
  },
  {
    path: "octopus-tour",
    component: OctopusTourLayoutComponent,
    loadChildren: () => OctopusTourModule,
  },
  { path: "**", redirectTo: "public" }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot(
      routes,
      {
        onSameUrlNavigation: "reload",
        relativeLinkResolution: 'legacy',
        // enableTracing: true, // <-- debugging purposes only
      }
    )
  ],
  exports: [],
  providers: []
})
export class AppRoutingModule {}
