import { Component, OnInit } from '@angular/core';
import { NaxosNotificationService } from 'app/security/services/NaxosNotification.service';
import { SortContentItemsService } from 'app/services/sort-content-items.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  home;
  description;
  assets;
  changeText1: boolean = false;
  changeText2: boolean = false;
  changeText3: boolean = false;

  text1 = "Diminuer vos délais de traitement et optimiser votre recouvrement. Saisie aisée des données créateurs et entreprises. Edition des contrats clients."
  + "Production des fichiers de prélèvement SEPA. Traitement du retour des banques Lettres de relance et de mise en demeure. " +
  "Production des écritures comptables injectables directement  dans la comptabilité.";

  text2 = "Les contrôles d’accès, la traçabilité des opérations et des procédures de sauvegarde et de reprise mettent " +
   "à l’abri vos données d’une utilisation frauduleuse ou malveillante, voire d’une défaillance éventuelle du système." +
   "Gestions des identités et contrôle d’accès. Gestion des habilitations selon des profils préétablis (séparation des rôles)" +
   "Audit : recherche et consultation de l’historique des transactions effectuées. Hébergement local respectueux du RGPD." + 
   "Procédures de sauvegarde et de reprise comprises dans l’offre.";

   text3 = "Grâce à Naxos Octopus, contrôler  et suivez au mieux votre activité de financeur solidaire. " +
   "Suivi et historique de vos contacts avec le créateur. " + 
   "Relations avec les accompagnateurs. " +
   "Suivi de la situation du projet et de l’entreprise. " +
   "Suivi des incidents de paiement. " +
   "Flexibilité des modalités de remboursement, des possibilités de restructuration. " + 
   "Rapports (comptables et métiers), listes paramétrables et adaptables."

  constructor(
    private itemsService: SortContentItemsService,
    private notifications: NaxosNotificationService,
  ) {}

  ngOnInit() {
    this.loadDescription();
    this.imgSleep()    
  }

  loadDescription() {
    this.itemsService
      .GetFlattenItemByCode({
        applicationCode: "octopus-web",
        itemCode: "page-home",
      })
      .subscribe((home) => {
        this.itemsService.sortSubItems(home);
        this.home = home;
        this.description = (<any[]>home.Items).filter(
          (obj) => obj.Code === "page-home-description"
        )[0];
      });
      this.itemsService
      .GetFlattenItemByCode({
        applicationCode: "octopus-web",
        itemCode: "assets",
      })
      .subscribe((assets) => {
        this.itemsService.sortSubItems(assets);
        this.assets = assets;
      });
  }

  imgSleep(){
    const imgSleep = document.getElementById("imgSleep");

    setTimeout(() => {
      imgSleep.style.visibility = 'visible'; 
      }, 1800);
  }
}
