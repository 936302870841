import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { MatDialogModule } from "@angular/material/dialog";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { TranslateModule } from "@ngx-translate/core";
import { SignupComponent } from './signup/signup.component';
import { SidebarComponent } from "./sidebar/sidebar.component";
import { FixedpluginComponent } from "../layouts/management/components/fixedplugin/fixedplugin.component";
import { LoginRedirectComponent } from "./login-redirect/login-redirect.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    ButtonsModule.forRoot(),
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    LoginComponent,
    LogoutComponent,
    SignupComponent,
    SidebarComponent,
    LoginRedirectComponent,
  ],
  entryComponents: [],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
  ],
})
export class ComponentsModule {}
