/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration, CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse, StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { BehaviorSubject, Observable, Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter, filter, map } from 'rxjs/operators';
import { RequestBuilder } from 'app/api/octopus-web/request-builder';
import { searchCorporateRequestDTO } from '../models/searchCorporateRequestDTO';
import { paginatedClientResponse } from '../models/paginatedClientResponse';
import { apiSettings } from 'environments/environment';
import { LicenceDto } from 'app/api/licence/models';
import { domainOfApplicationDTO } from '../models/domainOfApplicationDTO';
import { sponsor2DTO } from '../models/sponsor2DTO';
import { sponsor1DTO } from '../models/sponsor1DTO';

@Injectable({
  providedIn: 'root',
})
class CoreService extends __BaseService {

  public isCoreServiceEnable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  licenseKey: string = "";

  constructor(
    config: CoreApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  public isServiceEnable() {
    return apiSettings.OctopusCoreApiEndPoint != "";
  }

  public setService(rootUrl: string, license: LicenceDto) {
    this.rootUrl = rootUrl;
    this.licenseKey = license.licenceKey;
    this.isCoreServiceEnable.next(true);
  }

  /*
   * Path part for operation searchCorporatesByCriteria
  */
  static readonly searchCorporatesByCriteriaPath = '/api/Corporates/searchCorporatesByCriteria';

  Post$Response(params: searchCorporateRequestDTO
  ): Observable<StrictHttpResponse<paginatedClientResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CoreService.searchCorporatesByCriteriaPath, 'post');
    if (params) {
      rb.body(params, 'application/json');
    }

    rb.header("licence", this.licenseKey);

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<paginatedClientResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Post$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Post(params: searchCorporateRequestDTO
  ): Observable<paginatedClientResponse> {

    return this.Post$Response(params).pipe(
      map((r: StrictHttpResponse<paginatedClientResponse>) => r.body as paginatedClientResponse)
    );
  }


  /**
     * Path part for operation GetAllDomainOfApplication
  */
  static readonly domainOfApplicationPath = '/api/DomainOfApplication';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAllDomainOfApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllDomainOfApplication$Response(): Observable<StrictHttpResponse<domainOfApplicationDTO[]>> {

    const rb = new RequestBuilder(this.rootUrl, CoreService.domainOfApplicationPath, 'get');

    rb.header("licence", this.licenseKey);

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<domainOfApplicationDTO[]>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetAllDomainOfApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllDomainOfApplication(): Observable<domainOfApplicationDTO[]> {

    return this.GetAllDomainOfApplication$Response().pipe(
      map((r: StrictHttpResponse<domainOfApplicationDTO[]>) => r.body as domainOfApplicationDTO[])
    );
  }

  /**
     * Path part for operation GetAllSponsor1
  */
  static readonly sponsor1Path = '/api/SetUpSponsor1s';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAllSponsor1()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllSponsor1$Response(): Observable<StrictHttpResponse<sponsor1DTO[]>> {

    const rb = new RequestBuilder(this.rootUrl, CoreService.sponsor1Path, 'get');

    rb.header("licence", this.licenseKey);

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<sponsor1DTO[]>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetAllSponsor1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllSponsor1(): Observable<sponsor1DTO[]> {

    return this.GetAllSponsor1$Response().pipe(
      map((r: StrictHttpResponse<sponsor2DTO[]>) => r.body as sponsor1DTO[])
    );
  }

  /**
     * Path part for operation GetAllSponsor2
  */
  static readonly sponsor2Path = '/api/SetUpSponsor2s';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAllSponsor2()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllSponsor2$Response(): Observable<StrictHttpResponse<sponsor2DTO[]>> {

    const rb = new RequestBuilder(this.rootUrl, CoreService.sponsor2Path, 'get');

    rb.header("licence", this.licenseKey);

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<sponsor2DTO[]>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetAllSponsor2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllSponsor2(): Observable<sponsor2DTO[]> {

    return this.GetAllSponsor2$Response().pipe(
      map((r: StrictHttpResponse<sponsor2DTO[]>) => r.body as sponsor2DTO[])
    );
  }

}

module CoreService {
}

export { CoreService }
