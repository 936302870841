import { Component, OnInit } from "@angular/core";
import { LicencesService } from "app/api/licence/services";
import { LicenceDto } from "app/api/licence/models";

@Component({
  selector: "app-alllicences-view",
  templateUrl: "./alllicences-view.component.html",
  styleUrls: ["./alllicences-view.component.scss"]
})
export class AlllicencesViewComponent implements OnInit {
  title = "All licences";
  icon = "card_membership";

  items: LicenceDto[];
  isFormReady = false;

  constructor(
    private licenceService: LicencesService,
  ) {}

  ngOnInit() {
    this.loadData();
  }

  loadData(): any {
    this.licenceService
      .GetAll()
      .toPromise()
      .then(data => {
        (this.items = data), (this.isFormReady = true);
      });
  }
}
