import { ApiModule as OctopusWebApiModule } from "./api/octopus-web/api.module";
import { ApiModule as LicenceApiModule } from "./api/licence/api.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, Injector } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { NaxosAdminGuard } from "./security/guard/naxos-admin.guard";
import { CompanyRequiredGuard } from "./security/guard/company-required.guard";
import { BrowserModule } from "@angular/platform-browser";
import { MultilevelMenuService, NgMaterialMultilevelMenuModule } from "ng-material-multilevel-menu";
import { CoreModule } from "./core/core.module";
import { MdModule } from "./md/md.module";
import { UtilsService } from "./services/utils.service";
import { MenusService } from "./services/menus.service";
import { SortContentItemsService } from "./services/sort-content-items.service";
import { CurrentSimulationService } from "./services/current-simulation.service";
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import {
  RouterModule,
  UrlSerializer,
  DefaultUrlSerializer,
  UrlTree,
} from "@angular/router";

import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";
import { SidebarModule } from "ng-sidebar";
import { AppComponent } from "./app.component";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { NaxosNotificationService, AuthService } from "./security/services";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { RequiredCompanyGuard } from "./security/guard/required-company.guard";
import { AuthGuard } from "./security";
import { CompanyAdminGuard } from "./security/guard/company-admin.guard";
import { apiSettings, interactionType, isIE } from "environments/environment";
import {
  msalConfig,
  protectedResourceMapConfig,
} from "./../environments/environment";
import { ApiModule as ContentApiModule } from "./api/content-read/api.module";
import { AddressApiModule } from "./api/address/address-api.module";
import { PublicLayoutComponent } from "./layouts/public/public-layout/public-layout.component";

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { FileSaverModule } from 'ngx-filesaver';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from "@azure/msal-browser";
import {
  MsalGuard,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from "@azure/msal-angular";
import { RoutePaths } from "./models/menus-routes/route-paths";
import { CoreApiModule } from "./api/core/core-api.module";
import { GoogleMapsModule } from "@angular/google-maps";
import { ToastrModule } from "ngx-toastr";
import { CmApiModule } from "./api/cm/cm-api.module";
import { AgmCoreModule } from "@agm/core";
import { GoogleMapService } from "./layouts/shared/services/GoogleMap.service";

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = protectedResourceMapConfig;

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: interactionType,
    loginFailedRoute: RoutePaths.loginFailed.path,
  };
}


export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    return super.parse(url.toLowerCase());
  }
}

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
};

@NgModule({
  imports: [
    OctopusWebApiModule.forRoot({ rootUrl: apiSettings.OctopusWebApiEndPoint }),
    AddressApiModule.forRoot({ rootUrl: apiSettings.AddressApiEndPoint }),
    LicenceApiModule.forRoot({ rootUrl: apiSettings.OctopusLicenceApiEndPoint }),
    ContentApiModule.forRoot({ rootUrl: apiSettings.NaxosContentApiRootUrl }),
    CoreApiModule.forRoot({ rootUrl: apiSettings.OctopusCoreApiEndPoint }),
    CmApiModule.forRoot({ rootUrl: apiSettings.OctopusCmApiEndPoint }),

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA-RsBHTlHph248498TGXQsPx9MDb64mRU',
      libraries: ['places']
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    ComponentsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    SidebarModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MsalModule,
    BrowserModule,
    PerfectScrollbarModule,
    NgMaterialMultilevelMenuModule,
    CoreModule,
    MdModule,
    FileSaverModule,
    GoogleMapsModule,
    ToastrModule.forRoot({
      preventDuplicates: false,
      progressBar: true,
      countDuplicates: true,
      extendedTimeOut: 3000,
      positionClass: 'toast-bottom-right',
    }),
    GooglePlaceModule
  ],
  declarations: [
    AppComponent,
    PublicLayoutComponent,
  ],
  providers: [
    GoogleMapService,
    MultilevelMenuService,
    LowerCaseUrlSerializer,
    NaxosNotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,

    AuthGuard,
    CompanyAdminGuard,
    RequiredCompanyGuard,
    NaxosAdminGuard,
    AuthService,
    CompanyRequiredGuard,
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    UtilsService,
    MenusService,
    SortContentItemsService,
    CurrentSimulationService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}

export let AppInjector: Injector;
