import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { FileDto } from "app/api/octopus-web/models";
import { FieldConfig } from "app/layouts/naxos-framework/form/field.interface";
import { hasValue } from "app/layouts/naxos-framework/services";

@Component({
  selector: "app-file-form",
  templateUrl: "./file-form.component.html",
  styleUrls: ["./file-form.component.scss"],
})
export class FileFormComponent implements OnInit, OnChanges {
  @Input() EnableSave = true;
  @Input() EnableDelete = true;
  @Input() EnableCancel = true;
  @Input() isDialog = false;
  @Output() OnSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnCancel: EventEmitter<any> = new EventEmitter<any>();

  @Input() formData: FileDto = null;

  model: FileDto = null;

  @ViewChild("fileForm") public fileForm: NgForm;

  constructor() {}

  ngOnInit(): void {
    this.setModel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setModel();
  }

  setModel() {
    if (hasValue(this.formData)) {
      this.model = JSON.parse(JSON.stringify(this.formData));
    } else {
      this.model = {} as FileDto;
    }
  }

  changeFile(event: any) {
    let input = event.target;
    for (var index = 0; index < input.files.length; index++) {
      let reader = new FileReader();
      reader.onload = () => {
        this.model.content = reader.result as string;
      };
      reader.readAsDataURL(input.files[index]);
      this.model.name = input.files[index].name;
      this.model.size = input.files[index].size;
      this.model.type = input.files[index].type;
    }
  }

  onSave() {
    event.preventDefault();
    event.stopPropagation();
    if (this.fileForm.valid) {
      if (this.model.name) {
        this.OnSave.emit(this.model);
      }
    }
  }

  onCancel() {
    this.OnCancel.emit();
  }

  onDelete() {
    this.OnDelete.emit();
  }
}
