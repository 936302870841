import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/security/services/auth.service';
import { msalConfig } from "environments/environment";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  constructor(private authService: AuthService) {
   }

  ngOnInit(): void {
    this.logout();
  }

  logout() {
    this.authService.logout();
  }
}
