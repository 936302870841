import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  AfterViewInit,
  AfterViewChecked,
} from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import {
  Location,
  PopStateEvent,
} from "@angular/common";
import { NavbarComponent } from "./../../../../components/navbar/navbar.component";
import { SidebarService } from "app/components/sidebar/services/sidebar.service";
import PerfectScrollbar from "perfect-scrollbar";

declare const $: any;

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"],
})
export class AdminLayoutComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  sidebarInvisible = false;
  url: string;
  location: Location;
  psSidebar: PerfectScrollbar;
  psMainPanel: PerfectScrollbar;

  @ViewChild("sidebar", { static: false }) sidebar: any;
  @ViewChild(NavbarComponent, { static: false }) navbar: NavbarComponent;
  constructor(
    private router: Router,
    location: Location,
    private sidebarService: SidebarService,
  ) {
    this.location = location;
    this.setSidebarInvisible();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.updatePerfectScrollbar();
  }

  setSidebarInvisible() {
    this.sidebarInvisible = false;
  }

  ngOnInit() {
    const elemMainPanel = <HTMLElement>document.querySelector("app-admin-layout .main-panel");
    const elemSidebar = <HTMLElement>(
      document.querySelector("app-admin-layout .sidebar .sidebar-wrapper")
    );
    this.psMainPanel = new PerfectScrollbar(elemMainPanel);
    this.psSidebar = new PerfectScrollbar(elemSidebar);

    const elemNavbar = <HTMLElement>document.getElementById("navbarNaxos");
    this.sidebarService.navbarElement = elemNavbar;
    this.sidebarService.ngOnInit();

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else window.scrollTo(0, 0);
      }
    });
    const html = document.getElementsByTagName("html")[0];
    html.classList.add("perfect-scrollbar-on");
  }

  ngAfterViewInit() {
    this.updatePerfectScrollbar();
  }

  ngAfterViewChecked(): void {
    this.updatePerfectScrollbar();
  }

  public isMap() {
    if (
      this.location.prepareExternalUrl(this.location.path()) ===
      "/maps/fullscreen"
    ) {
      return true;
    } else {
      return false;
    }
  }

  updatePerfectScrollbar(): void {
    this.psMainPanel.update();
    this.psSidebar.update();
  }
}
