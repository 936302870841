import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-content-dashboard",
  templateUrl: "./content-dashboard.component.html",
  styleUrls: ["./content-dashboard.component.scss"]
})
export class ContentDashboardComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
