import { SignupChoiceComponent } from './view/signup-choice/signup-choice.component';
import { SignupWarningComponent } from 'app/layouts/public/view/signup-warning/signup-warning.component';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NgModule } from "@angular/core";
import { PublicRoutes } from "./public-routing.module";

import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ContentDashboardComponent } from "./view/content-dashboard/content-dashboard.component";
import { TestimoniesComponent } from "./view/testimonies/testimonies.component";
import { CommonModule } from "@angular/common";
import { SharedLayoutModule } from "../shared/shared-layout.module";
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { DescriptionComponent } from "./view/description/description.component";
import { AboutUsComponent } from './view/about-us/about-us.component';
import { NaxosFrameworkModule } from "../naxos-framework/naxos-framework.module";
import { ContactComponent } from './view/contact/contact.component';
import { ContactDataComponent } from './view/contact/contact-data/contact-data.component';
import { SupportComponent } from './view/support/support.component';
import { HomeComponent } from './view/home/home.component';
import { LoginFailedComponent } from './view/login-failed/login-failed.component';
import { SubscriptionComponent } from './view/subscription/subscription.component';
import { PrivacyPolicyComponent } from './view/privacy-policy/privacy-policy.component';
import { GoogleMapsModule } from '@angular/google-maps'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    // Ng2Charts,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(PublicRoutes),
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatTooltipModule,
    MatSelectModule,
    MatAutocompleteModule,
    SharedLayoutModule,
    CarouselModule.forRoot(),
    AccordionModule.forRoot(),
    NaxosFrameworkModule,
    GoogleMapsModule,
  ],
  declarations: [
    ContentDashboardComponent,
    TestimoniesComponent,
    DescriptionComponent,
    AboutUsComponent,
    ContactComponent,
    ContactDataComponent,
    SupportComponent,
    HomeComponent,
    LoginFailedComponent,
    SubscriptionComponent,
    PrivacyPolicyComponent,
    SignupWarningComponent,
    SignupChoiceComponent,
  ],
  exports: [MatFormFieldModule, MatInputModule],
})
export class PublicModule {}
