/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EnumUserRole } from '../models/enum-user-role';
import { UserDto } from '../models/user-dto';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllUsers
   */
  static readonly GetAllUsersPath = '/api/User';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAll$Response(params?: {
  }): Observable<StrictHttpResponse<Array<UserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetAllUsersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAll(params?: {
  }): Observable<Array<UserDto>> {

    return this.GetAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation postUser
   */
  static readonly PostUserPath = '/api/User';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Post()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Post$Response(params: {
    body: UserDto
  }): Observable<StrictHttpResponse<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.PostUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Post$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Post(params: {
    body: UserDto
  }): Observable<UserDto> {

    return this.Post$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation getAllUsersForCompany
   */
  static readonly GetAllUsersForCompanyPath = '/api/User/GetAllForCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAllForCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllForCompany$Response(params?: {
  }): Observable<StrictHttpResponse<Array<UserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetAllUsersForCompanyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetAllForCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllForCompany(params?: {
  }): Observable<Array<UserDto>> {

    return this.GetAllForCompany$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation getAllUsersWithoutCompany
   */
  static readonly GetAllUsersWithoutCompanyPath = '/api/User/GetAllWithoutCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAllWithoutCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllWithoutCompany$Response(params?: {
  }): Observable<StrictHttpResponse<Array<UserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetAllUsersWithoutCompanyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetAllWithoutCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllWithoutCompany(params?: {
  }): Observable<Array<UserDto>> {

    return this.GetAllWithoutCompany$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation getUserById
   */
  static readonly GetUserByIdPath = '/api/User/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUserByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetById(params: {
    id: string;
  }): Observable<UserDto> {

    return this.GetById$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation putUser
   */
  static readonly PutUserPath = '/api/User/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Put()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Put$Response(params: {
    id: string;
    body: UserDto
  }): Observable<StrictHttpResponse<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.PutUserPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Put$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Put(params: {
    id: string;
    body: UserDto
  }): Observable<UserDto> {

    return this.Put$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation deleteUser
   */
  static readonly DeleteUserPath = '/api/User/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  Delete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.DeleteUserPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Delete(params: {
    id: string;
  }): Observable<Blob> {

    return this.Delete$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getUserByIdentityId
   */
  static readonly GetUserByIdentityIdPath = '/api/User/GetByIdentityId/{identityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetByIdentityId()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetByIdentityId$Response(params: {
    identityId: string;
  }): Observable<StrictHttpResponse<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUserByIdentityIdPath, 'get');
    if (params) {
      rb.path('identityId', params.identityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetByIdentityId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetByIdentityId(params: {
    identityId: string;
  }): Observable<UserDto> {

    return this.GetByIdentityId$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation getUserByLastName
   */
  static readonly GetUserByLastNamePath = '/api/User/GetByLastName/{lastName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetByLastName()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetByLastName$Response(params: {
    lastName: string;
  }): Observable<StrictHttpResponse<Array<UserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUserByLastNamePath, 'get');
    if (params) {
      rb.path('lastName', params.lastName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetByLastName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetByLastName(params: {
    lastName: string;
  }): Observable<Array<UserDto>> {

    return this.GetByLastName$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation getUserByLastNameForCompany
   */
  static readonly GetUserByLastNameForCompanyPath = '/api/User/GetByLastNameForCompany/{lastName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetByLastNameForCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetByLastNameForCompany$Response(params: {
    lastName: string;
  }): Observable<StrictHttpResponse<Array<UserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUserByLastNameForCompanyPath, 'get');
    if (params) {
      rb.path('lastName', params.lastName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetByLastNameForCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetByLastNameForCompany(params: {
    lastName: string;
  }): Observable<Array<UserDto>> {

    return this.GetByLastNameForCompany$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation userExists
   */
  static readonly UserExistsPath = '/api/User/Exists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Exists()` instead.
   *
   * This method doesn't expect any request body.
   */
  Exists$Response(params?: {
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserExistsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Exists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Exists(params?: {
  }): Observable<boolean> {

    return this.Exists$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation isUserTheOnlyOneAdmin
   */
  static readonly IsUserTheOnlyOneAdminPath = '/api/User/IsTheOnlyOneAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `IsTheOnlyOneAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  IsTheOnlyOneAdmin$Response(params?: {
    userId?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.IsUserTheOnlyOneAdminPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `IsTheOnlyOneAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  IsTheOnlyOneAdmin(params?: {
    userId?: string;
  }): Observable<boolean> {

    return this.IsTheOnlyOneAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getCurrentUser
   */
  static readonly GetCurrentUserPath = '/api/User/GetCurrentUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetCurrentUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCurrentUser$Response(params?: {
  }): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.GetCurrentUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetCurrentUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCurrentUser(params?: {
  }): Observable<UserDto> {

 

    return this.GetCurrentUser$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation getCurrentUserInfo
   */
  static readonly GetCurrentUserInfoPath = '/api/User/GetCurrentUserInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetCurrentUserInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCurrentUserInfo$Response(params?: {
  }): Observable<StrictHttpResponse<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetCurrentUserInfoPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetCurrentUserInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCurrentUserInfo(params?: {
  }): Observable<UserDto> {

    return this.GetCurrentUserInfo$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation getAllowedRolesForCompany
   */
  static readonly GetAllowedRolesForCompanyPath = '/api/User/GetAllowedRolesForCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAllowedRolesForCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllowedRolesForCompany$Response(params?: {
    companyId?: string;
  }): Observable<StrictHttpResponse<Array<EnumUserRole>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetAllowedRolesForCompanyPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EnumUserRole>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetAllowedRolesForCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllowedRolesForCompany(params?: {
    companyId?: string;
  }): Observable<Array<EnumUserRole>> {

    return this.GetAllowedRolesForCompany$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EnumUserRole>>) => r.body as Array<EnumUserRole>)
    );
  }

  /**
   * Path part for operation assignLicenseToUser
   */
  static readonly AssignLicenseToUserPath = '/api/User/AssignLicense/{licenseKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `AssignLicense()` instead.
   *
   * This method doesn't expect any request body.
   */
  AssignLicense$Response(params: {
    licenseKey: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.AssignLicenseToUserPath, 'post');
    if (params) {
      rb.path('licenseKey', params.licenseKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `AssignLicense$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  AssignLicense(params: {
    licenseKey: string;
  }): Observable<Blob> {

    return this.AssignLicense$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation publishUser
   */
  static readonly PublishUserPath = '/api/User/Publish';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Publish()` instead.
   *
   * This method doesn't expect any request body.
   */
  Publish$Response(params?: {
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.PublishUserPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Publish$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Publish(params?: {
  }): Observable<Blob> {

    return this.Publish$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
