import { ErrorHandler, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ServerError } from "@azure/msal-browser";
import { RoutePaths } from "app/models/menus-routes/route-paths";
import { ErrorService } from "app/shared/errors/error.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private router: Router,
    private error: ErrorService,
    ) {}

  handleError(error) {
    let treatError = true;
    if (error instanceof ServerError) {
      if (error?.message?.includes("AADB2C90091")) {
        treatError = false;
        if (this.router.url.startsWith(RoutePaths.loginFailed.path)) {
          this.router.navigateByUrl(RoutePaths.home.path);
        }
      }
    }
    if (treatError)
    {
      console.error(error);
      this.error.error(error);
    }
  }
}
