
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AuthGuard } from './auth.guard';

@Injectable()
export class CompanyAdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate() {
    return this.authService.isCompanyAdmin ? true : this.authService.isCompanyAdmin$;
  }

}
