import { Configuration, InteractionType } from "@azure/msal-browser";
import { HostingEnvironment } from "app/api/licence/models/hosting-environment";

export const environment = {
  dev: false, // DEV or LOCAL Environment
  test: true,
  qa: false,
  prod: false,
  local: false
};

export const homeUrl = "https://www.octopus.tst.naxosit.com";

export const hostingEnvironment = HostingEnvironment.Test;

export const apiSettings = {
  AddressApiEndPoint: "https://address-api.naxosit.be.tst.naxosit.com",
  OctopusWebApiEndPoint: "https://web-api.octopus.tst.naxosit.com",
  OctopusLicenceApiEndPoint: "https://licence-api.octopus.tst.naxosit.com",
  NaxosContentApiRootUrl: "https://content-api.tst.naxosit.com",
  OctopusCoreApiEndPoint: "",
  OctopusCmApiEndPoint: ""
};

export const popup = {
  disableClose: true,
  width: "calc(100% - 50px)",
  height: "calc(100% - 120px)",
};

// this checks if the app is running on IE
export const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

/** =================== REGIONS ====================
 * 1) B2C policies and user flows
 * 2) Web API configuration parameters
 * 3) Authentication configuration parameters
 * 4) MSAL-Angular specific configuration parameters
 * =================================================
 */

// #region 1) B2C policies and user flows
/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
 export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_Octopus_Signup",
    resetPassword: "b2c_1_reset",
    editProfile: "b2c_1_edit_profile",
    signUpOnly: "B2C_1_Octopus_Signuponly",
    signinsignup: "B2C_1A_OCTOPUS_WEB_SIGNUP_SIGNIN",
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://naxositb2ctest.b2clogin.com/b2c.tst.naxosit.com/B2C_1_Octopus_Signup",
    },
    signUpOnly: {
      authority:
        "https://naxositb2ctest.b2clogin.com/b2c.tst.naxosit.com/B2C_1_Octopus_Signuponly",
    },
    resetPassword: {
      authority:
        "https://naxositb2ctest.b2clogin.com/b2c.tst.naxosit.com/B2C_1_Octopus_ResetPassword",
    },
    editProfile: {
      authority:
        "https://naxositb2ctest.b2clogin.com/b2c.tst.naxosit.com/b2c_1_octopus_editprofile",
    },
    signinsignup: {
      authority:
        "https://naxositb2ctest.b2clogin.com/b2c.tst.naxosit.com/B2C_1A_OCTOPUS_WEB_SIGNUP_SIGNIN",
    },
  },
};
// #endregion

// #region 2) Web API Configuration
/**
 * Enter here the coordinates of your Web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiOctopusCoreConfig: { b2cScopes: string[]; webApi: string } = {
  b2cScopes: ["https://b2c.tst.naxosit.com/octopus-api/Core.ReadWrite"],
  webApi: apiSettings.OctopusCoreApiEndPoint + "/api",
};
export const apiOctopusCmConfig: { b2cScopes: string[]; webApi: string } = {
  b2cScopes: ["https://b2c.tst.naxosit.com/octopus-api/CM.ReadWrite"],
  webApi: apiSettings.OctopusCmApiEndPoint + "/api",
};
export const apiOctopusWebConfig: { b2cScopes: string[]; webApi: string } = {
  b2cScopes: ["https://b2c.tst.naxosit.com/octopus-api/Octopus.Web.ReadWrite"],
  webApi: apiSettings.OctopusWebApiEndPoint + "/api",
};
export const apiOctopusWebReadFileConfig: { b2cScopes: string[]; webApi: string } = {
  b2cScopes: null,
  webApi: apiSettings.OctopusWebApiEndPoint + "/api/ReadFile",
};
export const apiLicenceConfig: { b2cScopes: string[]; webApi: string } = {
  b2cScopes: [
    "https://b2c.tst.naxosit.com/octopus-api/License.ReadWrite",
    "https://b2c.tst.naxosit.com/octopus-api/Octopus.Web.ReadWrite",
  ],
  webApi: "https://licence-api.octopus.tst.naxosit.com/api",
};
// #endregion

// #region 3) Authentication Configuration
/**
 * Config object to be passed to Msal on creation. For a full list of msal.js configuration parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_configuration_.html
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: "aae37ac7-c8e1-46e0-8e5d-99a7603e9d2d",
    authority: b2cPolicies.authorities.signinsignup.authority,
    knownAuthorities: ['https://naxositb2ctest.b2clogin.com'],
    redirectUri: "https://www.octopus.tst.naxosit.com/auth",
    postLogoutRedirectUri: "https://www.octopus.tst.naxosit.com",
    navigateToLoginRequestUrl: false,
  },
  // cache: {
  //     cacheLocation: "localStorage",
  //     storeAuthStateInCookie: isIE, // Set this to "true" to save cache in cookies to address trusted zones limitations in IE
  // },
};

// #region 4) MSAL-Angular Configuration
// here you can define the coordinates and required permissions for your protected resources
export const protectedResourceMapConfig: Map<string, Array<string>> = new Map<
  string,
  Array<string>
>([
  [apiOctopusWebReadFileConfig.webApi, apiOctopusWebReadFileConfig.b2cScopes], // i.e. [https://fabrikamb2chello.azurewebsites.net/hello, ['https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read']]
  [apiOctopusWebConfig.webApi, apiOctopusWebConfig.b2cScopes], // i.e. [https://fabrikamb2chello.azurewebsites.net/hello, ['https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read']]
  [apiLicenceConfig.webApi, apiLicenceConfig.b2cScopes], // i.e. [https://fabrikamb2chello.azurewebsites.net/hello, ['https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read']]
]);

export const interactionType = InteractionType.Redirect;
