/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FooterDto } from '../models/footer-dto';
import { MenuDto } from '../models/menu-dto';
import { OctopusWebDto } from '../models/octopus-web-dto';

@Injectable({
  providedIn: 'root',
})
export class ItemsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getFlattenItemByCode
   */
  static readonly GetFlattenItemByCodePath = '/api/v1-read/applications/{applicationCode}/items/{itemCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFlattenItemByCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetFlattenItemByCode$Response(params: {
    applicationCode: string;
    itemCode: string;
    locale?: string;
  }): Observable<StrictHttpResponse<{
[key: string]: any;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ItemsService.GetFlattenItemByCodePath, 'get');
    if (params) {
      rb.path('applicationCode', params.applicationCode, {});
      rb.path('itemCode', params.itemCode, {});
      rb.query('locale', params.locale, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: any;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFlattenItemByCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetFlattenItemByCode(params: {
    applicationCode: string;
    itemCode: string;
    locale?: string;
  }): Observable<{
[key: string]: any;
}> {

    return this.GetFlattenItemByCode$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: any;
}>) => r.body as {
[key: string]: any;
})
    );
  }

  /**
   * Path part for operation apiV1ReadGetMenuGet
   */
  static readonly ApiV1ReadGetMenuGetPath = '/api/v1-read/GetMenu';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReadGetMenuGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReadGetMenuGet$Plain$Response(params?: {
    locale?: string;
    IsUserconnected?: boolean;
  }): Observable<StrictHttpResponse<Array<MenuDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemsService.ApiV1ReadGetMenuGetPath, 'get');
    if (params) {
      rb.query('locale', params.locale, {});
      rb.query('IsUserconnected', params.IsUserconnected, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MenuDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReadGetMenuGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReadGetMenuGet$Plain(params?: {
    locale?: string;
    IsUserconnected?: boolean;
  }): Observable<Array<MenuDto>> {

    return this.apiV1ReadGetMenuGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MenuDto>>) => r.body as Array<MenuDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReadGetMenuGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReadGetMenuGet$Json$Response(params?: {
    locale?: string;
    IsUserconnected?: boolean;
  }): Observable<StrictHttpResponse<Array<MenuDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemsService.ApiV1ReadGetMenuGetPath, 'get');
    if (params) {
      rb.query('locale', params.locale, {});
      rb.query('IsUserconnected', params.IsUserconnected, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MenuDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReadGetMenuGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReadGetMenuGet$Json(params?: {
    locale?: string;
    IsUserconnected?: boolean;
  }): Observable<Array<MenuDto>> {

    return this.apiV1ReadGetMenuGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MenuDto>>) => r.body as Array<MenuDto>)
    );
  }

  /**
   * Path part for operation apiV1ReadGetFooterGet
   */
  static readonly ApiV1ReadGetFooterGetPath = '/api/v1-read/GetFooter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReadGetFooterGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReadGetFooterGet$Plain$Response(params?: {
    locale?: string;
  }): Observable<StrictHttpResponse<Array<FooterDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemsService.ApiV1ReadGetFooterGetPath, 'get');
    if (params) {
      rb.query('locale', params.locale, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FooterDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReadGetFooterGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReadGetFooterGet$Plain(params?: {
    locale?: string;
  }): Observable<Array<FooterDto>> {

    return this.apiV1ReadGetFooterGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FooterDto>>) => r.body as Array<FooterDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReadGetFooterGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReadGetFooterGet$Json$Response(params?: {
    locale?: string;
  }): Observable<StrictHttpResponse<Array<FooterDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemsService.ApiV1ReadGetFooterGetPath, 'get');
    if (params) {
      rb.query('locale', params.locale, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FooterDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReadGetFooterGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReadGetFooterGet$Json(params?: {
    locale?: string;
  }): Observable<Array<FooterDto>> {

    return this.apiV1ReadGetFooterGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FooterDto>>) => r.body as Array<FooterDto>)
    );
  }

  /**
   * Path part for operation getOctopusWebItemByCode
   */
  static readonly GetOctopusWebItemByCodePath = '/api/v1-read/applications/{applicationCode}/items/octopusWeb/{octopusWebItemCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOctopusWebItemByCode$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOctopusWebItemByCode$Plain$Response(params: {
    applicationCode: string;
    octopusWebItemCode: string;
    locale?: string;
  }): Observable<StrictHttpResponse<OctopusWebDto>> {

    const rb = new RequestBuilder(this.rootUrl, ItemsService.GetOctopusWebItemByCodePath, 'get');
    if (params) {
      rb.path('applicationCode', params.applicationCode, {});
      rb.path('octopusWebItemCode', params.octopusWebItemCode, {});
      rb.query('locale', params.locale, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OctopusWebDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOctopusWebItemByCode$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOctopusWebItemByCode$Plain(params: {
    applicationCode: string;
    octopusWebItemCode: string;
    locale?: string;
  }): Observable<OctopusWebDto> {

    return this.getOctopusWebItemByCode$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OctopusWebDto>) => r.body as OctopusWebDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOctopusWebItemByCode$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOctopusWebItemByCode$Json$Response(params: {
    applicationCode: string;
    octopusWebItemCode: string;
    locale?: string;
  }): Observable<StrictHttpResponse<OctopusWebDto>> {

    const rb = new RequestBuilder(this.rootUrl, ItemsService.GetOctopusWebItemByCodePath, 'get');
    if (params) {
      rb.path('applicationCode', params.applicationCode, {});
      rb.path('octopusWebItemCode', params.octopusWebItemCode, {});
      rb.query('locale', params.locale, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OctopusWebDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOctopusWebItemByCode$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOctopusWebItemByCode$Json(params: {
    applicationCode: string;
    octopusWebItemCode: string;
    locale?: string;
  }): Observable<OctopusWebDto> {

    return this.getOctopusWebItemByCode$Json$Response(params).pipe(
      map((r: StrictHttpResponse<OctopusWebDto>) => r.body as OctopusWebDto)
    );
  }

}
