
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class NaxosAdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate() {
    return this.authService.isNaxosAdmin ? true : this.authService.isNaxosAdmin$;
  }

}
