import { RoutePathItem } from "./route-path-item";



export const RoutePaths = {
  description: new RoutePathItem({
    path: "/public/description",
    title: "Description", type: "link", iconType: "menu_book"
  }),
  testimonies: new RoutePathItem({
    path: "/public/testimonies",
    title: "Témoignages", type: "link", iconType: "face"
  }),
  contact: new RoutePathItem({
    path: "/public/contact",
    title: "Contact", type: "link", iconType: "email"
  }),
  tryIt: new RoutePathItem({
    path: "/tryit",
    title: "Essayez le !", type: "sub", iconType: "try"
  }),
  account: new RoutePathItem({
    path: "",
    title: "", type: "sub", iconType: "person"
  }),
  help: new RoutePathItem({
    path: "/help",
    title: "Aide", type: "sub", iconType: "help"
  }),
  subscription: new RoutePathItem({
    path: "/public/subscription",
    title: "Abonnement gratuit", type: "", iconType: "card_giftcard"
  }),
  octopusTour: new RoutePathItem({
    path: "/octopus-tour",
    title: "Tour Octopus", type: "", iconType: "travel_explore"
  }),
  support: new RoutePathItem({
    path: "/public/support",
    title: "Support", type: "link", iconType: "headset_mic"
  }),
  files: new RoutePathItem({
    path: "/public/files",
    title: "Manuels d'utilisation", type: "", iconType: "article"
  }),
  aboutus: new RoutePathItem({
    path: "/public/aboutus",
    title: "À propos", type: "", iconType: "info"
  }),



  profile: new RoutePathItem({
    path: "/management/profile",
    title: "Profile", type: "", iconType: "person"
  }),
  passwordReset: new RoutePathItem({
    path: "/public/password-reset",
    title: "Password Reset", type: "", iconType: "key"
  }),
  logout: new RoutePathItem({
    path: "/logout",
    title: "Log Out", type: "", iconType: "logout"
  }),
  login: new RoutePathItem({
    path: "/login",
    title: "Log In/sign up", type: "", iconType: "lock_open"
  }),

  home: new RoutePathItem({
    path: "/public/home",
    title: "Accueil", type: "link", iconType: "home"
  }),
  userDashboard: new RoutePathItem({
    path: "/management/user-dashboard",
    title: "Accueil", type: "link", iconType: "dashboard"
  }),
  companyDashboard: new RoutePathItem({
    path: "/management/company-dashboard",
    title: "Accueil", type: "link", iconType: "dashboard"
  }),
  company: new RoutePathItem({
    path: "/management/company",
    title: "Signalétique entreprise", type: "link", iconType: "store"
  }),
  allCompanies: new RoutePathItem({
    path: "/management/all-companies",
    title: "Gestion des entreprises", type: "link", iconType: "business"
  }),
  companyUsers: new RoutePathItem({
    path: "/management/all-userscompany",
    title: "Utilisateurs", type: "link", iconType: "group"
  }),
  allUsers: new RoutePathItem({
    path: "/management/all-users",
    title: "Utilisateurs", type: "link", iconType: "group"
  }),
  companyLicences: new RoutePathItem({
    path: "/management/company-licences",
    title: "Licence", type: "link", iconType: "badge"
  }),
  allLicenceRequests: new RoutePathItem({
    path: "/management/all-licence-requests",
    title: "Licence requests", type: "item", iconType: "badge"
  }),
  allLicences: new RoutePathItem({
    path: "/management/all-licences",
    title: "", type: "", iconType: ""
  }),
  companyDownload: new RoutePathItem({
    path: "/management/download",
    title: "Téléchargements", type: "link", iconType: "cloud_download"
  }),
  companyHostings: new RoutePathItem({
    path: "/management/hostings",
    title: "Hostings", type: "link", iconType: "engineering"
  }),
  naxosDownload: new RoutePathItem({
    path: "/management/naxos-download",
    title: "Liens de téléchargements", type: "link", iconType: "cloud_download"
  }),
  naxosFiles: new RoutePathItem({
    path: "/management/naxos-files",
    title: "Manuels d'utilisation", type: "link", iconType: "article"
  }),
  allProjects: new RoutePathItem({
    path: "/management/all-projects",
    title: "Projets", type: "link", iconType: "travel_explore"
  }),
  updateDownload: new RoutePathItem({
    path: "/management/update-download/",
    title: "", type: "", iconType: ""
  }),
  unsubscribeWarning: new RoutePathItem({
    path: "/management/unsubscribe-warning/",
    title: "", type: "", iconType: ""
  }),
  adminAddCompany: new RoutePathItem({
    path: "/management/admin-add-company",
    title: "", type: "", iconType: ""
  }),
  finalForm: new RoutePathItem({
    path: "/management/finalform/",
    title: "", type: "", iconType: ""
  }),
  signupChoice: new RoutePathItem({
    path: "/public/signup-choice",
    title: "", type: "", iconType: ""
  }),
  termOfUse: new RoutePathItem({
    path: "/management/terms-of-use",
    title: "", type: "", iconType: ""
  }),
  loginFailed: new RoutePathItem({
    path: "/public/login-failed",
    title: "", type: "", iconType: ""
  })
};

export const navMenuRoutePaths = {
  home: RoutePaths.home,
  description: RoutePaths.description,
  testimonies: RoutePaths.testimonies,
  contact: RoutePaths.contact,
  tryIt: RoutePaths.tryIt,
  subscription: RoutePaths.subscription,
  octopusTour: RoutePaths.octopusTour,
  help: RoutePaths.help,
  support: RoutePaths.support,
  files: RoutePaths.files,
  aboutus: RoutePaths.aboutus
};

export const navPublicAccountMenuRoutePaths = {
  account: RoutePaths.account,
  profile: RoutePaths.profile,
  passwordReset: RoutePaths.passwordReset,
  logout: RoutePaths.logout,
  login: RoutePaths.login,
  // signup: RoutePaths.signup
};


