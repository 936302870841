import { AfterViewInit } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit, AfterViewInit {
  lat: number = 50.7274594;
  lng: number = 4.4966814;
  center: google.maps.LatLngLiteral = {
    lat: this.lat,
    lng: this.lng,
  }
  position: google.maps.LatLngLiteral = {
    lat: this.lat,
    lng: this.lng,
  }
  options: google.maps.MapOptions = {
    scrollwheel: false,
  }
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChild(MapMarker, { static: false }) marker: MapMarker;

  constructor() { }

  ngOnInit(): void {

  }

  openInfo() {
    this.infoWindow.open(this.marker);
  }

  ngAfterViewInit(): void {
    this.openInfo();
  }

}
