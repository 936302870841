export enum EnumMenu {
  navPublic = 0,
  user = 2,
  company = 3,
  admin = 4,
  navManagement = 5,
  tryit = 6,
  help = 7,
  account = 8
}
