import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FieldConfig } from 'app/layouts/naxos-framework/form/field.interface';

@Component({
  selector: 'app-specific-form',
  templateUrl: './specific-form.component.html',
  styleUrls: ['./specific-form.component.scss']
})
export class SpecificFormComponent implements OnInit {
  @Input() type: string;
  @Input() fields: FieldConfig[] = [];
  @Input() formData: any;
  @Input() EnableSave = true;
  @Input() EnableDelete = true;
  @Input() EnableCancel = true;
  @Input() IsReadOnly = false;

  @Input() isDialog = false;

  @Output() OnSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnCancel: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onSave(value) {
    this.OnSave.emit(value);
  }

  onCancel() {
    this.OnCancel.emit();
  }

  onDelete() {
    this.OnDelete.emit();
  }

}
