import { MatDialogModule } from '@angular/material/dialog';
import { NotificationsService } from './notifications/notifications.service';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ErrorService } from "./errors/error.service";
import { LoadingDialogService } from "./loading/loading-dialog.service";
import { LoadingDialogComponent } from "./loading/loading-dialog/loading-dialog.component";
import { ToastrModule, ToastrService} from 'ngx-toastr';
import { ErrorToastComponent } from './notifications/error-toast/error-toast.component';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal'
import { ExceptionDetailComponent } from './errors/naxos-error-details/exception-details/exception-detail.component';
import { NaxosErrorDetailsComponent } from './errors/naxos-error-details/naxos-error-details.component';
import { ErrorDetailsComponent } from './errors/error-details/error-details.component';
import { HttpErrorDetailsComponent } from './errors/http-error-details/http-error-details.component';
import { StackFrameComponent } from './errors/naxos-error-details/exception-details/stack-frame/stack-frame.component';
import { CodeComponent } from './errors/naxos-error-details/exception-details/stack-frame/code/code.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      maxOpened: 2,
      preventDuplicates: true,
      // resetTimeoutOnDuplicate: true,
      countDuplicates: true,
      includeTitleDuplicates: true,
      enableHtml: true,
      progressBar: true,
    }), // ToastrModule added
    ModalModule,
    RouterModule,
    MatDialogModule,
  ],
  declarations: [
    LoadingDialogComponent,
    ErrorToastComponent,
    ExceptionDetailComponent,
    NaxosErrorDetailsComponent,
    ErrorDetailsComponent,
    HttpErrorDetailsComponent,
    StackFrameComponent,
    CodeComponent,
  ],
  entryComponents: [
    NaxosErrorDetailsComponent,
  ],
  providers: [
    LoadingDialogService,
    ErrorService,
    NotificationsService,
    ToastrService,
    BsModalService,
    BsModalRef,
  ],
})
export class SharedModule {}
