import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ItemsService } from 'app/api/content-read/services';

@Component({
  selector: 'app-login-failed',
  templateUrl: './login-failed.component.html',
  styleUrls: ['./login-failed.component.scss']
})
export class LoginFailedComponent implements OnInit {
  item;

  constructor(
    private authService: MsalService,
    private itemsService: ItemsService,
    ) { }

  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe();
    this.itemsService.GetFlattenItemByCode({
      applicationCode: 'octopus-web',
      itemCode: 'login-failed'
    }).subscribe({
      next: (item) => {
        this.item = item;
      },
      error: (error) => {
        console.log('could not retrieve item for login-failed component');
      }
    });
  }

}
