import { Component, OnInit } from '@angular/core';
import { UserDto } from 'app/api/octopus-web/models';
import { hasValue } from 'app/layouts/naxos-framework/services';
import { AuthService } from 'app/security/services';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {

  constructor(
    public authService: AuthService,
  ) {
  }

  ngOnInit() {
  }

}
