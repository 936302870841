import { UserService } from 'app/api/octopus-web/services';
import { Router, ActivatedRoute } from '@angular/router';
import { LicencesService } from 'app/api/licence/services';
import { Component, OnInit } from '@angular/core';
import { RequestDto, LicenceDto, LicenceType, RequestStatus } from 'app/api/licence/models';
import { AuthService } from 'app/security/services';
import { CompanyDto } from 'app/api/octopus-web/models';
import { RoutePaths } from 'app/models/menus-routes/route-paths';

@Component({
  selector: 'app-terms-of-use-view',
  templateUrl: './terms-of-use-view.component.html',
  styleUrls: ['./terms-of-use-view.component.scss']
})
export class TermsOfUseViewComponent implements OnInit {

  company: CompanyDto;
  licence: LicenceDto;

  constructor(
    private licenceService: LicencesService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    ) { }

  ngOnInit(): void {
    this.company = this.authService.currentCompany;
    this.authService.currentCompany$.subscribe({
      next: (currentCompany) => {
        this.company = currentCompany;
      },
    });
  }

  agreeTerms(){
    const licenceRequest: RequestDto = {} as RequestDto;
    licenceRequest.licenceType = LicenceType.OnCloud; // OnCloud
    licenceRequest.isTrialLicence = true;
    licenceRequest.status = RequestStatus.Validated;
    licenceRequest.companyId = this.company?.id;
    this.licenceService.PostTrial({ companyId: licenceRequest.companyId }).subscribe(data => {
      this.licence = data;
      this.assignLicense();
      this.authService.companyLicenseSubject.next(this.licence);
    });
    this.router.navigateByUrl(RoutePaths.company.path);
  }

  cancel(){
    this.router.navigateByUrl(RoutePaths.company.path);
  }

  assignLicense() {
    this.userService.AssignLicense({ licenseKey: this.licence.licenceKey}).subscribe();
}
}

