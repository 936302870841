export class RoutePathItem {
  path: string = "";
  title: string = "";
  type: string = "";
  iconType: string = "";

  public constructor(
    fields?: {
      path: string,
      title: string,
      type: string,
      iconType: string
    }) {
    if (fields) Object.assign(this, fields);
  }
}