/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FileDto } from '../models/file-dto';
import { Stream } from '../models/stream';

@Injectable({
  providedIn: 'root',
})
export class ReadFileService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllFiles
   */
  static readonly GetAllFilesPath = '/api/ReadFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAll$Response(params?: {
  }): Observable<StrictHttpResponse<Array<FileDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ReadFileService.GetAllFilesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FileDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAll(params?: {
  }): Observable<Array<FileDto>> {

    return this.GetAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FileDto>>) => r.body as Array<FileDto>)
    );
  }

  /**
   * Path part for operation getFileById
   */
  static readonly GetFileByIdPath = '/api/ReadFile/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<FileDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReadFileService.GetFileByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetById(params: {
    id: string;
  }): Observable<FileDto> {

    return this.GetById$Response(params).pipe(
      map((r: StrictHttpResponse<FileDto>) => r.body as FileDto)
    );
  }

  /**
   * Path part for operation downloadFileById
   */
  static readonly DownloadFileByIdPath = '/api/ReadFile/DownloadById/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DownloadById()` instead.
   *
   * This method doesn't expect any request body.
   */
  DownloadById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Stream>> {

    const rb = new RequestBuilder(this.rootUrl, ReadFileService.DownloadFileByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Stream>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `DownloadById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DownloadById(params: {
    id: string;
  }): Observable<Stream> {

    return this.DownloadById$Response(params).pipe(
      map((r: StrictHttpResponse<Stream>) => r.body as Stream)
    );
  }

}
