import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutePaths } from 'app/models/menus-routes/route-paths';

@Component({
  selector: 'app-signup-choice',
  templateUrl: './signup-choice.component.html',
  styleUrls: ['./signup-choice.component.scss']
})
export class SignupChoiceComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  signin() {
    this.router.navigateByUrl(RoutePaths.login.path);
  }

}
