import { CompanyService } from "app/api/octopus-web/services";
import { Component, OnInit } from "@angular/core";
import { SimulationService } from "app/api/octopus-web/services";
import { SimulationDto } from "app/api/octopus-web/models";
import { HostingEnvironment, LicenceDto } from "app/api/licence/models";
import { DownloadLinksService, LicencesService } from "app/api/licence/services";
import { AuthService } from "app/security/services";
import { Router } from "@angular/router";
import { DownloadLinkDto } from "app/api/licence/models/download-link-dto";
import { RoutePaths } from "app/models/menus-routes/route-paths";

@Component({
  selector: "app-download-view",
  templateUrl: "./download-view.component.html",
  styleUrls: ["./download-view.component.scss"],
})
export class DownloadViewComponent implements OnInit {
  title = "Octopus MSI";
  icon = "cloud_download";

  isFormReady = false;
  downloadLinks: DownloadLinkDto[];
  hostingEnvironments: string[] = [];

  constructor(
    private downloadLinkService: DownloadLinksService,
    private router: Router
  ) {}

  ngOnInit() {
    this.initializeDownload();
  }
  initializeDownload() {
    this.downloadLinkService.GetAll().subscribe((downloadLinks) => {
      if (downloadLinks != null) {
        this.downloadLinks = downloadLinks;
        downloadLinks.forEach(element => {
          this.hostingEnvironments.push(HostingEnvironment[element.hostingEnvironment].toString());
        });
        this.isFormReady = true;
      }
    });
  }

  download(urlDownload) {
    window.location.href = urlDownload;
  }

  manageLicenses() {
    this.router.navigateByUrl(RoutePaths.companyLicences.path);
  }
}
