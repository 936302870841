import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { NaxosNotificationService } from "../services";
import { UserService } from "app/api/octopus-web/services";
import { RoutePaths } from "app/models/menus-routes/route-paths";

@Injectable({
  providedIn: "root"
})
export class RequiredCompanyGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private naxosNotif: NaxosNotificationService
  ) {}
  canActivate() {
    const result = this.userService.Exists();
    result.subscribe(item => {
      if (!item) {
        this.router.navigateByUrl(RoutePaths.companyDashboard.path);
        this.naxosNotif.Info(
          "A company is required. Register your company ....."
        );
      }
    });

    return result;
  }
}
