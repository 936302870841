import { Component, OnInit, Input } from "@angular/core";
@Component({
  selector: "app-naxos-card",
  templateUrl: "./naxos-card.component.html",
  styleUrls: ["./naxos-card.component.scss"]
})
export class NaxosCardComponent implements OnInit {
  @Input() headerIcon: string;
  @Input() category: string;
  @Input() title: string;
  @Input() footerIcon: string;
  @Input() footerTitle: string;
  @Input() location: string;
  @Input() linearColor: string;
  @Input() boxShadow: string;
  constructor() {}

  ngOnInit() {}
}
