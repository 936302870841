import { Component, OnInit } from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { SidebarTourService } from "../../service/sidebar-tour.service";
import { Router } from "@angular/router";
import { MultilevelNodes } from "ng-material-multilevel-menu";
import { RoutePaths } from "app/models/menus-routes/route-paths";

declare const $: any;

@Component({
  selector: "app-sidebar-tour",
  templateUrl: "./sidebar-tour.component.html",
  styleUrls: ["./sidebar-tour.component.scss"],
  animations: [
    trigger("slide", [
      state("up", style({ height: 0 })),
      state("down", style({ height: "*" })),
      transition("up <=> down", animate(200)),
    ]),
  ],
})
export class SidebarTourComponent implements OnInit {
  config = {
    paddingAtStart: true,
    interfaceWithRoute: true,
    classname: "my-custom-class",
    listBackgroundColor: "#FFFFFF",
    fontColor: "#3C4858",
    backgroundColor: "#FFFFFF",
    selectedListFontColor: "#2d338e",
    highlightOnSelect: true,
    collapseOnSelect: true,
    useDividers: false,
    rtlLayout: false,
  };

  appitems = [
    {
      label: "Retour à l'accueil",
      icon: RoutePaths.home.iconType,
      link: RoutePaths.home.path,
    },
    {
      label: "Naxos Octopus",
      link: "/octopus-tour/slides/1",
    },
    {
      label: "Les processus",
      link: "/octopus-tour/slides/2",
    },
    {
      label: "Le cycle de crédit",
      link: "/octopus-tour/slides/3",
    },
    {
      label: "Porteur de projet, entreprise",
      items: [
        {
          label: "Intro",
          link: "/octopus-tour/slides/4",
        },
        {
          label: "Enregister porteur de projet",
          link: "/octopus-tour/slides/5",
        },
        {
          label: "Enregistrer entreprise",
          link: "/octopus-tour/slides/6",
        },
      ],
    },
    {
      label: "Gestion des crédits",
      items: [
        {
          label: "Intro",
          //link: "/octopus-tour/project-carrier-enterprise/intro",
          link: "/octopus-tour/slides/7",
        },
        {
          label: "Etablir proposition",
          //link: "/octopus-tour/project-enterprise/register-project-carrier",
          link: "/octopus-tour/slides/8",
        },
        {
          label: "Documenter la décision",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/9",
        },
        {
          label: "Etablir le contrat",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/10",
        },
        {
          label: "Produire un mandat SEPA",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/11",
        },
        {
          label: "Décaisser un crédit",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/12",
        },
        {
          label: "Prélèvement bancaire: sélection",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/13",
        },
        {
          label: "Prélèvement bancaire: fichier",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/14",
        },
        {
          label: "Consulter les transactions",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/15",
        },
        {
          label: "Restructuration d'un crédit",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/16",
        },
        {
          label: "Paramétrer provisionnement",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/17",
        },
        {
          label: "Provisionner, passer en perte",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/18",
        },
      ],
    },
    {
      label: "Définir l'offre de produit",
      items: [
        {
          label: "Intro",
          //link: "/octopus-tour/project-carrier-enterprise/intro",
          link: "/octopus-tour/slides/19",
        },
        {
          label: "Les crédits",
          //link: "/octopus-tour/project-enterprise/register-project-carrier",
          link: "/octopus-tour/slides/20",
        },
        {
          label: "Les garanties",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/21",
        },
      ],
    },
    {
      label: "Lignes de financement",
      items: [
        {
          label: "Intro",
          //link: "/octopus-tour/project-carrier-enterprise/intro",
          link: "/octopus-tour/slides/22",
        },
        {
          label: "Tableau de bord",
          //link: "/octopus-tour/project-enterprise/register-project-carrier",
          link: "/octopus-tour/slides/23",
        },
        {
          label: "Gestion",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/24",
        },
      ],
    },
    {
      label: "La comptabilité",
      items: [
        {
          label: "Intro",
          //link: "/octopus-tour/project-carrier-enterprise/intro",
          link: "/octopus-tour/slides/25",
        },
        {
          label: "Plan comptable",
          //link: "/octopus-tour/project-enterprise/register-project-carrier",
          link: "/octopus-tour/slides/26",
        },
        {
          label: "Journaux comptables",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/27",
        },
        {
          label: "Schémas d'écriture",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/28",
        },
        {
          label: "Consultation des balances",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/29",
        },
        {
          label: "Bilan",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/30",
        },
        {
          label: "Exportation",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/31",
        },
      ],
    },
    {
      label: "Accompagnement du client",
      items: [
        {
          label: "Intro",
          //link: "/octopus-tour/project-carrier-enterprise/intro",
          link: "/octopus-tour/slides/32",
        },
        {
          label: "Suivi et évènements client",
          //link: "/octopus-tour/project-enterprise/register-project-carrier",
          link: "/octopus-tour/slides/33",
        },
        {
          label: "Lettre de relance",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/34",
        },
        {
          label: "Suivre une entreprise",
          //link: "/octopus-tour/project-enterprise/intro",
          link: "/octopus-tour/slides/35",
        },
      ],
    },
    {
      label: "Contrôles",
      items: [
        {
          label: "Intro",
          //link: "/octopus-tour/project-carrier-enterprise/intro",
          link: "/octopus-tour/slides/36",
        },
        {
          label: "Historique des transactions",
          //link: "/octopus-tour/project-enterprise/register-project-carrier",
          link: "/octopus-tour/slides/37",
        },
      ],
    },
    {
      label: "Documents clients",
      //link: "/octopus-tour/project-enterprise/register-project-carrier",
      link: "/octopus-tour/slides/38",
    },
    {
      label: "Listes et rapports",
      //link: "/octopus-tour/project-enterprise/register-project-carrier",
      link: "/octopus-tour/slides/39",
    },
    {
      label: "Administration et sécurité",
      //link: "/octopus-tour/project-enterprise/register-project-carrier",
      link: "/octopus-tour/slides/40",
    },
  ];

  constructor(
    public sidebarTourService: SidebarTourService,
    private router: Router
  ) {}

  public isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  ngOnInit() {}

  sidebarClose() {
    this.sidebarTourService.sidebarVisible = true;
    this.sidebarTourService.sidebarToggle();
  }

  selectedItem(event: MultilevelNodes) {
    this.router.navigateByUrl(event.link);
  }

  menuIsReady(event: MultilevelNodes[]) {}

  selectedLabel(event: MultilevelNodes) {}
}
