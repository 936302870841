import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  constructor() {}

  getAcronym(words: string): string {
    const matches = words.match(/\b(\w)/g); // ['J','S','O','N']
    const acronym = matches.join(""); // JSON
    return acronym;
  }
}
