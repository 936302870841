import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-naxos-file-view',
  templateUrl: './naxos-file-view.component.html',
  styleUrls: ['./naxos-file-view.component.scss']
})
export class NaxosFileViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
