import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { FileDto } from "app/api/octopus-web/models";
import { FileService } from "app/api/octopus-web/services";
import { FieldConfig } from "app/layouts/naxos-framework/form/field.interface";
import { SelectItems } from "app/layouts/naxos-framework/model/selectItems";
import { isGuid } from "app/layouts/naxos-framework/services";
import { AuthService } from "app/security/services";
import { NotificationsService } from "app/shared/notifications/notifications.service";
import { FormService } from "../models/form-service.interface";

@Injectable({
  providedIn: "root",
})
export class FileFormService implements FormService {
  isDynamic: boolean = false;
  formData: FileDto;
  title: string = "Fichier";
  icon: string = "article";

  constructor(
    private fileService: FileService,
  ) {}

  onSave(value: any): Promise<any> {
    const result = <FileDto>value;

    this.formData.name = result.name;
    this.formData.type = result.type;
    this.formData.description = result.description;
    this.formData.size = result.size;
    this.formData.content = result.content;

    if (isGuid(this.formData.id)) {
      const params = { id: this.formData.id, body: this.formData };
      return this.fileService
        .Put(params)
        .toPromise()
        .then((item) => {
          this.formData.id = item.id;
        });
    } else {
      return this.fileService
        .Post({ body: this.formData })
        .toPromise()
        .then((item) => {
          this.formData.id = item.id;
        });
    }
  }

  onCancel(): Promise<void> {
    return new Promise<void>((resolve) => resolve());
  }

  onDelete(): Promise<void> {
    return new Promise<void>((resolve) => resolve());
  }

  async getFields(): Promise<FieldConfig[]> {
    return new Promise<FieldConfig[]>(resolve => resolve([]));
  }
}
