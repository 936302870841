import { hasValue } from 'app/layouts/naxos-framework/services';
import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { EnumUserRole, UserDto } from "app/api/octopus-web/models";
import { AuthService } from "app/security/services";
import { popup } from "environments/environment";
import { CardFormComponent } from 'app/layouts/shared/forms/generic/card-form/card-form.component';

@Component({
  selector: "app-profile-view",
  templateUrl: "./profile-view.component.html",
  styleUrls: ["./profile-view.component.scss"],
})
export class ProfileViewComponent implements OnInit {
  user: UserDto = null;
  hasCompanyLicense = false;
  hasCompany = false;
  isCompanyAdmin = false;
  isNaxosAdmin = false;
  isFormReady = false;
  public userDialogRef: MatDialogRef<CardFormComponent>;
  public UserRoles = EnumUserRole;

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    if (hasValue(this.authService.currentUser)) {
      this.user = this.authService.currentUser;
    }
    this.authService.currentUser$.subscribe({
      next: (currentUser) => {
        this.user = currentUser;
      },
    });
  }
}
