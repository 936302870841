import { Injectable } from "@angular/core";
import { CompanyDto } from "app/api/octopus-web/models";
import { CompanyService } from "app/api/octopus-web/services";
import { FieldConfig } from "app/layouts/naxos-framework/form/field.interface";
import { isGuid } from "app/layouts/naxos-framework/services";
import { FormService } from "../models/form-service.interface";

@Injectable({
  providedIn: "root",
})
export class CompanyFormService implements FormService {
  isDynamic: boolean = false;
  formData: CompanyDto;
  title: string = "";
  icon: string = "nature_people";

  constructor(
    private companyService: CompanyService
  ) { }

  onSave(value: any): Promise<any> {
    if (isGuid(this.formData.id)) {
      return this.companyService
        .GetById({
          id: this.formData.id,
        })
        .toPromise()
        .then((item) => {
          const result = <CompanyDto>value;

          let newCompany = item;

          newCompany.institutionStreet = result.institutionStreet;
          newCompany.institutionStreetNumber = result.institutionStreetNumber;
          newCompany.institutionCountry = result.institutionCountry;
          newCompany.institutionZipCode = result.institutionZipCode;
          newCompany.institutionCity = result.institutionCity;
          newCompany.institutionName = result.institutionName;
          newCompany.currency = result.currency;
          newCompany.siret = result.siret;
          newCompany.lng = result.lng;
          newCompany.lat = result.lat;

          return this.companyService
            .Put({ id: newCompany.id, body: newCompany })
            .toPromise()
            .then((item) => {
              this.formData = JSON.parse(JSON.stringify(item));
            });
        });
    } else {
      const result = <CompanyDto>value;

      let newCompany = JSON.parse(JSON.stringify(this.formData));;

      newCompany.institutionStreet = result.institutionStreet;
      newCompany.institutionStreetNumber = result.institutionStreetNumber;
      newCompany.institutionCountry = result.institutionCountry;
      newCompany.institutionZipCode = result.institutionZipCode;
      newCompany.institutionCity = result.institutionCity;
      newCompany.institutionName = result.institutionName;
      newCompany.currency = result.currency;
      newCompany.siret = result.siret;
      newCompany.lng = result.lng;
      newCompany.lat = result.lat;

      return this.companyService
        .Post({ body: newCompany })
        .toPromise()
        .then((item) => {
          this.formData = JSON.parse(JSON.stringify(item));
        });
    }
  }

  onCancel(): Promise<void> {
    return new Promise<void>(resolve => resolve());
  }

  onDelete(): Promise<void> {
    return new Promise<void>(resolve => resolve());
  }

  async getFields(): Promise<FieldConfig[]> {
    return new Promise<FieldConfig[]>(resolve => resolve([]));
  }
}
