import {
  Component,
  Input,
  OnInit
} from "@angular/core";
const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};
import { SidebarService } from "../sidebar/services/sidebar.service";
import { MenusService } from "app/services/menus.service";
import { Menu } from "app/models/menus/menu";
import { EnumMenu } from "app/models/menus/enum-menu";
import { SignupWarningComponent } from "app/layouts/public/view/signup-warning/signup-warning.component";
import { MatDialog } from "@angular/material/dialog";
import { homeUrl } from "environments/environment";
import { LazyMapsAPILoader } from "@agm/core";
import { Observable } from "rxjs";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  @Input() layout: string;
  isLoggedIn: boolean;
  isCollapsed = false;

  navMainMenu: Menu;
  navPublicAccountMenu: Menu;
  menus: Menu[] = [];

  EnumMenuAccount = EnumMenu[EnumMenu.account];
  EnumMenuNavPublic = EnumMenu[EnumMenu.navPublic];

  homeUrl: string;
  navMenuService : Menu;
  navMenuService$ : Observable<Menu>;

  constructor(
    public dialog: MatDialog,
    private sidebarService: SidebarService,
    private menusService: MenusService,
  ) {
  
    if (this.layout === "admin" )
      this.navMenuService = this.menusService.navManagementMenu;
    else
      this.navMenuService = this.menusService.navPublicMainMenu;

    if (this.navMenuService) {
      this.navMainMenu = this.navMenuService;
      this.addOrUpdateMenu(this.navMainMenu);
    }

    if (this.layout === "admin" )
      this.navMenuService$ = this.menusService.navManagementMenu$;
    else
      this.navMenuService$ = this.menusService.navPublicMainMenu$;

    this.navMenuService$.subscribe({
      next: (navMenu) => {
        if (this.layout === "admin" )
        {
          this.navMainMenu = navMenu;
          if (this.navMainMenu == null) {
            this.removeMenu(EnumMenu[EnumMenu.navManagement]);
          } else {
            this.addOrUpdateMenu(this.navMainMenu);
          }
        }
        else
        {
          this.navMainMenu = navMenu;
          if (this.navMainMenu == null) {
            this.removeMenu(EnumMenu[EnumMenu.navPublic]);
          } else {
            this.addOrUpdateMenu(this.navMainMenu);
          }
        }
      }
    });

    if (this.menusService.navPublicAccountMenu) {
      this.navPublicAccountMenu = this.menusService.navPublicAccountMenu;
      this.addOrUpdateMenu(this.navPublicAccountMenu);
    }
    this.menusService.navPublicAccountMenu$.subscribe({
      next: (navPublicAccountMenu) => {
        this.navPublicAccountMenu = navPublicAccountMenu;
        if (this.navPublicAccountMenu == null) {
          this.removeMenu(EnumMenu[EnumMenu.account]);
        } else {
          this.addOrUpdateMenu(this.navPublicAccountMenu);
        }
      }
    });
   }

  ngOnInit() {
    this.homeUrl = homeUrl;
  }

  addOrUpdateMenu(menu: Menu) {
    var existingMenu = this.menus.find(x => x.id === menu.id);
    if (existingMenu) {
      this.removeMenu(existingMenu.id);
    }
    this.menus.push(menu);
    this.sortMenus();
  }

  sortMenus() {
    this.menus.sort(function (a, b) {
      return EnumMenu[a.id].order - EnumMenu[b.id].order;
    });
  }

  removeMenu(menuId: string) {
    var index = this.menus.map(x => {
      return x.id;
    }).indexOf(menuId);
    this.menus.splice(index, 1);
  }

  openWarning(){
    this.dialog.open(SignupWarningComponent);
  }

  minimizeSidebar() {
    this.sidebarService.minimizeSidebar();
  }
  sidebarToggle() {
    this.sidebarService.sidebarToggle();
  }
}
