import { Injectable } from "@angular/core";
import { SimulationDto } from "app/api/octopus-web/models";
import { SimulationService } from "app/api/octopus-web/services";
import { AuthService } from "app/security/services";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CurrentSimulationService {
  simulation: SimulationDto;
  simulation$: Observable<SimulationDto>;
  simulationSubject: Subject<SimulationDto>;

  constructor(private simulationService: SimulationService) {
    this.simulationSubject = new Subject();
    this.simulation$ = this.simulationSubject.asObservable();
    this.simulation$.subscribe({
      next: (simulation) => {
        this.simulation = simulation;
      },
    });
  }

  setSimulation() {
    this.simulationService.GetAsync().subscribe((simulationData) => {
      this.simulationSubject.next(simulationData);
    });
  }

  changeSimulation(simulation: SimulationDto) {
    this.simulationSubject.next(simulation);
  }

  stopSimulation() {
    this.simulationService
      .DeleteAsync()
      .toPromise()
      .then((data) => {
        this.changeSimulation(null);
      });
  }
}
