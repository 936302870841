import { Router } from '@angular/router';
import { SortContentItemsService } from 'app/services/sort-content-items.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss']
})
export class SlideComponent implements OnInit {
  public slideId: any;
  content;

  constructor(private _route: ActivatedRoute,
              private itemsService: SortContentItemsService,
              private router: Router) {
      // ...
  }

  ngOnInit() {
      // // For a static snapshot of the route...
      // this.slideId = this._route.snapshot.paramMap.get('slide');

      // // For subscribing to the observable paramMap...
      // this._route.paramMap.pipe(
      //     switchMap((params: ParamMap) => this.slideId = params.get('slide'))
      // );

      // Or as an alternative, with slightly different execution...
      this._route.paramMap.subscribe((params: ParamMap) =>  {
          this.slideId = parseInt(params.get('slide'));
      });

      this.itemsService
      .GetFlattenItemByCode({
        applicationCode: "octopus-web",
        itemCode: "tour_sliders",
      })
      .subscribe((content) => {
        //this.itemsService.sortSubItems(content);
        this.content = content;
      });
  }
}
