import { DownloadLinkDto } from './../../../../api/licence/models/download-link-dto';
import { Router } from '@angular/router';
import { DownloadLinksService } from 'app/api/licence/services';
import { Component, OnInit } from '@angular/core';
import { RoutePaths } from 'app/models/menus-routes/route-paths';

@Component({
  selector: 'app-naxos-download-view',
  templateUrl: './naxos-download-view.component.html',
  styleUrls: ['./naxos-download-view.component.scss']
})
export class NaxosDownloadViewComponent implements OnInit {
  title = "Liens de téléchargement";
  icon = "cloud_download";


  downloadLinks: DownloadLinkDto [];

  constructor(
    private downloadLinkService: DownloadLinksService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.downloadLinkService.GetAll().subscribe(data =>{
      this.downloadLinks = data;
    })
  }

  redirectToForm(id: string){
    this.router.navigateByUrl(RoutePaths.updateDownload.path + id);
  }


}
