import { SidebarService } from "../../../components/sidebar/services/sidebar.service";
import { Component, OnInit, ViewChild, AfterViewInit, AfterViewChecked, HostListener } from "@angular/core";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
  PopStateEvent,
} from "@angular/common";
import { NavbarComponent } from "../../../components/navbar/navbar.component";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { Observable, Subscription, filter } from "rxjs";
import { AuthService } from "app/security/services";
import PerfectScrollbar from "perfect-scrollbar";
import { PerfectScrollbarDirective, PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";

declare const $: any;

@Component({
  selector: "app-public-layout",
  templateUrl: "./public-layout.component.html",
  styleUrls: ["./public-layout.component.scss"],
})
export class PublicLayoutComponent implements OnInit, AfterViewInit, AfterViewChecked {
  private _router: Subscription;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  isAuthenticated: Observable<boolean>;
  isLoggedIn = false;
  sidebarInvisible = false;
  psSidebar: PerfectScrollbar;
  psMainPanel: PerfectScrollbar;

  config;
  @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;

  constructor(
    public location: Location,
    private router: Router,
    private authService: AuthService,
    private sidebarService: SidebarService
  ) {
    this.config = PERFECT_SCROLLBAR_CONFIG;

    this.location = location;
    this.isLoggedIn = this.authService.loggedIn;
    this.authService.loggedIn$.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
      this.setSidebarInvisible();
    });

    this.setSidebarInvisible();
  }

  setSidebarInvisible() {
    if (!this.isLoggedIn && !this.isMobileMenu()) {
      this.sidebarInvisible = true;
    } else {
      this.sidebarInvisible = false;
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.updatePerfectScrollbar();
    this.setSidebarInvisible();
  }

  ngOnInit() {
    const elemSidebar = <HTMLElement>(
      document.querySelector("app-public-layout .sidebar .sidebar-wrapper")
    );
    this.psSidebar = new PerfectScrollbar(elemSidebar);

    const elemNavbar = <HTMLElement>document.getElementById("navbarNaxos");
    this.sidebarService.navbarElement = elemNavbar;
    this.sidebarService.ngOnInit();
    this.sidebarService.sidebarClose();

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        }
      } else if (event instanceof NavigationEnd) {
        if (event.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
      }
    });

    this._router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        elemSidebar.scrollTop = 0;
        this.perfectScrollbarDirectiveRef.scrollToTop(0, 1);
      });
    const html = document.getElementsByTagName("html")[0];
    html.classList.add("perfect-scrollbar-on");
  }

  ngAfterViewInit() {
    this.updatePerfectScrollbar();
  }

  ngAfterViewChecked(): void {
    this.updatePerfectScrollbar();
  }

  public isMap() {
    if (
      this.location.prepareExternalUrl(this.location.path()) ===
      "/maps/fullscreen"
    ) {
      return true;
    } else {
      return false;
    }
  }

  updatePerfectScrollbar(): void {
    if (this.psSidebar) {
      this.psSidebar.update();
    }
    if (this.perfectScrollbarDirectiveRef) {
      this.perfectScrollbarDirectiveRef.update();
    }
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  public isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }
}
