import { Component, OnInit } from '@angular/core';
import { NaxosNotificationService } from 'app/security/services/NaxosNotification.service';
import { SortContentItemsService } from 'app/services/sort-content-items.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  aboutUs;

  constructor(
    private itemsService: SortContentItemsService,
    private notifications: NaxosNotificationService,
  ) { }

  ngOnInit(): void {
    this.itemsService
      .GetFlattenItemByCode({
        applicationCode: "octopus-web",
        itemCode: "page-about-us",
        locale: "fr"
      })
      .subscribe(aboutUs => {
        this.aboutUs = aboutUs;
      }, (error) => {
        this.notifications.Error('Could not load about-us page', error);
      });
  }

}
