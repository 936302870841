/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoreApiConfiguration, CoreApiConfigurationInterface } from './core-api-configuration';

import {  CoreService } from './services/core.service';


/**
 * Provider for all CoreApi services, plus CoreApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CoreApiConfiguration,
    CoreService
  ],
})
export class CoreApiModule {
  static forRoot(customParams: CoreApiConfigurationInterface): ModuleWithProviders<any> {
    return {
      ngModule: CoreApiModule,
      providers: [
        {
          provide: CoreApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
