import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InputComponent } from "./form/input/input.component";
import { ButtonComponent } from "./form/button/button.component";
import { SelectComponent } from "./form/select/select.component";
import { DateComponent } from "./form/date/date.component";
import { RadiobuttonComponent } from "./form/radiobutton/radiobutton.component";
import { CheckboxComponent } from "./form/checkbox/checkbox.component";
import { DynamicFieldDirective } from "./form/dynamic-field/dynamic-field.directive";
import { MaterialModule } from "./material.module";
import { NaxosNotificationComponent } from "./form/naxos-notification/naxos-notification.component";
import { DynamicFormComponent } from "./form/dynamic-form/dynamic-form.component";

import { RichTextComponent } from "./form/rich-text/rich-text.component";
import { DisableControlDirective } from "./disable-control.directive";
import { TranslateModule } from "@ngx-translate/core";
import { ChipListComponent } from "./form/chip-list/chip-list.component";
import { NaxosCardComponent } from "./view/naxos-card/naxos-card.component";
import { GenericComponent } from "./view/generic/generic.component";
import { ToolbarComponent } from "./form/toolbar/toolbar.component";
import { ToolbarEditionComponent } from "./form/toolbar-edition/toolbar-edition.component";
import { ToolbarEditionConfirmComponent } from "./form/toolbar-edition-confirm/toolbar-edition-confirm.component";
import { SelectObjectComponent } from "./form/select-object/select-object.component";
import { TextareaComponent } from "./form/textarea/textarea.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
  ],
  declarations: [
    //Dynamic
    SelectObjectComponent,
    ToolbarComponent,
    ToolbarEditionComponent,
    ToolbarEditionConfirmComponent,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    RichTextComponent,
    NaxosNotificationComponent,
    DynamicFieldDirective,
    DynamicFormComponent,
    DisableControlDirective,
    ChipListComponent,
    NaxosCardComponent,
    GenericComponent,
    TextareaComponent,
  ],
  exports: [
    //Dynamic

    SelectObjectComponent,
    ToolbarComponent,
    ToolbarEditionComponent,
    ToolbarEditionConfirmComponent,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    ChipListComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    DynamicFormComponent,
    RichTextComponent,
    NaxosCardComponent,
    GenericComponent,
    DynamicFieldDirective,
    DisableControlDirective,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    TextareaComponent,
  ],
  entryComponents: [
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    DynamicFormComponent,
    NaxosNotificationComponent,
    RichTextComponent,
    ChipListComponent,
    GenericComponent,
    SelectObjectComponent,
    TextareaComponent,
  ]
})
export class NaxosFrameworkModule {}
