import { Component, Input, OnInit } from "@angular/core";
import { trigger, state, style, transition, animate, } from "@angular/animations";
import { AuthService } from "app/security/services";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { homeUrl, msalConfig } from "environments/environment";
import { SidebarService } from "./services/sidebar.service";
import { Menu } from "../../models/menus/menu";
import { UserDto } from "app/api/octopus-web/models";
import { RoutePaths } from "app/models/menus-routes/route-paths";
import { MenusService } from "app/services/menus.service";
import { CurrentSimulationService } from "app/services/current-simulation.service";
import { hasValue } from "app/layouts/naxos-framework/services";
import { EnumMenu } from "app/models/menus/enum-menu";

declare const $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  animations: [
    trigger("slide", [
      state("up", style({ height: 0 })),
      state("down", style({ height: "*" })),
      transition("up <=> down", animate(200)),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  @Input() layout: string;

  sidebarMenu: Menu[];

  hasLicense = false;
  isLoggedIn = false;
  homeUrl = homeUrl;
  EnumMenuNavManagement = EnumMenu[EnumMenu.navManagement];
  EnumMenuNavPublic = EnumMenu[EnumMenu.navPublic];

  public menus: Menu[] = [];
  public modeSimulationActive = false;
  public currentUser: UserDto;

  constructor(
    public router: Router,
    public sidebarservice: SidebarService,
    public auth: AuthService,
    public dialog: MatDialog,
    public authService: AuthService,
    private menusService: MenusService,
    private currentSimulationService: CurrentSimulationService
  ) {

    this.menusService.sidebarMenu$.subscribe({
      next: (sidebarMenu) => {
        this.sidebarMenu = sidebarMenu;
        this.resetMenu(this.sidebarMenu);
      }
    });
    if (this.menusService.sidebarMenu) {
      this.sidebarMenu = this.menusService.sidebarMenu;
      this.sidebarMenu.forEach(i => this.addOrUpdateMenu(i));
    }

    if (hasValue(this.currentSimulationService.simulation)) {
      this.modeSimulationActive = true;
    }
    this.currentSimulationService.simulation$.subscribe({
      next: (simulation) => {
        this.modeSimulationActive = hasValue(simulation);
      }
    })
  }

  ngOnInit() {
  }

  getMenuUserTitle(menu: Menu)
  {
    if (menu == null || menu.name == null || menu.name == "")
      return "";
    return menu.name.split(";")[0];
  }
  getMenuCompanyTitle(menu: Menu)
  {
    if (menu == null || menu.name == null || menu.name == "")
      return "";
    var m = menu.name.split(";");
    if (menu.name.split(";")[1] == null)
      return "";
    return m.length > 1 ? menu.name.split(";")[1] : "";
  }

  addOrUpdateMenu(menu: Menu) {
    if (this.menus != null || this.menus != undefined) {
      
      var existingMenu = this.menus.find(x => x.id === menu.id);

      if (existingMenu) {
        this.removeMenu(existingMenu.id);
      }
      this.menus.push(menu);
      this.sortMenus();
    }
  }

  resetMenu(menu: Menu[]) {
    if (this.menus != null || this.menus != undefined) {
      this.menus = menu;
      this.sortMenus();
    }
    else      
      this.menus = [];
  }

  sortMenus() {
    if (this.auth.isNaxosAdmin) {
      this.menus.sort(function (a, b) {
        return EnumMenu[b.id].order - EnumMenu[a.id].order;
      });
    }
    else {
      this.menus.sort(function (a, b) {
        return EnumMenu[a.id].order - EnumMenu[b.id].order;
      });
    }
  }

  removeMenu(menuId: string) {
    var index = this.menus.map(x => {
      return x.id;
    }).indexOf(menuId);
    this.menus.splice(index, 1);
  }

  getState(currentMenu) {
    if (currentMenu.active) {
      return "down";
    } else {
      return "up";
    }
  }

  stopSimulation() {
    this.currentSimulationService.stopSimulation();
    this.router.navigateByUrl(RoutePaths.allCompanies.path);
  }

  public isNavManagementVisible() {
    return this.layout === 'admin' && this.isMobileMenu();
  }

  public isNavPublicVisible() {
    return this.layout === 'public' && this.isMobileMenu();
  }

  public isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

}
