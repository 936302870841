import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
@Component({
  selector: "app-rich-text",
  templateUrl: "./rich-text.component.html",
  styleUrls: ["./rich-text.component.scss"]
})
export class RichTextComponent implements OnInit {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() inputText: string;
  @Input() isModeEdit = false;
  editorOptions = { toolbar: false };
  preview = false;

  isFormReady = false;
  ngOnInit() {
    this.isFormReady = true;
  }

  setValue(value) {}

  toogleValueChange() {
    this.preview = !this.preview;
  }

  Submit() {
    this.onSubmit.emit(this.inputText);
  }
}
