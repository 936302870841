import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FieldConfig } from "app/layouts/naxos-framework/form/field.interface";
import { hasValue } from "app/layouts/naxos-framework/services";
import { FormService } from "app/layouts/shared/models/form-service.interface";
import { CompanyFormService } from "app/layouts/shared/services/company-form.service";
import { Company2FormService } from "app/layouts/shared/services/company2-form.service";
import { ContactFormService } from "app/layouts/shared/services/contact-form.service";
import { FileFormService } from "app/layouts/shared/services/file-form.service";
import { HostingFormService } from "app/layouts/shared/services/hosting-form.service";
import { UserFormService } from "app/layouts/shared/services/user-form.service";

@Component({
  selector: "app-card-form",
  templateUrl: "./card-form.component.html",
  styleUrls: ["./card-form.component.scss"],
})
export class CardFormComponent implements OnInit, OnChanges {
  @Input() inlineData: any;
  @Input() inlineType: string;
  @Output() OnSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnCancel: EventEmitter<any> = new EventEmitter<any>();
  type: string;
  title: string;
  icon: string;
  @Input() EnableSave = true;
  @Input() EnableDelete = true;
  @Input() EnableCancel = true;
  @Input() IsReadOnly = false;

  formService: FormService;
  fields: FieldConfig[] = [];
  isDialog = false;
  isFormReady = false;
  isDynamic = true;
  config;

  constructor(
    @Optional() public dialogRef: MatDialogRef<CardFormComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private dialogData: {
      current: any;
      type: string;
      EnableSave: boolean;
      EnableCancel: boolean;
      EnableDelete: boolean;
    } = null,
    private userFormService: UserFormService,
    private companyFormService: CompanyFormService,
    private company2FormService: Company2FormService,
    private hostingFormService: HostingFormService,
    private contactFormService: ContactFormService,
    private fileFormService: FileFormService
  ) {}

  async ngOnInit() {
    if (hasValue(this.dialogRef)) {
      this.isDialog = true;
    }

    if (this.isDialog) {
      this.type = this.dialogData.type;
      this.EnableCancel = this.dialogData.EnableCancel ?? true;
      this.EnableDelete = this.dialogData.EnableDelete ?? true;
      this.EnableSave = this.dialogData.EnableSave ?? true;
    } else {
      this.type = this.inlineType;
    }

    this.selectService();

    if (this.isDialog) {
      if (hasValue(this.dialogData.current)) {
        this.formService.formData = JSON.parse(
          JSON.stringify(this.dialogData.current)
        );
      } else {
        this.formService.formData = {};
      }
    } else {
      if (hasValue(this.inlineData)) {
        this.formService.formData = JSON.parse(JSON.stringify(this.inlineData));
      } else {
        this.formService.formData = {};
      }
    }

    this.isDynamic = this.formService.isDynamic;
    this.title = this.formService.title;
    this.icon = this.formService.icon;

    this.fields = await this.getFields();

    this.isFormReady = true;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (hasValue(this.dialogRef)) {
      this.isDialog = true;
    }

    if (this.isDialog) {
      this.type = this.dialogData.type;
    } else {
      this.type = this.inlineType;
    }

    this.selectService();

    if (hasValue(this.inlineData)) {
      this.formService.formData = JSON.parse(JSON.stringify(this.inlineData));
    } else {
      this.formService.formData = {};
    }

    this.fields = await this.getFields();
  }

  async getFields(): Promise<FieldConfig[]> {
    var allFields = await this.formService.getFields();
    if (this.IsReadOnly)
      allFields.forEach(t => t.disable = true);
    return allFields;
  }

  onSave(value) {
    this.formService.onSave(value).then(async () => {
      this.fields = await this.getFields();
      this.OnSave.emit(this.formService.formData);
      if (this.isDialog) {
        this.dialogRef.close();
      }
    });
  }

  onDelete() {
    this.formService.onDelete().then(async () => {
      this.fields = await this.getFields();
      this.OnDelete.emit();
      if (this.isDialog) {
        this.dialogRef.close();
      }
    });
  }

  onCancel() {
    this.formService.onCancel().then(async () => {
      if (!this.isDynamic) {
        if (hasValue(this.formService.formData)) {
          this.formService.formData = JSON.parse(
            JSON.stringify(this.formService.formData)
          );
        }
      }
      this.fields = await this.getFields();
      this.OnCancel.emit();
      if (this.isDialog) {
        this.dialogRef.close();
      }
    });
  }

  selectService() {
    switch (this.type) {
      case "user":
        this.formService = this.userFormService;
        break;
      case "company":
        this.formService = this.companyFormService;
        break;
      case "company2":
        this.formService = this.company2FormService;
        break;
      case "hosting":
        this.formService = this.hostingFormService;
        break;
      case "contact":
        this.formService = this.contactFormService;
        break;
      case "file":
        this.formService = this.fileFormService;
        break;
      default:
        throw new Error("form type not recognized in CardFormComponent");
    }
  }
}
