/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AddressApiConfiguration as __Configuration } from '../address-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CountryDTO } from '../models/country-dto';
@Injectable({
  providedIn: 'root',
})
class CountriesService extends __BaseService {
  static readonly GetAllPath = '/api/Countries';
  static readonly PostitemPath = '/api/Countries';
  static readonly GetByIdPath = '/api/Countries/{id}';
  static readonly PutitemPath = '/api/Countries/{id}';
  static readonly DeleteitemPath = '/api/Countries/{id}';
  static readonly ExistsPath = '/api/Countries/Exists{id}';
  static readonly GetCountryByLanguageAndNamePath = '/api/Countries/GetCountryByLanguageAndName';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }
  GetAllResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Countries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  GetAll(): __Observable<null> {
    return this.GetAllResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param item undefined
   */
  PostitemResponse(item?: CountryDTO): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = item;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Countries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param item undefined
   */
  Postitem(item?: CountryDTO): __Observable<null> {
    return this.PostitemResponse(item).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  GetByIdResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Countries/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  GetById(id: string): __Observable<null> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CountriesService.PutitemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `item`:
   */
  PutitemResponse(params: CountriesService.PutitemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.item;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Countries/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CountriesService.PutitemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `item`:
   */
  Putitem(params: CountriesService.PutitemParams): __Observable<null> {
    return this.PutitemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteitemResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Countries/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Deleteitem(id: string): __Observable<null> {
    return this.DeleteitemResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  ExistsResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Countries/Exists${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Exists(id: string): __Observable<null> {
    return this.ExistsResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CountriesService.GetCountryByLanguageAndNameParams` containing the following parameters:
   *
   * - `name`:
   *
   * - `isoLanguageCode`:
   */
  GetCountryByLanguageAndNameResponse(params: CountriesService.GetCountryByLanguageAndNameParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.isoLanguageCode != null) __params = __params.set('isoLanguageCode', params.isoLanguageCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Countries/GetCountryByLanguageAndName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CountriesService.GetCountryByLanguageAndNameParams` containing the following parameters:
   *
   * - `name`:
   *
   * - `isoLanguageCode`:
   */
  GetCountryByLanguageAndName(params: CountriesService.GetCountryByLanguageAndNameParams): __Observable<null> {
    return this.GetCountryByLanguageAndNameResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CountriesService {

  /**
   * Parameters for Putitem
   */
  export interface PutitemParams {
    id: string;
    item?: CountryDTO;
  }

  /**
   * Parameters for GetCountryByLanguageAndName
   */
  export interface GetCountryByLanguageAndNameParams {
    name?: string;
    isoLanguageCode?: string;
  }
}

export { CountriesService }
