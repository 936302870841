import { Component, OnInit } from '@angular/core';
import { NaxosNotificationService } from 'app/security/services/NaxosNotification.service';
import { SortContentItemsService } from 'app/services/sort-content-items.service';

@Component({
  selector: 'app-contact-data',
  templateUrl: './contact-data.component.html',
  styleUrls: ['./contact-data.component.css']
})
export class ContactDataComponent implements OnInit {
  label = "Naxos Information Technology";
  ready = false;

  address;
  phone;
  mobile;
  fax;
  email;

  constructor(
    private itemsService: SortContentItemsService,
    private notifications: NaxosNotificationService,
  ) { }

  ngOnInit(): void {
    this.itemsService
      .GetFlattenItemByCode({
        applicationCode: "octopus-web",
        itemCode: "page-contact",
        locale: "fr"
      })
      .subscribe(contact => {
        this.address = (<any[]>contact.Items).filter(
          (obj) => obj.Code === "page-contact-address"
        )[0];
        this.phone = (<any[]>contact.Items).filter(
          (obj) => obj.Code === "page-contact-phone"
        )[0];
        this.mobile = (<any[]>contact.Items).filter(
          (obj) => obj.Code === "page-contact-mobile"
        )[0];
        this.fax = (<any[]>contact.Items).filter(
          (obj) => obj.Code === "page-contact-fax"
        )[0];
        this.email = (<any[]>contact.Items).filter(
          (obj) => obj.Code === "page-contact-email"
        )[0];
        this.ready = true;
      }, (error) => {
        this.notifications.Error('Could not load contact page', error);
      });
  }

}
