import { LicencesService } from 'app/api/licence/services';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NaxosNotificationService } from 'app/security/services/NaxosNotification.service';
import { SortContentItemsService } from 'app/services/sort-content-items.service';
import { RoutePaths } from 'app/models/menus-routes/route-paths';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  subscription;
  assets;

  constructor(
    private itemsService: SortContentItemsService,
    private notifications: NaxosNotificationService,
    private router: Router,
    private licenceService: LicencesService
  ) { }

  ngOnInit(): void {
    this.itemsService
      .GetFlattenItemByCode({
        applicationCode: "octopus-web",
        itemCode: "page-subscription",
        locale: "fr"
      })
      .subscribe(subscription => {
        this.subscription = subscription;
      }, (error) => {
        this.notifications.Error('Could not load subscription page', error);
      });

      this.itemsService
      .GetFlattenItemByCode({
        applicationCode: "octopus-web",
        itemCode: "assets",
      })
      .subscribe((assets) => {
        this.itemsService.sortSubItems(assets);
        this.assets = assets;
      });
  }

  tryOctopusFree() {
    this.router.navigateByUrl(RoutePaths.companyLicences.path);
  }
}
