/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AddressApiConfiguration, AddressApiConfigurationInterface } from './address-api-configuration';

import { CitiesService } from './services/cities.service';
import { CountriesService } from './services/countries.service';
import { CurrenciesService } from './services/currencies.service';

/**
 * Provider for all AddressApi services, plus AddressApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    AddressApiConfiguration,
    CitiesService,
    CountriesService,
    CurrenciesService
  ],
})
export class AddressApiModule {
  static forRoot(customParams: AddressApiConfigurationInterface): ModuleWithProviders<any> {
    return {
      ngModule: AddressApiModule,
      providers: [
        {
          provide: AddressApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
