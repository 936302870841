import { UpdateDownloadViewComponent } from './view/update-download-view/update-download-view.component';
import { NaxosDownloadViewComponent } from './view/naxos-download-view/naxos-download-view.component';
import { UnsubscribeWarningComponent } from './view/unsubscribe-warning/unsubscribe-warning.component';
import { HasLicenceGuard } from './../../security/guard/has-licence.guard';
import { CompanyAdminGuard } from './../../security/guard/company-admin.guard';
import { TermsOfUseViewComponent } from './view/terms-of-use-view/terms-of-use-view.component';
import { Routes } from "@angular/router";
import { NaxosCompanyViewComponent } from "./view/naxos-company-view/naxos-company-view.component";
import { CompanyDashboardComponent } from "./view/company-dashboard/company-dashboard.component";
import { DownloadViewComponent } from "./view/download-view/download-view.component";
import { HostingsViewComponent } from "./view/hostings-view/hostings-view.component";
import { AlllicencesViewComponent } from "./view/alllicences-view/alllicences-view.component";
import { CompanylicencesViewComponent } from "./view/companylicences-view/companylicences-view.component";
import { RequestsViewComponent } from "./view/requests-view/requests-view.component";
import { AllUsersViewComponent } from "./view/allusers-view/allusers-view.component";
import { ProfileViewComponent } from './view/profile-view/profile-view.component';
import { NaxosAdminGuard } from 'app/security/guard/naxos-admin.guard';
import { CompanyRequiredGuard } from "app/security/guard/company-required.guard";
import { FinalFormBeforeLicenceComponent } from './view/final-form-before-licence/final-form-before-licence.component';
import { AdminAddCompanyComponent } from './view/admin-add-company/admin-add-company.component';
import { CompanyComponent } from './view/company/company.component';
import { UserDashboardComponent } from './view/user-dashboard/user-dashboard.component';
import { NaxosFileViewComponent } from './view/naxos-file-view/naxos-file-view.component';
import { AllprojectsViewComponent } from './view/allprojects-view/allprojects-view.component';
import { AllUsersCompanyViewComponent } from './view/allusers-company-view/allusers-company-view.component';

export const ManagementRoutes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  {
    path: "profile",
    component: ProfileViewComponent,
  },
  {
    path: "all-userscompany",
    component: AllUsersCompanyViewComponent
  },
  {
    path: "company-dashboard",
    component: CompanyDashboardComponent,
  },
  {
    path: "company",
    component: CompanyComponent,
  },
  {
    path: "all-users",
    component: AllUsersViewComponent,
    canActivate: [NaxosAdminGuard]
  },
  {
    path: "all-projects",
    component: AllprojectsViewComponent,
    canActivate: [HasLicenceGuard]
  },
  {
    path: "company-licences",
    component: CompanylicencesViewComponent,
    canActivate: [HasLicenceGuard]
  },
  {
    path: "all-licences",
    component : AlllicencesViewComponent,
    canActivate: [NaxosAdminGuard]
  },
  {
    path: "hostings",
    component: HostingsViewComponent,
    canActivate: [NaxosAdminGuard]
  },
  {
    path: "download",
    component: DownloadViewComponent,
    canActivate: [HasLicenceGuard]
  },
  {
    path: "naxos-download",
    component: NaxosDownloadViewComponent,
    canActivate: [NaxosAdminGuard]
  },
  {
    path: "update-download/:id",
    component: UpdateDownloadViewComponent,
    canActivate: [NaxosAdminGuard]
  },
  {
    path: "all-companies",
    component: NaxosCompanyViewComponent,
    canActivate: [NaxosAdminGuard]
  },
  {
    path: "naxos-files",
    component: NaxosFileViewComponent,
    canActivate: [NaxosAdminGuard]
  },
  {
    path: "all-licence-requests",
    component : RequestsViewComponent,
    canActivate: [NaxosAdminGuard]
  },
  {
    path: "naxos-dashboard",
    component: NaxosCompanyViewComponent,
    canActivate: [NaxosAdminGuard]
  },
  {
    path: "admin-add-company",
    component: AdminAddCompanyComponent,
    canActivate: [NaxosAdminGuard]
  },
  {
    path: "terms-of-use",
    component: TermsOfUseViewComponent,
    canActivate: [CompanyRequiredGuard]
  },
  {
    path: "unsubscribe-warning/:id",
    component: UnsubscribeWarningComponent,
    canActivate: [CompanyAdminGuard]
  },
  {
    path: "finalform",
    component: FinalFormBeforeLicenceComponent,
    canActivate: [CompanyRequiredGuard]
  },
  {
    path: "home",
    component: UserDashboardComponent,
  },

  { path: "**", redirectTo: "home" }
];
