/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CompanyDto } from '../models/company-dto';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllCompanies
   */
  static readonly GetAllCompaniesPath = '/api/Company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAll$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CompanyDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.GetAllCompaniesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompanyDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAll(params?: {
  }): Observable<Array<CompanyDto>> {

    return this.GetAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CompanyDto>>) => r.body as Array<CompanyDto>)
    );
  }

  /**
   * Path part for operation getCompanyById
   */
  static readonly GetCompanyByIdPath = '/api/Company/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<CompanyDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.GetCompanyByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetById(params: {
    id: string;
  }): Observable<CompanyDto> {

    return this.GetById$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDto>) => r.body as CompanyDto)
    );
  }

  /**
   * Path part for operation putCompany
   */
  static readonly PutCompanyPath = '/api/Company/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Put()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Put$Response(params: {
    id: string;
    body: CompanyDto
  }): Observable<StrictHttpResponse<CompanyDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.PutCompanyPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Put$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Put(params: {
    id: string;
    body: CompanyDto
  }): Observable<CompanyDto> {

    return this.Put$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDto>) => r.body as CompanyDto)
    );
  }

  /**
   * Path part for operation deleteCompany
   */
  static readonly DeleteCompanyPath = '/api/Company/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  Delete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<CompanyDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.DeleteCompanyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Delete(params: {
    id: string;
  }): Observable<CompanyDto> {

    return this.Delete$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDto>) => r.body as CompanyDto)
    );
  }

  /**
   * Path part for operation getCompanyCurrentId
   */
  static readonly GetCompanyCurrentIdPath = '/api/Company/CurrentId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `CurrentId()` instead.
   *
   * This method doesn't expect any request body.
   */
  CurrentId$Response(params?: {
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.GetCompanyCurrentIdPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `CurrentId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  CurrentId(params?: {
  }): Observable<string> {

    return this.CurrentId$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getCurrentCompany
   */
  static readonly GetCurrentCompanyPath = '/api/Company/CurrentCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetCurrentCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCurrentCompany$Response(params?: {
  }): Observable<StrictHttpResponse<CompanyDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.GetCurrentCompanyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetCurrentCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCurrentCompany(params?: {
  }): Observable<CompanyDto> {

    return this.GetCurrentCompany$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDto>) => r.body as CompanyDto)
    );
  }

  /**
   * Path part for operation postCompany
   */
  static readonly PostCompanyPath = '/api/Company/Post';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Post()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Post$Response(params: {
    body: CompanyDto
  }): Observable<StrictHttpResponse<CompanyDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.PostCompanyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Post$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Post(params: {
    body: CompanyDto
  }): Observable<CompanyDto> {

    return this.Post$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDto>) => r.body as CompanyDto)
    );
  }

  /**
   * Path part for operation companyWantsToUnsubscribe
   */
  static readonly CompanyWantsToUnsubscribePath = '/api/Company/WantsToUnsubscribe/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `WantsToUnsubscribe()` instead.
   *
   * This method doesn't expect any request body.
   */
  WantsToUnsubscribe$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<CompanyDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.CompanyWantsToUnsubscribePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `WantsToUnsubscribe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  WantsToUnsubscribe(params: {
    id: string;
  }): Observable<CompanyDto> {

    return this.WantsToUnsubscribe$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDto>) => r.body as CompanyDto)
    );
  }

  /**
   * Path part for operation unsubscribeCompany
   */
  static readonly UnsubscribeCompanyPath = '/api/Company/Unsubscribe/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `UnsubscribeCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  UnsubscribeCompany$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<CompanyDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.UnsubscribeCompanyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `UnsubscribeCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  UnsubscribeCompany(params: {
    id: string;
  }): Observable<CompanyDto> {

    return this.UnsubscribeCompany$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDto>) => r.body as CompanyDto)
    );
  }

}
