import { SortContentItemsService } from "app/services/sort-content-items.service";
import { Component, OnInit } from "@angular/core";


@Component({
  selector: "app-description",
  templateUrl: "./description.component.html",
  styleUrls: ["./description.component.scss"],
})
export class DescriptionComponent implements OnInit {
  description;

  constructor(
    private itemsService: SortContentItemsService,
    ) {}

  ngOnInit() {
    this.loadDescription();
  }

  loadDescription() {
    this.itemsService
      .GetFlattenItemByCode({
        applicationCode: "octopus-web",
        itemCode: "page-description",
      })
      .subscribe((description) => {
        this.itemsService.sortSubItems(description);
        this.description = description;
      });
  }
}
