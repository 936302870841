import { TranslateService } from '@ngx-translate/core';
import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { CompanyDto } from "app/api/octopus-web/models";
import { CompanyService } from "app/api/octopus-web/services";
import { FieldConfig } from "app/layouts/naxos-framework/form/field.interface";
import { isGuid } from "app/layouts/naxos-framework/services";
import { from } from "rxjs";
import { FormService } from "../models/form-service.interface";

@Injectable({
  providedIn: "root",
})
export class Company2FormService implements FormService {
  isDynamic: boolean = true;
  formData: CompanyDto;
  title: string = "";
  icon: string = "nature_people";

  constructor(
    private companyService: CompanyService,
    private translateService: TranslateService,
  ) {}

  async getFields(): Promise<FieldConfig[]> {
    const fields: FieldConfig[] = [
      {
        type: "input",
        inputType: "text",
        name: "managerName",
        label: this.translateService.instant('Manager name'),
        value: this.formData?.managerName
      },
      {
        type: "input",
        inputType: "date",
        name: "legalCreationDate",
        label: this.translateService.instant('Legal creation date'),
        value: this.formData?.legalCreationDate,
      },
      {
        type: "input",
        inputType: "text",
        name: "creditorIdentifier",
        label: this.translateService.instant('Creditor identifier'),
        value: this.formData?.creditorIdentifier,
      },
      {
        type: "input",
        inputType: "text",
        name: "websiteLink",
        label: this.translateService.instant('Website'),
        value: this.formData?.webSiteLink,
      },
      {
        type: "input",
        inputType: "text",
        name: "faxNumber",
        label: this.translateService.instant('Fax number'),
        value: this.formData?.faxNumber,
      },
      {
        type: "input",
        inputType: "text",
        name: "numberOfClients",
        label: this.translateService.instant('Number of clients'),
        value: this.formData?.numberOfClients,
      },
      {
        type: "input",
        inputType: "text",
        name: "portfolio",
        label: this.translateService.instant('Portfolio'),
        value: this.formData?.portfolio,
      },
      {
        type: "input",
        inputType: "text",
        name: "depositPortfolio",
        label: this.translateService.instant('Deposit portfolio'),
        value: this.formData?.depositPortfolio,
      },
    ];

    return new Promise<FieldConfig[]>((resolve) => {
      resolve(fields);
    });
  }

  async onSave(value: any): Promise<any> {
    if (isGuid(this.formData.id)) {
      return this.companyService
        .GetById({ id: this.formData.id })
        .toPromise()
        .then(async (item) => {
          this.formData = item;

          const result = <CompanyDto>value;

          this.formData.managerName = result.managerName;
          this.formData.legalCreationDate = result.legalCreationDate;
          this.formData.creditorIdentifier = result.creditorIdentifier;
          this.formData.webSiteLink = result.webSiteLink;
          this.formData.faxNumber = result.faxNumber;
          this.formData.numberOfClients = result.numberOfClients;
          this.formData.portfolio = result.portfolio;
          this.formData.depositPortfolio = result.depositPortfolio;

          return this.companyService
            .Put({ id: this.formData.id, body: this.formData })
            .toPromise()
            .then((item) => {
              this.formData = item;
            });
        });
    }
  }

  onCancel() {
    return new Promise<void>(resolve => resolve());
  }

  onDelete() {
    if (isGuid(this.formData.id)) {
      return this.companyService
        .Delete({ id: this.formData.id })
        .toPromise()
        .then((itm) => {
          this.formData = null;
        });
    }
  }
}
