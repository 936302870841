import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutePaths } from 'app/models/menus-routes/route-paths';
import { AuthService } from 'app/security/services';

@Component({
  selector: 'app-company-dashboard',
  templateUrl: './company-dashboard.component.html',
  styleUrls: ['./company-dashboard.component.scss']
})
export class CompanyDashboardComponent implements OnInit {
  hasCompanyLicense = false;
  hasCompany = false;
  hasBeenLoaded = false;

  constructor(
    public authService: AuthService,
    private router: Router
  ) {
    this.hasCompanyLicense = this.authService.hasCompanyLicense;
    this.authService.hasCompanyLicense$.subscribe({
      next: (hasCompanyLicense) => {
        this.hasCompanyLicense = hasCompanyLicense;
        this.hasBeenLoaded = true;
      },
    });
    this.hasCompany = this.authService.hasCompany;
    this.authService.hasCompany$.subscribe({
      next: (hasCompany) => {
        this.hasCompany = hasCompany;
      },
    });
  }

  ngOnInit(): void {
  }

  askTrialLicence() {
    this.router.navigateByUrl(RoutePaths.finalForm.path);
  }
}
