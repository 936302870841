/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CmApiConfiguration, CmApiConfigurationInterface } from './cm-api-configuration';

import {  CmService } from './services/cm.service';


/**
 * Provider for all CmApi services, plus CmApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CmApiConfiguration,
    CmService
  ],
})
export class CmApiModule {
  static forRoot(customParams: CmApiConfigurationInterface): ModuleWithProviders<any> {
    return {
      ngModule: CmApiModule,
      providers: [
        {
          provide: CmApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
