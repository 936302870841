import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-alluserscompany-view",
  templateUrl: "./allusers-company-view.component.html",
  styleUrls: ["./allusers-company-view.component.scss"]
})
export class AllUsersCompanyViewComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
