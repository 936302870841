/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HostingDto } from '../models/hosting-dto';
import { HostingEnvironment } from '../models/hosting-environment';
import { HostingRequest } from '../models/hostingRequest';

@Injectable({
  providedIn: 'root',
})
export class HostingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation hostingsGetAll
   */
  static readonly HostingsGetAllPath = '/api/Hostings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAll$Response(params?: {
  }): Observable<StrictHttpResponse<Array<HostingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, HostingsService.HostingsGetAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HostingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAll(params?: {
  }): Observable<Array<HostingDto>> {

    return this.GetAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HostingDto>>) => r.body as Array<HostingDto>)
    );
  }

  /**
   * Path part for operation hostingsPost
   */
  static readonly HostingsPostPath = '/api/Hostings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Post()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Post$Response(params: {
    body: HostingDto
  }): Observable<StrictHttpResponse<HostingDto>> {

    const rb = new RequestBuilder(this.rootUrl, HostingsService.HostingsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HostingDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Post$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Post(params: {
    body: HostingDto
  }): Observable<HostingDto> {

    return this.Post$Response(params).pipe(
      map((r: StrictHttpResponse<HostingDto>) => r.body as HostingDto)
    );
  }

  /**
   * Path part for operation hostingsGetById
   */
  static readonly HostingsGetByIdPath = '/api/Hostings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<HostingDto>> {

    const rb = new RequestBuilder(this.rootUrl, HostingsService.HostingsGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HostingDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetById(params: {
    id: string;
  }): Observable<HostingDto> {

    return this.GetById$Response(params).pipe(
      map((r: StrictHttpResponse<HostingDto>) => r.body as HostingDto)
    );
  }

  /**
   * Path part for operation hostingsPut
   */
  static readonly HostingsPutPath = '/api/Hostings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Put()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Put$Response(params: {
    id: string;
    body: HostingDto
  }): Observable<StrictHttpResponse<HostingDto>> {

    const rb = new RequestBuilder(this.rootUrl, HostingsService.HostingsPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HostingDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Put$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Put(params: {
    id: string;
    body: HostingDto
  }): Observable<HostingDto> {

    return this.Put$Response(params).pipe(
      map((r: StrictHttpResponse<HostingDto>) => r.body as HostingDto)
    );
  }

  /**
   * Path part for operation hostingsDelete
   */
  static readonly HostingsDeletePath = '/api/Hostings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  Delete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<HostingDto>> {

    const rb = new RequestBuilder(this.rootUrl, HostingsService.HostingsDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HostingDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Delete(params: {
    id: string;
  }): Observable<HostingDto> {

    return this.Delete$Response(params).pipe(
      map((r: StrictHttpResponse<HostingDto>) => r.body as HostingDto)
    );
  }

  /**
   * Path part for operation hostingsGetForLicenceAndEnvironment
   */
  static readonly HostingsGetLicenceAndEnvironmentPath = '/api/Hostings/Hosting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetForLicenceAndEnvironment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  GetForLicenceAndEnvironment$Response(params: {
    hostingEnvironment: HostingEnvironment;
    body: string
  }): Observable<StrictHttpResponse<HostingDto>> {

    const rb = new RequestBuilder(this.rootUrl, HostingsService.HostingsGetLicenceAndEnvironmentPath, 'post');
    if (params) {
      rb.path('hostingEnvironment', params.hostingEnvironment, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HostingDto>;
      })
    );
  }

  GetLicenceAndEnvironment$Response(hostingRequest: HostingRequest): Observable<StrictHttpResponse<HostingDto>> {

    const rb = new RequestBuilder(this.rootUrl, HostingsService.HostingsGetLicenceAndEnvironmentPath, 'post');
    if (hostingRequest) {
      rb.body(hostingRequest, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HostingDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetForLicenceAndEnvironment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  GetForLicenceAndEnvironment(params: {
    hostingEnvironment: HostingEnvironment;
    body: string
  }): Observable<HostingDto> {

    return this.GetForLicenceAndEnvironment$Response(params).pipe(
      map((r: StrictHttpResponse<HostingDto>) => r.body as HostingDto)
    );
  }

    /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetLicenceAndEnvironment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
    GetLicenceAndEnvironment(hostingRequest: HostingRequest): Observable<HostingDto> {
  
      return this.GetLicenceAndEnvironment$Response(hostingRequest).pipe(
        map((r: StrictHttpResponse<HostingDto>) => r.body as HostingDto)
      );
    }

  /**
   * Path part for operation hostingsGetCurrentCmConnectionStringForEnvironment
   */
  static readonly HostingsGetCurrentCmConnectionStringForEnvironmentPath = '/api/Hostings/GetCurrentCMConnectionStringForEnvironment/{hostingEnvironment}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetCurrentCMConnectionStringForEnvironment()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCurrentCMConnectionStringForEnvironment$Response(params: {
    hostingEnvironment: HostingEnvironment;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, HostingsService.HostingsGetCurrentCmConnectionStringForEnvironmentPath, 'get');
    if (params) {
      rb.path('hostingEnvironment', params.hostingEnvironment, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetCurrentCMConnectionStringForEnvironment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCurrentCMConnectionStringForEnvironment(params: {
    hostingEnvironment: HostingEnvironment;
  }): Observable<string> {

    return this.GetCurrentCMConnectionStringForEnvironment$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation hostingsGetCurrentCoreConnectionStringForEnvironment
   */
  static readonly HostingsGetCurrentCoreConnectionStringForEnvironmentPath = '/api/Hostings/GetCurrentCoreConnectionStringForEnvironment/{hostingEnvironment}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetCurrentCoreConnectionStringForEnvironment()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCurrentCoreConnectionStringForEnvironment$Response(params: {
    hostingEnvironment: HostingEnvironment;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, HostingsService.HostingsGetCurrentCoreConnectionStringForEnvironmentPath, 'get');
    if (params) {
      rb.path('hostingEnvironment', params.hostingEnvironment, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetCurrentCoreConnectionStringForEnvironment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCurrentCoreConnectionStringForEnvironment(params: {
    hostingEnvironment: HostingEnvironment;
  }): Observable<string> {

    return this.GetCurrentCoreConnectionStringForEnvironment$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
