import { Validators } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { hasValue } from "app/layouts/naxos-framework/services";
import { Component, OnInit } from "@angular/core";

import {
  CompanyService,
  SimulationService,
} from "app/api/octopus-web/services";
import { CompanyDto, SimulationDto } from "app/api/octopus-web/models";
import {
  RequestDto,
  LicenceDto,
  LicenceType,
  RequestStatus,
} from "app/api/licence/models";
import { LicencesService, RequestsService } from "app/api/licence/services";
import { AuthService } from "app/security/services";
import { RoutePaths } from "app/models/menus-routes/route-paths";

@Component({
  selector: "app-companylicences-view",
  templateUrl: "./companylicences-view.component.html",
  styleUrls: ["./companylicences-view.component.scss"],
})
export class CompanylicencesViewComponent implements OnInit {
  title = "Données d'abonnement";
  icon = "card_membership";

  isOnGoingRequest: boolean;
  isFormReady = false;
  companyId: string;
  company: CompanyDto;
  dateForm: FormGroup;
  license: LicenceDto;
  hasCompanyLicense = false;

  constructor(
    private licenceService: LicencesService,
    private licenceRequestService: RequestsService,
    private simulationService: SimulationService,
    private companyService: CompanyService,
    public authService: AuthService,
    private router: Router,
    private builder: FormBuilder
  ) {}

  async ngOnInit() {
    this.company = this.authService.currentCompany;
    this.license = this.authService.companyLicense;
    this.hasCompanyLicense = this.authService.hasCompanyLicense;
    this.authService.hasCompanyLicense$.subscribe({
      next: async (hasCompanyLicense) => {
        this.company = this.authService.currentCompany;
 
        this.license = this.authService.companyLicense;
        this.hasCompanyLicense = hasCompanyLicense;
        await this.loadData();
      }
    });
    await this.loadData();
  }

  async loadData() {
    this.dateForm = this.builder.group({
      expirationDate: ["", Validators.required],
    });
    this.isFormReady = true;
    this.isOnGoingRequest = await this.onGoingRequest();
  }

  async onGoingRequest(): Promise<boolean> {
    if (hasValue(this.company?.id)) {
      return this.licenceRequestService
      .OnGoingRequest({ companyId: this.company.id })
      .toPromise()
      .then((data) => {
        return data != null;
      });
    } else {
      return false;
    }
  }

  askTrialLicence() {
    this.router.navigateByUrl(RoutePaths.finalForm.path);
  }

  upgradeLicence() {
    this.licenceService.Upgrade({ key: this.license.licenceKey }).subscribe();
    this.router.navigateByUrl(RoutePaths.company.path);
  }

  extendExpirationDate() {
    if (this.dateForm.valid) {
      this.licenceService
        .ExtendExpirationDate({
          key: this.license.licenceKey,
          body: this.dateForm.controls["expirationDate"].value,
        })
        .subscribe(() => {
          this.authService.setCompanyLicense();
        });
    }
  }
}
