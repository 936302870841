/* tslint:disable */
/* eslint-disable */
export enum ApplicationType {
  CoreClient = 'CoreClient',
  CoreServer = 'CoreServer',
  CMClient = 'CMClient',
  CMServer = 'CMServer',
  Servers = 'Servers',
  Clients = 'Clients',
  All = 'All'
}
