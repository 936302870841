import { Routes } from "@angular/router";
import { SlideComponent } from "./view/slide/slide.component";

export const OctopusTourRoutes: Routes = [
  {
    path: "",
    redirectTo: "slides/1",
    pathMatch: "full",
  },
  {
    path: "slides/:slide",
    component: SlideComponent,
  },
  { path: "**", redirectTo: "slides/1" },
];
