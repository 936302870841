import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { T } from '@angular/cdk/keycodes';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CompanyAlreadyExistsComponent } from '../company-already-exists/company-already-exists.component';
import { RoutePaths } from 'app/models/menus-routes/route-paths';

@Component({
  selector: 'app-signup-warning',
  templateUrl: './signup-warning.component.html',
  styleUrls: ['./signup-warning.component.scss']
})
export class SignupWarningComponent implements OnInit {

  form: FormGroup;
  choice: boolean;

  constructor(
    private router: Router,
    private builder: FormBuilder,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.form = this.builder.group({
      choiceControl: ['', Validators.required],
    })
  }

  redirectToSignup(){
    this.choice = this.form.controls['choiceControl'].value;
    if(!this.choice){
      this.router.navigateByUrl(RoutePaths.signupChoice.path);
      this.dialog.closeAll();
    }
    if(this.choice){
      this.dialog.open(CompanyAlreadyExistsComponent);

    }
  }
}
