import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-toolbar-edition-confirm",
  templateUrl: "./toolbar-edition-confirm.component.html",
  styleUrls: ["./toolbar-edition-confirm.component.scss"]
})
export class ToolbarEditionConfirmComponent implements OnInit {
  @Input() EnableSave = true;
  @Input() EnableDelete = true;
  @Input() EnableCancel = true;
  @Input() IsReadOnly = false;

  @Input() Form: FormGroup;
  @Output() OnSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnCancel: EventEmitter<any> = new EventEmitter<any>();
  @Input() isDialog: boolean = false;

  constructor() {}

  ngOnInit() {}

  onSave(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.OnSave.emit(event);
    // if (this.Form.valid) {
    //   this.OnSave.emit(this.Form.value);
    // } else {
    //   this.validateAllFormFields(this.Form);
    // }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  onDelete() {
    this.OnDelete.emit();
  }

  onCancel() {
    this.OnCancel.emit();
  }
}
