import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { NEVER, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { catchError, tap } from "rxjs/operators";
import { NotificationsService } from "app/shared/notifications/notifications.service";
import { ErrorService } from "app/shared/errors/error.service";

@Injectable({
  providedIn: "root",
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private notifications: NotificationsService,
    private error: ErrorService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (req.method !== "GET") {
              this.notifications.success(
                "The request was successfully executed",
                "Success"
              );
            }
          }
        },
      }),
      catchError((error: HttpErrorResponse) => {
        if (error?.error instanceof ErrorEvent) {
          console.log("This is a client-side error");
        }
        console.error(error);
        this.error.error(error);
        return NEVER;
      })
    );
  }
}
