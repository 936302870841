import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { FieldConfig } from "../field.interface";

@Component({
  selector: "app-dynamic-form",
  templateUrl: "./dynamic-form.component.html",
  styleUrls: ["./dynamic-form.component.scss"]
})
export class DynamicFormComponent implements OnInit, OnChanges {
  @Input() fields: FieldConfig[] = [];
  @Input() EnableSave = true;
  @Input() EnableDelete = true;
  @Input() EnableCancel = true;
  @Input() IsReadOnly = false;
  @Input() isDialog = false;

  @Output() OnSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnCancel: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;

  get value() {
    return this.form.value;
  }
  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.form = this.createControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form = this.createControl();
  }

  onSave(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.valid) {
      const value = this.form.getRawValue();
      this.OnSave.emit(value);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  onDelete() {
    event.preventDefault();
    event.stopPropagation();
    this.OnDelete.emit();
  }

  onCancel() {
    this.OnCancel.emit();
  }

  createControl() {
    const group = this.fb.group({});
    this.fields.forEach(field => {
      if (field.type === "button") return;
      const control = this.fb.control(
        field.value,
        this.bindValidations(field.validations || [])
      );
      group.addControl(field.name, control);
    });
    return group;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}
