import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { clientSearchResultDTO, searchCorporateRequestDTO } from "app/api/core/models";
import { domainOfApplicationDTO } from "app/api/core/models/domainOfApplicationDTO";
import { sponsor1DTO } from "app/api/core/models/sponsor1DTO";
import { sponsor2DTO } from "app/api/core/models/sponsor2DTO";
import { CoreService } from "app/api/core/services";
import { GeocoderResponse } from "app/api/google/models/GeocoderResponse";
import { GeocodingService } from "app/api/google/services/geocoder.service";
import { CompanyDto } from "app/api/octopus-web/models";
import { CompanyService } from "app/api/octopus-web/services";
import { hasValue } from "app/layouts/naxos-framework/services";
import { CompanyFormComponent } from "app/layouts/shared/forms/specific/company-form/company-form.component";
import { CompanyFormService } from "app/layouts/shared/services/company-form.service";
import { GoogleMapMarker } from "app/models/googleMapData";
import { AuthService } from "app/security/services";

@Component({
  selector: 'app-allprojects-view',
  templateUrl: './allprojects-view.component.html',
  styleUrls: ['./allprojects-view.component.scss']
})
export class AllprojectsViewComponent implements OnInit {
  projectForm: NgForm;
  allProjects: clientSearchResultDTO[] = [];
  allDomainOfApplication: domainOfApplicationDTO[] = [];
  allSponsor1: sponsor1DTO[] = [];
  allSponsor2: sponsor2DTO[] = [];
  title = "Localisation de vos projets";
  icon = "travel_explore";
  lat: number = 0;
  lng: number = 0;
  scroll: boolean = false;
  zoom: number = 8;
  markers: GoogleMapMarker[] = [];
  currentMarker: clientSearchResultDTO;
  model: searchCorporateRequestDTO = null;
  param: searchCorporateRequestDTO;
  totalOfProjects: number = 0;
  totalOfProjectsDisplay: number = 0;
  defaultPagesize: number = 50;

  isSponsor1Checked: string = "false";
  isSponsor2Checked: string = "false";
  isDomainOfApplicationChecked: string = "false";

  company: CompanyDto;
  
  constructor(
    private coreService: CoreService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.model = <searchCorporateRequestDTO>{ pageNumber: 1, pageSize: this.defaultPagesize, hideClient: true, hideNotClient: true, isActive: true, domainOfApplication: null }

    this.coreService.isCoreServiceEnable.subscribe(bool => { if (bool)  this.filterData(); });
  }

  setIsDomainOfApplicationChecked(e) {
    if (e.checked) {
      this.isDomainOfApplicationChecked = 'true'
    }
    else {
      this.isDomainOfApplicationChecked = 'false'
      this.model.domainOfApplication = null;
    }
  }

  setIsSponsor1Checked(e) {
    if (e.checked) {
      this.isSponsor1Checked = 'true'
    } else {
      this.isSponsor1Checked = 'false'
      this.model.sponsor1 = null;
    }
  }

  setIsSponsor2Checked(e) {
    if (e.checked) {
      this.isSponsor2Checked = 'true'
    } else {
      this.isSponsor2Checked = 'false'
      this.model.sponsor2 = null;
    }
  }

  filterData() {
    this.param = <searchCorporateRequestDTO>
      {
        pageNumber: 1,
        pageSize: this.model.pageSize == 0 ? this.defaultPagesize : this.model.pageSize,
        name: this.model.name != "" ? this.model.name : null,
        city: this.model.city != "" ? this.model.city : null,
        sponsor1: this.model.sponsor1 != "" ? this.model.sponsor1 : null,
        sponsor2: this.model.sponsor2 != "" ? this.model.sponsor2 : null,
        domainOfApplication: this.model.domainOfApplication != null ? this.model.domainOfApplication : null,
        isActive: true,
      }
    this.unZoom();
   
    if (this.coreService.isServiceEnable()) {
      this.coreService.Post(this.param).subscribe(data => {
        this.company = this.authService.currentCompany;
        if (hasValue(this.company)) {
          this.lat = this.company.lat??0;
          this.lng = this.company.lng??0;
        }

        this.allProjects = data.results;
        this.markers = [];
        this.totalOfProjects = data.totalCount;

        this.allProjects.forEach(i => {
          if (i.lat + i.lng > 0) {
            this.markers.push({
              lat: i.lat,
              lng: i.lng,
              label: i.name,
              draggable: true,
              visible: false,
              opacity: 0.7,
              data: {
                title: i.name,
                street: i.streetAndNumber,
                number: '',
                zip: i.postaCode,
                city: i.locality,
                country: '',
                phone: '',
                mobile: '',
                fax: '',
                email: ''
              }
            });
          }
        }
        );

        this.markers.push({
          lat: this.lat,
          lng: this.lng,
          label: this.company?.institutionName,
          draggable: true,
          visible: false,
          opacity: 0.7,
          data: {
            title: this.company?.institutionName,
            street: this.company?.institutionStreet,
            number: this.company?.institutionStreetNumber,
            zip: this.company?.institutionZipCode,
            city: this.company?.institutionCity,
            country: this.company?.institutionCountry,
            phone: '',
            mobile: '',
            fax: '',
            email: ''
          }
        });


        this.totalOfProjectsDisplay = this.allProjects.length;
      });

      this.coreService.GetAllDomainOfApplication().subscribe(data => {
        this.allDomainOfApplication = data.sort((a, b) => a.name.trim().toUpperCase() < b.name.trim().toUpperCase() ? -1 : 1);
      });

      this.coreService.GetAllSponsor1().subscribe(data => {
        this.allSponsor1 = data.sort((a, b) => a.value.trim().toUpperCase() < b.value.trim().toUpperCase() ? -1 : 1);
      });

      this.coreService.GetAllSponsor2().subscribe(data => {
        this.allSponsor2 = data.sort((a, b) => a.value.trim().toUpperCase() < b.value.trim().toUpperCase() ? -1 : 1);
      });
    }
  }

  filterCleanup
    () {
    this.model = <searchCorporateRequestDTO>{ pageNumber: 1, pageSize: this.defaultPagesize, hideClient: true, hideNotClient: true, isActive: true, domainOfApplication: null }
  }

  getLabel(marker: clientSearchResultDTO) {
    return marker.name + ((marker.district + marker.city != "") ? " / " + marker.district + " " + marker.city : "");
  }

  unZoom() {
    this.zoom = 8;
  }

  zoomOnMarker(marker, index) {
    if (this.markers[index] != null) {
      this.currentMarker = marker;
      this.zoom = 8;
      setTimeout(() => {
        this.lat = this.markers[index].lat;
        this.lng = this.markers[index].lng;
        this.zoom = 10; //Value : 0 to 22
      }, 1000);
      setTimeout(() => {
        this.zoom = 12; //Value : 0 to 22
      }, 2000);
    }
  }
}