import { NaxosFileViewComponent } from './view/naxos-file-view/naxos-file-view.component';
import { ConfirmationDialogComponent } from '../shared/forms/specific/confirmation-dialog/confirmation-dialog.component';
import { UsersComponent } from './components/users/users.component';
import { AllUsersViewComponent } from './view/allusers-view/allusers-view.component';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { SharedLayoutModule } from "../shared/shared-layout.module";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ManagementRoutes } from "./management-routing.module";
import { MatDialogModule } from "@angular/material/dialog";
import { NaxosCompanyViewComponent } from "./view/naxos-company-view/naxos-company-view.component";
import { CompanyDashboardComponent } from "./view/company-dashboard/company-dashboard.component";
import { DownloadViewComponent } from "./view/download-view/download-view.component";
import { HostingsViewComponent } from "./view/hostings-view/hostings-view.component";
import { AlllicencesViewComponent } from "./view/alllicences-view/alllicences-view.component";
import { CompanylicencesViewComponent } from "./view/companylicences-view/companylicences-view.component";
import { RequestsViewComponent } from './view/requests-view/requests-view.component';
import { ProfileViewComponent } from './view/profile-view/profile-view.component';
import { TermsOfUseViewComponent } from './view/terms-of-use-view/terms-of-use-view.component';
import { UnsubscribeWarningComponent } from './view/unsubscribe-warning/unsubscribe-warning.component';
import { NaxosDownloadViewComponent } from './view/naxos-download-view/naxos-download-view.component';
import { UpdateDownloadViewComponent } from './view/update-download-view/update-download-view.component';
import { FinalFormBeforeLicenceComponent } from './view/final-form-before-licence/final-form-before-licence.component';
import { AdminLayoutComponent } from './components/admin-layout/admin-layout.component';
import { ComponentsModule } from 'app/components/components.module';
import { FixedpluginComponent } from './components/fixedplugin/fixedplugin.component';
import { AdminAddCompanyComponent } from './view/admin-add-company/admin-add-company.component';
import { CompanyComponent } from './view/company/company.component';
import { UserDashboardComponent } from './view/user-dashboard/user-dashboard.component';
import { AllprojectsViewComponent } from './view/allprojects-view/allprojects-view.component';
import { AgmCoreModule } from '@agm/core';
import { AllprojectsDataComponent } from './view/allprojects-view/allprojects-data/allprojects-data.component';
import { AllUsersCompanyViewComponent } from './view/allusers-company-view/allusers-company-view.component';

@NgModule({
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAaSBjVjolei-VxiJd8diAEv2npxBjnIvw'
     }),
    CommonModule,
    RouterModule,
    SharedLayoutModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(ManagementRoutes),
    MatDialogModule,
    ComponentsModule,
  ],
  declarations: [
    AllUsersViewComponent,
    NaxosCompanyViewComponent,
    AllUsersCompanyViewComponent,
    CompanyDashboardComponent,
    DownloadViewComponent,
    HostingsViewComponent,
    AlllicencesViewComponent,
    AllprojectsViewComponent,
    AllprojectsDataComponent,
    CompanylicencesViewComponent,
    RequestsViewComponent,
    AllUsersViewComponent,
    UsersComponent,
    ProfileViewComponent,
    ConfirmationDialogComponent,
    TermsOfUseViewComponent,
    UnsubscribeWarningComponent,
    NaxosDownloadViewComponent,
    UpdateDownloadViewComponent,
    FinalFormBeforeLicenceComponent,
    AdminLayoutComponent,
    FixedpluginComponent,
    AdminAddCompanyComponent,
    CompanyComponent,
    UserDashboardComponent,
    NaxosFileViewComponent,
  ],
  providers: [

  ],
  exports: [NaxosCompanyViewComponent],
})
export class ManagementModule {}
