import { AuthService } from "../../security/services/auth.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { EnumUserRole } from "app/api/octopus-web/models";
import { take } from "rxjs";
import { RoutePaths } from "app/models/menus-routes/route-paths";

@Component({
  selector: "app-login-redirect",
  templateUrl: "./login-redirect.component.html",
  styleUrls: ["./login-redirect.component.scss"],
})
export class LoginRedirectComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.authService.hasCompanyLicense$.pipe(take(1)).subscribe({
      next: () => {
        if (this.authService.isCompanyAdmin)
        {
          if (this.authService.hasCompanyLicense)
            this.router.navigateByUrl(RoutePaths.allProjects.path);
          else
            this.router.navigateByUrl(RoutePaths.company.path);
        }
        else if (this.authService.isNaxosAdmin)
          this.router.navigateByUrl(RoutePaths.allCompanies.path);
        else
        {
          if (this.authService.hasCompanyLicense)
            this.router.navigateByUrl(RoutePaths.allProjects.path);
          else
            this.router.navigateByUrl(RoutePaths.company.path);
        }
        
      }
    });
  }
}
