import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyDto } from 'app/api/octopus-web/models';
import { CompanyService } from 'app/api/octopus-web/services';
import { RoutePaths } from 'app/models/menus-routes/route-paths';
import { AuthService } from 'app/security/services';

@Component({
  selector: 'app-final-form-before-licence',
  templateUrl: './final-form-before-licence.component.html',
  styleUrls: ['./final-form-before-licence.component.scss']
})
export class FinalFormBeforeLicenceComponent implements OnInit {

  form: FormGroup;
  public company = {} as CompanyDto;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  async ngOnInit() {
    this.company = this.authService.currentCompany;
    this.authService.currentCompany$.subscribe({
      next: (currentCompany) => {
        this.company = currentCompany;
      }
    })
  }

  continue(){
    this.router.navigateByUrl(RoutePaths.termOfUse.path);
  }

}
