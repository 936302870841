import { Component, Input, OnInit } from '@angular/core';
import { GoogleMapData } from 'app/models/googleMapData';

@Component({
  selector: 'app-allprojects-data',
  templateUrl: './allprojects-data.component.html',
  styleUrls: ['./allprojects-data.component.css']
})
export class AllprojectsDataComponent implements OnInit {
  @Input() data: GoogleMapData;

  ready = true;

  phoneLabel : string = "Tel";
  mobileLabel : string = "Gsm";
  faxLabel : string = "Fax";
  emailLabel : string = "Email";

  constructor(
  ) { }

  ngOnInit(): void {
    // this.itemsService
    //   .GetFlattenItemByCode({
    //     applicationCode: "octopus-web",
    //     itemCode: "page-allprojects",
    //     locale: "fr"
    //   })
    //   .subscribe(allprojects => {
    //     this.address = (<any[]>allprojects.Items).filter(
    //       (obj) => obj.Code === "page-allprojects-address"
    //     )[0];
    //     this.phone = (<any[]>allprojects.Items).filter(
    //       (obj) => obj.Code === "page-allprojects-phone"
    //     )[0];
    //     this.mobile = (<any[]>allprojects.Items).filter(
    //       (obj) => obj.Code === "page-allprojects-mobile"
    //     )[0];
    //     this.fax = (<any[]>allprojects.Items).filter(
    //       (obj) => obj.Code === "page-allprojects-fax"
    //     )[0];
    //     this.email = (<any[]>allprojects.Items).filter(
    //       (obj) => obj.Code === "page-allprojects-email"
    //     )[0];
    //     this.ready = true;
    //   }, (error) => {
    //     this.notifications.Error('Could not load allprojects page', error);
    //   });
  }

}
