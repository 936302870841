import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { FormControl } from "@angular/forms";
import { startWith, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { ENTER, COMMA } from "@angular/cdk/keycodes";

@Component({
  selector: "app-chip-list",
  templateUrl: "./chip-list.component.html",
  styleUrls: ["./chip-list.component.scss"]
})
export class ChipListComponent implements OnInit {
  //#region tag variables
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  isFormReady = false;
  //itemS
  itemCtrl = new FormControl();
  filtereditems: Observable<string[]>;

  @ViewChild("itemInput") itemInput: ElementRef<HTMLInputElement>;
  @ViewChild("autoitems") matAutocompleteItems: MatAutocomplete;

  @Input() allitems: string[];
  @Input() items: string[];
  @Input() title: string;

  //#endregion itemS tag variables
  constructor() {}

  ngOnInit(): void {
    console.log("all Items = ", this.allitems);
    console.log(" Items = ", this.items);
    this.initializeFilterItem();
  }

  Unstringify(data: string): string {
    try {
      const obj = JSON.parse(data);
      return obj.formModel;
    } catch (ex) {
      console.error(ex);
    }
  }

  initializeFilterItem(): any {
    this.filtereditems = this.itemCtrl.valueChanges.pipe(
      startWith(null),
      map(
        (item: string | null) =>
          item ? this._filter(item, this.allitems) : this.allitems.slice(),
        (this.isFormReady = true)
      )
    );
  }

  //#region  FUNCTIONS && SKILLS
  additem(event: MatChipInputEvent): void {
    this.add(event, this.items, this.itemCtrl, this.matAutocompleteItems);
  }
  removeitem(item: string, destination: string[]): void {
    this.remove(item, this.items);
  }

  selecteditem(event: MatAutocompleteSelectedEvent): void {
    this.selected(event, this.items, this.itemInput, this.itemCtrl);
  }

  //#endregion FUNCTIONS && SKILLS

  add(
    event: MatChipInputEvent,
    destination: string[],
    destinationCtrl: FormControl,
    auto: MatAutocomplete
  ): void {
    if (!auto.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our skill
      if ((value || "").trim()) {
        destination.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = "";
      }

      destinationCtrl.setValue(null);
    }
  }

  remove(item: string, destination: string[]): void {
    const index = destination.indexOf(item);

    if (index >= 0) {
      destination.splice(index, 1);
    }
  }

  selected(
    event: MatAutocompleteSelectedEvent,
    destination: string[],
    destinationInput: any,
    destinationCtrl: FormControl
  ): void {
    destination.push(event.option.viewValue);
    destinationInput.nativeElement.value = "";
    destinationCtrl.setValue(null);
  }

  private _filter(value: string, destination: any): string[] {
    const filterValue = value.toLowerCase();

    return destination.filter(
      item => item.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
