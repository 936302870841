import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-company-already-exists',
  templateUrl: './company-already-exists.component.html',
  styleUrls: ['./company-already-exists.component.scss']
})
export class CompanyAlreadyExistsComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  closeAll(){
    this.dialog.closeAll();
  }
}
