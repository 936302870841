import { HostingDto } from '../../../../api/licence/models/hosting-dto';
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { popup } from "environments/environment";
import { HostingsService } from "app/api/licence/services";
import { UserService } from "app/api/octopus-web/services";
import { ConfirmationDialogComponent } from "app/layouts/shared/forms/specific/confirmation-dialog/confirmation-dialog.component";
import { AuthService } from 'app/security/services';
import { CardFormComponent } from 'app/layouts/shared/forms/generic/card-form/card-form.component';

@Component({
  selector: "app-hostings-view",
  templateUrl: "./hostings-view.component.html",
  styleUrls: ["./hostings-view.component.scss"]
})
export class HostingsViewComponent implements OnInit {
  title = "Hostings";
  icon = "perm_media";

  items: HostingDto[];
  isFormReady = false;
  canUpdate = false;

  constructor(
    private hostingService: HostingsService,
    private userInfoService: UserService,
    public dialog: MatDialog,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.canUpdate = this.authService.isNaxosAdmin;
    this.authService.hasCompanyLicense$.subscribe({
      next: async (hasCompanyLicense) => {
        this.canUpdate = this.authService.isNaxosAdmin;
        await this.loadData();
      }
    });
    this.loadData();
  }

  loadData(): any {
    this.hostingService
      .GetAll()
      .toPromise()
      .then(data => {
        this.items = data;
        this.isFormReady = true;
      });
  }

  modifyDialog(item: HostingDto) {
    const dialogRef = this.dialog.open(CardFormComponent, {
      width: popup.width,
      height: popup.height,
      disableClose: popup.disableClose,
      data: {
        current: item,
        type: 'hosting'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  confirmDelete(item: HostingDto) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { name: `Delete hosting ${item.name}?` }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Confirmation dialog closed with result ${result}`);
      if (result) {
        this.hostingService
          .Delete({ id: item.id })
          .toPromise()
          .then(data => {
            this.loadData();
          });
      }
    });
  }
}
