import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { HostingDto } from "app/api/licence/models";
import { HostingsService } from "app/api/licence/services";
import { EnumUserRole, UserDto } from "app/api/octopus-web/models";
import { UserService } from "app/api/octopus-web/services";
import { FieldConfig } from "app/layouts/naxos-framework/form/field.interface";
import { SelectItems } from "app/layouts/naxos-framework/model/selectItems";
import { isGuid } from "app/layouts/naxos-framework/services";
import { AuthService } from "app/security/services";
import { NotificationsService } from "app/shared/notifications/notifications.service";
import { FormService } from "../models/form-service.interface";

@Injectable({
  providedIn: "root",
})
export class HostingFormService implements FormService {
  isDynamic: boolean = true;
  formData: HostingDto;
  title: string = "Utilisateur";
  icon: string = "nature_people";

  constructor(
    private hostingsService: HostingsService,
  ) {}

  onSave(value: any): Promise<any> {
    const result = <HostingDto>value;

    this.formData.cmConnectionString = result.cmConnectionString;
    this.formData.cmPort = result.cmPort;
    this.formData.cmServer = result.cmServer;
    this.formData.cmWebServicePort = result.cmWebServicePort;
    this.formData.coreConnectionString = result.coreConnectionString;
    this.formData.corePort = result.corePort;
    this.formData.coreServer = result.coreServer;
    this.formData.default = result.default;
    this.formData.hostingEnvironment = result.hostingEnvironment;
    this.formData.name = result.name;

    if (isGuid(this.formData.id)) {
      return this.hostingsService
        .Put({
          id: this.formData.id,
          body: this.formData
        })
        .toPromise()
        .then(item => {
          this.formData = item;
        });
    } else {
      return this.hostingsService
        .Post({ body: this.formData})
        .toPromise()
        .then(item => {
          this.formData.id = item.id;
        });
    }
  }

  onCancel(): Promise<void> {
    return new Promise<void>(resolve => resolve());
  }

  onDelete(): Promise<void> {
    if (isGuid(this.formData.id)) {
      return this.hostingsService
        .Delete({ id: this.formData.id })
        .toPromise()
        .then(item => {
        });
    }
    return new Promise<void>(resolve => resolve());
  }

  async getFields(): Promise<FieldConfig[]> {
    const fields = [
      {
        type: "input",
        inputType: "text",
        name: "name",
        label: "Name",
        value: this.formData?.name,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Hosting name is required"
          }
        ]
      },
      {
        type: "input",
        inputType: "text",
        name: "corePort",
        label: "Core port",
        value: this.formData?.corePort
      },
      {
        type: "input",
        inputType: "text",
        name: "coreServer",
        label: "Core server",
        value: this.formData?.coreServer
      },
      // {
      //   type: "input",
      //   inputType: "text",
      //   name: "coreConnectionString",
      //   label: "Core connection string",
      //   value: this.formData?.coreConnectionString
      // },
      {
        type: "input",
        inputType: "text",
        name: "cmPort",
        label: "CM port",
        value: this.formData?.cmPort
      },
      {
        type: "input",
        inputType: "text",
        name: "cmWebServicePort",
        label: "CM Webservice port",
        value: this.formData?.cmWebServicePort
      },
      {
        type: "input",
        inputType: "text",
        name: "cmServer",
        label: "CM server",
        value: this.formData?.cmServer
      },
      // {
      //   type: "input",
      //   inputType: "text",
      //   name: "cmConnectionString",
      //   label: "CM connection string",
      //   value: this.formData?.cmConnectionString
      // },
      // {
      //   type: "input",
      //   inputType: "text",
      //   name: "msiUrl",
      //   label: "MSI URL",
      //   value: this.formData?.msiUrl
      // }
    ];

    return new Promise<FieldConfig[]>((resolve) => {
      resolve(fields);
    });
  }
}
