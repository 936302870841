import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { CompanyDto, UserDto } from "app/api/octopus-web/models";
import { SimulationService } from "app/api/octopus-web/services";
import { CardFormComponent } from "app/layouts/shared/forms/generic/card-form/card-form.component";
import { RoutePaths } from "app/models/menus-routes/route-paths";
import { AuthService } from "app/security/services";
import { CurrentSimulationService } from "app/services/current-simulation.service";
import { popup } from "environments/environment";

@Component({
  selector: "app-admin-add-company",
  templateUrl: "./admin-add-company.component.html",
  styleUrls: ["./admin-add-company.component.scss"],
})
export class AdminAddCompanyComponent implements OnInit {
  hasUser = false;
  user: UserDto;
  company: CompanyDto;

  constructor(
    private simulationService: SimulationService,
    private currentSimulationService: CurrentSimulationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.company = {} as CompanyDto;
  }

  onSaveUser(user: UserDto) {
    this.user = user;
    this.hasUser = true;
    this.company.users = [this.user];
  }

  onSaveCompany(company: CompanyDto) {
    this.company = company;
    this.company.users = [this.user];
    this.simulation(this.company.id);
  }

  simulation(companyId: string) {
    this.simulationService
      .Put({ companyId: companyId })
      .toPromise()
      .then((data) => {
        if (data) {
          this.currentSimulationService.changeSimulation(data);
          this.router.navigateByUrl(RoutePaths.companyDashboard.path);
        }
      });
  }
}
