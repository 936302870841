import { Component, OnInit } from '@angular/core';
import { FieldConfig } from '../field.interface';
import { FormGroup } from '@angular/forms';

@Component({
  selector: "app-input",
  templateUrl: './textarea.component.html',
  styles: [`
  .form-row, .card-form, .mat-form-field{
  width: 100%;
}
`]
})
export class TextareaComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor() {}
  ngOnInit() {}
}
