/* tslint:disable */
/* eslint-disable */
export enum LicenceValidationResult {
  LicenceOK = 'LicenceOK',
  FormatKeyNotOK = 'FormatKeyNotOK',
  MaxActivationReached = 'MaxActivationReached',
  AuthenticationDeactivated = 'AuthenticationDeactivated',
  LicenceKeyDeactivated = 'LicenceKeyDeactivated',
  LicenceKeyExpired = 'LicenceKeyExpired',
  AuthentificationToExceed = 'AuthentificationToExceed',
  LicenceKeyDoesNotRespondToYourProduct = 'LicenceKeyDoesNotRespondToYourProduct'
}
