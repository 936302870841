/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LicenceDto } from '../models/licence-dto';
import { LicenceValidationRequestDto } from '../models/licence-validation-request-dto';
import { LicenceValidationResultDto } from '../models/licence-validation-result-dto';

@Injectable({
  providedIn: 'root',
})
export class LicencesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation licencesGetAll
   */
  static readonly LicencesGetAllPath = '/api/Licences';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAll$Response(params?: {
  }): Observable<StrictHttpResponse<Array<LicenceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LicencesService.LicencesGetAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LicenceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAll(params?: {
  }): Observable<Array<LicenceDto>> {

    return this.GetAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LicenceDto>>) => r.body as Array<LicenceDto>)
    );
  }

  /**
   * Path part for operation licencesGetById
   */
  static readonly LicencesGetByIdPath = '/api/Licences/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<LicenceDto>> {

    const rb = new RequestBuilder(this.rootUrl, LicencesService.LicencesGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicenceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetById(params: {
    id: string;
  }): Observable<LicenceDto> {

    return this.GetById$Response(params).pipe(
      map((r: StrictHttpResponse<LicenceDto>) => r.body as LicenceDto)
    );
  }

  /**
   * Path part for operation licencesPut
   */
  static readonly LicencesPutPath = '/api/Licences/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Put()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Put$Response(params: {
    id: string;
    body: LicenceDto
  }): Observable<StrictHttpResponse<LicenceDto>> {

    const rb = new RequestBuilder(this.rootUrl, LicencesService.LicencesPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicenceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Put$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  Put(params: {
    id: string;
    body: LicenceDto
  }): Observable<LicenceDto> {

    return this.Put$Response(params).pipe(
      map((r: StrictHttpResponse<LicenceDto>) => r.body as LicenceDto)
    );
  }

  /**
   * Path part for operation licencesDelete
   */
  static readonly LicencesDeletePath = '/api/Licences/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  Delete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<LicenceDto>> {

    const rb = new RequestBuilder(this.rootUrl, LicencesService.LicencesDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicenceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Delete(params: {
    id: string;
  }): Observable<LicenceDto> {

    return this.Delete$Response(params).pipe(
      map((r: StrictHttpResponse<LicenceDto>) => r.body as LicenceDto)
    );
  }

  /**
   * Path part for operation licencesGetByKey
   */
  static readonly LicencesGetByKeyPath = '/api/Licences/GetByKey/{key}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetByKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetByKey$Response(params: {
    key: string;
  }): Observable<StrictHttpResponse<LicenceDto>> {

    const rb = new RequestBuilder(this.rootUrl, LicencesService.LicencesGetByKeyPath, 'get');
    if (params) {
      rb.path('key', params.key, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicenceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetByKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetByKey(params: {
    key: string;
  }): Observable<LicenceDto> {

    return this.GetByKey$Response(params).pipe(
      map((r: StrictHttpResponse<LicenceDto>) => r.body as LicenceDto)
    );
  }

  /**
   * Path part for operation licencesGetByKeyCrypted
   */
  static readonly LicencesGetByKeyCryptedPath = '/api/Licences/GetByKeyCryped';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetByKeyCrypted()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  GetByKeyCrypted$Response(params: {
    body: string
  }): Observable<StrictHttpResponse<LicenceDto>> {

    const rb = new RequestBuilder(this.rootUrl, LicencesService.LicencesGetByKeyCryptedPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicenceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetByKeyCrypted$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  GetByKeyCrypted(params: {
    body: string
  }): Observable<LicenceDto> {

    return this.GetByKeyCrypted$Response(params).pipe(
      map((r: StrictHttpResponse<LicenceDto>) => r.body as LicenceDto)
    );
  }

  /**
   * Path part for operation licencesExistsForCompany
   */
  static readonly LicencesExistsForCompanyPath = '/api/Licences/ExistsForCompany/{companyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ExistsForCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  ExistsForCompany$Response(params: {
    companyId: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, LicencesService.LicencesExistsForCompanyPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ExistsForCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ExistsForCompany(params: {
    companyId: string;
  }): Observable<boolean> {

    return this.ExistsForCompany$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation licencesGetAllForCompany
   */
  static readonly licencesGetAllForCompanyPath = '/api/Licences/GetAllForCompany/{companyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAllForCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllForCompany$Response(params: {
    companyId: string;
  }): Observable<StrictHttpResponse<Array<LicenceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LicencesService.licencesGetAllForCompanyPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LicenceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetAllForCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllForCompany(params: {
    companyId: string;
  }): Observable<Array<LicenceDto>> {

    return this.GetAllForCompany$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LicenceDto>>) => r.body as Array<LicenceDto>)
    );
  }

    /**
   * Path part for operation licencesGetLicenseOfACompanyPath
   */
    static readonly licencesGetLicenseOfACompanyPath = '/api/Licences/GetLicenceOfACompany/{companyId}';

    
  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetLicenseOfACompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetLicenseOfACompany$Response(params: {
    companyId: string;
  }): Observable<StrictHttpResponse<LicenceDto>> {

    const rb = new RequestBuilder(this.rootUrl, LicencesService.licencesGetLicenseOfACompanyPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicenceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `GetLicenseOfACompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetLicenseOfACompany(params: {
    companyId: string;
  }): Observable<LicenceDto> {

    return this.GetLicenseOfACompany$Response(params).pipe(
      map((r: StrictHttpResponse<LicenceDto>) => r.body as LicenceDto)
    );
  }

  /**
   * Path part for operation licencesUpgrade
   */
  static readonly LicencesUpgradePath = '/api/Licences/Upgrade/{key}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Upgrade()` instead.
   *
   * This method doesn't expect any request body.
   */
  Upgrade$Response(params: {
    key: string;
  }): Observable<StrictHttpResponse<LicenceDto>> {

    const rb = new RequestBuilder(this.rootUrl, LicencesService.LicencesUpgradePath, 'post');
    if (params) {
      rb.path('key', params.key, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicenceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `Upgrade$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Upgrade(params: {
    key: string;
  }): Observable<LicenceDto> {

    return this.Upgrade$Response(params).pipe(
      map((r: StrictHttpResponse<LicenceDto>) => r.body as LicenceDto)
    );
  }

  /**
   * Path part for operation licencesExtendExpirationDate
   */
  static readonly LicencesExtendExpirationDatePath = '/api/Licences/ExtendExpirationDate/{key}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ExtendExpirationDate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ExtendExpirationDate$Response(params: {
    key: string;
    body: string
  }): Observable<StrictHttpResponse<LicenceDto>> {

    const rb = new RequestBuilder(this.rootUrl, LicencesService.LicencesExtendExpirationDatePath, 'post');
    if (params) {
      rb.path('key', params.key, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicenceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ExtendExpirationDate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ExtendExpirationDate(params: {
    key: string;
    body: string
  }): Observable<LicenceDto> {

    return this.ExtendExpirationDate$Response(params).pipe(
      map((r: StrictHttpResponse<LicenceDto>) => r.body as LicenceDto)
    );
  }

  /**
   * Path part for operation licencesPostTrial
   */
  static readonly LicencesPostTrialPath = '/api/Licences/PostTrial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `PostTrial()` instead.
   *
   * This method doesn't expect any request body.
   */
  PostTrial$Response(params?: {
    companyId?: string;
  }): Observable<StrictHttpResponse<LicenceDto>> {

    const rb = new RequestBuilder(this.rootUrl, LicencesService.LicencesPostTrialPath, 'post');
    if (params) {
      rb.query('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicenceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `PostTrial$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  PostTrial(params?: {
    companyId?: string;
  }): Observable<LicenceDto> {

    return this.PostTrial$Response(params).pipe(
      map((r: StrictHttpResponse<LicenceDto>) => r.body as LicenceDto)
    );
  }

  /**
   * Path part for operation licencesPostLicenceValidation
   */
  static readonly LicencesPostLicenceValidationPath = '/api/Licences/PostLicenceValidation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `PostLicenceValidation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  PostLicenceValidation$Response(params: {
    body: LicenceValidationRequestDto
  }): Observable<StrictHttpResponse<LicenceValidationResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, LicencesService.LicencesPostLicenceValidationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicenceValidationResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `PostLicenceValidation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  PostLicenceValidation(params: {
    body: LicenceValidationRequestDto
  }): Observable<LicenceValidationResultDto> {

    return this.PostLicenceValidation$Response(params).pipe(
      map((r: StrictHttpResponse<LicenceValidationResultDto>) => r.body as LicenceValidationResultDto)
    );
  }

}
