import { CompanyService } from 'app/api/octopus-web/services';
import { CompanyDto } from 'app/api/octopus-web/models';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { RoutePaths } from 'app/models/menus-routes/route-paths';

@Component({
  selector: 'app-unsubscribe-warning',
  templateUrl: './unsubscribe-warning.component.html',
  styleUrls: ['./unsubscribe-warning.component.scss']
})
export class UnsubscribeWarningComponent implements OnInit {

  companyId: string;
  company: CompanyDto;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    this.companyId = this.route.snapshot.paramMap.get('id');
  }

  confirmUnsubscription(){
    this.companyService.WantsToUnsubscribe({ id: this.companyId }).subscribe();
    this.router.navigateByUrl(RoutePaths.profile.path);
  }

  cancelUnsubscription(){
    this.router.navigateByUrl(RoutePaths.company.path);
  }

}
