import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FileDto } from "app/api/octopus-web/models";
import { FileService, ReadFileService } from "app/api/octopus-web/services";
import { apiOctopusWebConfig, popup } from "environments/environment";
import { FileSaverService } from "ngx-filesaver";
import { CardFormComponent } from "../../forms/generic/card-form/card-form.component";
import { WritableStream } from 'web-streams-polyfill/ponyfill';
import streamSaver from 'streamsaver';

@Component({
  selector: "app-files",
  templateUrl: "./files.component.html",
  styleUrls: ["./files.component.scss"],
})
export class FilesComponent implements OnInit {
  files: FileDto[];
  loaded = false;
  title = "Fichiers";
  icon = "article";
  @Input() canEdit = false;

  constructor(
    public dialog: MatDialog,
    private fileService: FileService,
    private readFileService: ReadFileService,
    private fileSaverService: FileSaverService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.readFileService.GetAll().subscribe({
      next: (files) => {
        this.files = files;
        this.loaded = true;
      },
    });
  }

  download(file: FileDto) {
    // this.readFileService.DownloadById({ id: file.id }).subscribe({
    //   next: (data) => {
    //     //var fileToDownload = this.base64toBlob(downloadedFile.content, downloadedFile.type)
    //     this.fileSaverService.save(new Blob(data, file.type), file.name);
    //   },
    // });
    fetch(apiOctopusWebConfig.webApi + "/ReadFile/DownloadById/" + file.id)
      .then((response) => {
        // let contentDisposition = response.headers.get("Content-Disposition");
        // let fileName = contentDisposition.substring(
        //   contentDisposition.lastIndexOf("=") + 1
        // );

        // These code section is adapted from an example of the StreamSaver.js
        // https://jimmywarting.github.io/StreamSaver.js/examples/fetch.html

        // If the WritableStream is not available (Firefox, Safari), take it from the ponyfill
        if (!window.WritableStream) {
          streamSaver.WritableStream = WritableStream;
          window.WritableStream = WritableStream;
        }

        const fileStream = streamSaver.createWriteStream(file.name);
        const readableStream = response.body;

        // More optimized
        if (readableStream.pipeTo) {
          return readableStream.pipeTo(fileStream);
        }

        let writer = fileStream.getWriter();

        const reader = response.body.getReader();
        const pump = () =>
          reader
            .read()
            .then((res) =>
              res.done ? writer.close() : writer.write(res.value).then(pump)
            );

        pump();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  modifyDialog(item: FileDto) {
    if (item === null) {
      item = {} as FileDto;
      this.dialog.open(CardFormComponent, {
        width: popup.width,
        height: popup.height,
        data: {
          current: item,
          type: "file",
          EnableDelete: false,
        },
      });
      this.dialog.afterAllClosed.subscribe((result) => {
        this.loadData();
      });
    } else {
      this.dialog.open(CardFormComponent, {
        width: popup.width,
        height: popup.height,
        data: {
          current: item,
          type: "file",
          EnableDelete: false,
        },
      });
      this.dialog.afterAllClosed.subscribe((result) => {
        this.loadData();
      });
    }
  }

  delete(file: FileDto) {
    this.fileService.Delete({ id: file.id }).subscribe({
      next: () => {
        this.loadData();
      },
    });
  }
}
