import { SignupChoiceComponent } from './view/signup-choice/signup-choice.component';
import { PrivacyPolicyComponent } from './view/privacy-policy/privacy-policy.component';
import { ContactComponent } from './view/contact/contact.component';
import { AuthGuard } from 'app/security';
import { Routes } from "@angular/router";
import { ContentDashboardComponent } from "./view/content-dashboard/content-dashboard.component";
import { TestimoniesComponent } from "./view/testimonies/testimonies.component";
import { DescriptionComponent } from './view/description/description.component';
import { AboutUsComponent } from './view/about-us/about-us.component';
import { SupportComponent } from './view/support/support.component';
import { HomeComponent } from './view/home/home.component';
import { LoginFailedComponent } from './view/login-failed/login-failed.component';
import { SubscriptionComponent } from './view/subscription/subscription.component';
import { SignupWarningComponent } from './view/signup-warning/signup-warning.component';
import { PasswordResetComponent } from '../../components/password-reset/password-reset.component';
import { LoginRedirectComponent } from '../../components/login-redirect/login-redirect.component';
import { MsalGuard } from '@azure/msal-angular';
import { FilesComponent } from '../shared/components/files/files.component';

export const PublicRoutes: Routes = [
  {
    path: "home",
    component: HomeComponent
  },
  {
    path: "signup-choice",
    component: SignupChoiceComponent
  },
  {
    path: "login-failed",
    component: LoginFailedComponent
  },
  {
    path: "password-reset",
    component: PasswordResetComponent,
  },
  {
    path: "aboutus",
    component: AboutUsComponent
  },
  {
    path: "contact",
    component: ContactComponent,
  },
  {
    path: "description",
    component: DescriptionComponent
  },
  {
    path: "subscription",
    component: SubscriptionComponent
  },
  {
    path: "support",
    component: SupportComponent
  },
  {
    path: "warning",
    component: SignupWarningComponent
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent
  },
  {
    path: "testimonies",
    component: TestimoniesComponent
  },
  {
    path: "files",
    component: FilesComponent
  },
  { path: "**", redirectTo: "home" }
];
