import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { EnumUserRole, UserDto } from "app/api/octopus-web/models";
import { UserService } from "app/api/octopus-web/services";
import { FieldConfig } from "app/layouts/naxos-framework/form/field.interface";
import { SelectItems } from "app/layouts/naxos-framework/model/selectItems";
import { isGuid } from "app/layouts/naxos-framework/services";
import { AuthService } from "app/security/services";
import { NotificationsService } from "app/shared/notifications/notifications.service";
import { FormService } from "../models/form-service.interface";

@Injectable({
  providedIn: "root",
})
export class UserFormService implements FormService {
  isDynamic: boolean = true;
  formData: UserDto;
  title: string = "Utilisateur";
  icon: string = "nature_people";

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private notificationsService: NotificationsService
  ) {}

  onSave(value: any): Promise<any> {
    const result = <UserDto>value;

    if (isGuid(this.formData.id)) {
      if (
        (this.formData.userRole === EnumUserRole.NaxosAdmin ||
          this.formData.userRole === EnumUserRole.CompanyAdmin) &&
        this.formData.userRole !== result.userRole
      ) {
        return this.userService
          .IsTheOnlyOneAdmin({ userId: this.formData.id })
          .toPromise()
          .then((isTheOnlyOneAdmin) => {
            this.formData.firstName = result.firstName;
            this.formData.lastName = result.lastName;
            this.formData.userName = result.userName;
            this.formData.email = result.email;
            this.formData.userRole = result.userRole;
            this.formData.userOctopusCMRole = result.userOctopusCMRole;
            this.formData.userOctopusCoreRole = result.userOctopusCoreRole;
            this.formData.provider = result.provider;
            const params = { id: this.formData.id, body: this.formData };
            return this.userService
              .Put(params)
              .toPromise()
              .then((item) => {
                this.formData.id = item.id;
              });
          });
      } else {
        this.formData.firstName = result.firstName;
        this.formData.lastName = result.lastName;
        this.formData.userName = result.userName;
        this.formData.email = result.email;
        this.formData.userRole = result.userRole;
        this.formData.userOctopusCMRole = result.userOctopusCMRole;
        this.formData.userOctopusCoreRole = result.userOctopusCoreRole;
        this.formData.provider = result.provider;
        const params = { id: this.formData.id, body: this.formData };
        return this.userService
          .Put(params)
          .toPromise()
          .then((item) => {
            this.formData.id = item.id;
          });
      }
    } else {
      this.formData.firstName = result.firstName;
      this.formData.lastName = result.lastName;
      this.formData.userName = result.userName;
      this.formData.email = result.email;
      this.formData.userRole = result.userRole;
      this.formData.userOctopusCMRole = result.userOctopusCMRole;
      this.formData.userOctopusCoreRole = result.userOctopusCoreRole;
      this.formData.provider = result.provider;
      return this.userService
        .Post({ body: this.formData })
        .toPromise()
        .then((item) => {
          this.formData.id = item.id;
        });
    }
  }

  onCancel(): Promise<void> {
    return new Promise<void>((resolve) => resolve());
  }

  onDelete(): Promise<void> {
    return this.userService
      .IsTheOnlyOneAdmin({ userId: this.formData.id })
      .toPromise()
      .then((isTheOnlyOneAdmin) => {
        if (isTheOnlyOneAdmin) {
          this.notificationsService.info(
            "This user is the only admin. You can't delete it",
            "not enough admins"
          );
          return new Promise<void>((resolve) => resolve());
        } else {
          if (isGuid(this.formData.id)) {
            return this.userService
              .Delete({ id: this.formData.id })
              .toPromise()
              .then(() => {});
          }
          return new Promise<void>((resolve) => resolve());
        }
      });
  }

  async getFields(): Promise<FieldConfig[]> {
    let selfEdit = false;
    if (this.authService.currentUser.id === this.formData?.id) {
      selfEdit = true;
    }

    const fields: FieldConfig[] = [
      {
        type: "input",
        inputType: "text",
        name: "firstName",
        label: "Prénom",
        value: this.formData?.firstName,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "First name is required",
          },
        ],
      },
      {
        type: "input",
        inputType: "text",
        name: "lastName",
        label: "Nom",
        value: this.formData?.lastName,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Lastname is required",
          },
        ],
      },
      {
        type: "input",
        inputType: "text",
        name: "userName",
        label: "Nom d'utilisateur",
        value: this.formData?.userName,
        validations: [
          {
            name: "required",
            validator: Validators.required,
            message: "Username is required",
          },
        ],
      },
    ];
    fields.push({
      type: "input",
      inputType: "text",
      name: "email",
      label: "Adresse email",
      value: this.formData?.email,
      validations: [
        {
          name: "required",
          validator: Validators.required,
          message: "Email is required",
        },
        {
          name: "pattern",
          validator: Validators.pattern(
            "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
          ),
          message: "Invalid email",
        },
      ],
      disable: selfEdit,
    });

    const allowedRoles: EnumUserRole[] = await this.userService
      .GetAllowedRolesForCompany({
        companyId: this.authService.currentCompany?.id,
      })
      .toPromise();
    const userAllowedRoles = allowedRoles.map(
      (ar) => new SelectItems(ar, EnumUserRole[ar])
    );

    fields.push({
      type: "selectObject",
      name: "userRole",
      label: "Rôle de l'utilisateur",
      value: this.formData?.userRole,
      optionsObject: userAllowedRoles,
      validations: [
        {
          name: "required",
          validator: Validators.required,
          message: "User role is required",
        },
      ],
    });

    const providers = [
      new SelectItems(0, "Octopus"),
      new SelectItems(1, "Office 365"),
      new SelectItems(2, "Microsoft"),
      new SelectItems(3, "Google"),
      new SelectItems(4, "LinkedIn"),
    ];

    fields.push({
      type: "selectObject",
      name: "provider",
      label: "Compte",
      value: this.formData?.provider,
      optionsObject: providers,
      validations: [
        {
          name: "required",
          validator: Validators.required,
          message: "The provider is required",
        },
      ],
      disable: selfEdit,
    });

    return new Promise<FieldConfig[]>((resolve) => {
      resolve(fields);
    });
  }
}
