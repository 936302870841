import { Injectable } from "@angular/core";

import { FormBuilder, Form, FormGroup } from "@angular/forms";
import { NaxosNotificationComponent } from "app/layouts/naxos-framework/form/naxos-notification/naxos-notification.component";
@Injectable({
  providedIn: "root"
})
export class NaxosNotificationService {
  private notificationform: NaxosNotificationComponent;
  // const type = ['','info','success','warning','danger'];
  private _form: FormGroup;
  public content = "Awesome! Well executed";
  public title = "Well done";
  public timeOut: Number = 1200;
  constructor() {
    this.notificationform = new NaxosNotificationComponent();
  }
  public Success(title: string = this.title, content = this.content) {
    this.notificationform.run(content, "success");
  }
  public Error(
    title: string = "Error",
    content: string = "Whoops, something went wrong."
  ) {
    this.notificationform.run(content, "danger");
  }
  public Warn(title: string, content: string) {
    this.notificationform.run(content, "warning");
  }
  public Info(content: string) {
    this.notificationform.run(content, "info");
  }
}
