import { SimulationService } from "app/api/octopus-web/services";
import { LicencesService } from "app/api/licence/services";
import { AuthService } from "./../services/auth.service";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { LicenceDto } from "app/api/licence/models";

@Injectable({
  providedIn: "root",
})
export class HasLicenceGuard implements CanActivate {
  items: LicenceDto[];
  licenceCount = 0;

  constructor(
    private authService: AuthService,
    private licenceService: LicencesService,
    private simulationService: SimulationService
  ) {}
  canActivate() {
    return this.authService.hasCompanyLicense
      ? true
      : this.authService.hasCompanyLicense$;
  }
}
