import { LicencesService } from "app/api/licence/services";
import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { CompanyDto, SimulationDto, UserDto } from "app/api/octopus-web/models";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { popup } from "environments/environment";

import { of } from "rxjs";
import { isEmpty } from "rxjs/operators";

import { SidebarService } from "app/components/sidebar/services/sidebar.service";
import {
  SimulationService,
  CompanyService,
  UserService,
} from "app/api/octopus-web/services";
import { LicenceDto } from "app/api/licence/models";
import { hasValue } from "app/layouts/naxos-framework/services";
import { RoutePaths } from "app/models/menus-routes/route-paths";
import { CurrentSimulationService } from "app/services/current-simulation.service";

@Component({
  selector: "app-naxos-company-view",
  templateUrl: "./naxos-company-view.component.html",
  styleUrls: ["./naxos-company-view.component.scss"],
})
export class NaxosCompanyViewComponent implements OnInit {
  title = "Gestion des entreprises";
  icon = "person";

  items: CompanyDto[];
  licenceItems: LicenceDto[];
  isFormReady = false;
  itemExist = false;
  term = "";
  private searchTerms = new Subject<string>();

  constructor(
    public dialog: MatDialog,
    private companyService: CompanyService,
    private simulationService: SimulationService,
    private licenceService: LicencesService,
    private router: Router,
    private currentSimulationService: CurrentSimulationService
  ) {}

  async ngOnInit() {
    await this.loadData();
  }

  SearchTerm(): void {
    // this.companyService
    //   .searchByFirstName(this.term)
    //   .toPromise()
    //   .then(data => {
    //     console.log("data", (this.items = data));
    //   });
  }

  // Push a search term into the observable stream.
  search(term: string): void {
    this.term = term;
    this.searchTerms.next(term);
    this.SearchTerm();
  }

  async loadData() {
    await this.companyService
      .GetAll()
      .toPromise()
      .then((data) => {
        this.items = data;
      });
    await this.licenceService
      .GetAll()
      .toPromise()
      .then((data) => {
        this.licenceItems = data;
        for (let i = 0; i < this.items.length; i++) {
          for (let j = 0; j < this.licenceItems.length; j++) {
            if (this.items[i].id == this.licenceItems[j].companyId) {
              this.items[i].hasLicense = true;
            }
          }
        }
        this.isFormReady = true;
      });
  }

  addCompany() {
    this.router.navigateByUrl(RoutePaths.adminAddCompany.path);
  }

  simulation(companyId: string) {
    this.simulationService
      .Put({ companyId: companyId })
      .toPromise()
      .then((data) => {
        if (data) {
          this.currentSimulationService.changeSimulation(data);
          this.router.navigateByUrl(RoutePaths.company.path);
        }
      });
  }

  async delete(companyId: string) {
    if (this.currentSimulationService.simulation?.companyId === companyId) {
      await this.simulationService.DeleteAsync().toPromise().then((simulation) => {
        this.currentSimulationService.changeSimulation(null);
      });
    }
    await this.companyService
      .Delete({ id: companyId })
      .toPromise()
      .then((company) => {
        this.loadData();
      });
  }
}
