/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AddressApiConfiguration as __Configuration } from '../address-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CityDTO } from '../models/city-dto';
@Injectable({
  providedIn: 'root',
})
class CitiesService extends __BaseService {
  static readonly GetAllPath = '/api/Cities';
  static readonly PostitemPath = '/api/Cities';
  static readonly GetByIdPath = '/api/Cities/{id}';
  static readonly PutitemPath = '/api/Cities/{id}';
  static readonly DeleteitemPath = '/api/Cities/{id}';
  static readonly ExistsPath = '/api/Cities/Exists{id}';
  static readonly GetCityByZipCodePath = '/api/Cities/GetCityByZipCode';
  static readonly GetCityByNamePath = '/api/Cities/GetCityByName';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }
  GetAllResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Cities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  GetAll(): __Observable<null> {
    return this.GetAllResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param item undefined
   */
  PostitemResponse(item?: CityDTO): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = item;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Cities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param item undefined
   */
  Postitem(item?: CityDTO): __Observable<null> {
    return this.PostitemResponse(item).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  GetByIdResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Cities/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  GetById(id: string): __Observable<null> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CitiesService.PutitemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `item`:
   */
  PutitemResponse(params: CitiesService.PutitemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.item;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Cities/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CitiesService.PutitemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `item`:
   */
  Putitem(params: CitiesService.PutitemParams): __Observable<null> {
    return this.PutitemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteitemResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Cities/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Deleteitem(id: string): __Observable<null> {
    return this.DeleteitemResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  ExistsResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Cities/Exists${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Exists(id: string): __Observable<null> {
    return this.ExistsResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CitiesService.GetCityByZipCodeParams` containing the following parameters:
   *
   * - `zipCode`:
   *
   * - `isoCountryCode`:
   */
  GetCityByZipCodeResponse(params: CitiesService.GetCityByZipCodeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.zipCode != null) __params = __params.set('zipCode', params.zipCode.toString());
    if (params.isoCountryCode != null) __params = __params.set('isoCountryCode', params.isoCountryCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Cities/GetCityByZipCode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CitiesService.GetCityByZipCodeParams` containing the following parameters:
   *
   * - `zipCode`:
   *
   * - `isoCountryCode`:
   */
  GetCityByZipCode(params: CitiesService.GetCityByZipCodeParams): __Observable<null> {
    return this.GetCityByZipCodeResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CitiesService.GetCityByNameParams` containing the following parameters:
   *
   * - `name`:
   *
   * - `IsoCountryCode`:
   */
  GetCityByNameResponse(params: CitiesService.GetCityByNameParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.IsoCountryCode != null) __params = __params.set('IsoCountryCode', params.IsoCountryCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Cities/GetCityByName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CitiesService.GetCityByNameParams` containing the following parameters:
   *
   * - `name`:
   *
   * - `IsoCountryCode`:
   */
  GetCityByName(params: CitiesService.GetCityByNameParams): __Observable<null> {
    return this.GetCityByNameResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CitiesService {

  /**
   * Parameters for Putitem
   */
  export interface PutitemParams {
    id: string;
    item?: CityDTO;
  }

  /**
   * Parameters for GetCityByZipCode
   */
  export interface GetCityByZipCodeParams {
    zipCode?: string;
    isoCountryCode?: string;
  }

  /**
   * Parameters for GetCityByName
   */
  export interface GetCityByNameParams {
    name?: string;
    IsoCountryCode?: string;
  }
}

export { CitiesService }
