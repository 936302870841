import { hasValue } from "app/layouts/naxos-framework/services";
import { AuthService } from "app/security/services";
import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { popup } from "environments/environment";
import {
  UserService,
  SimulationService,
  CompanyService,
} from "app/api/octopus-web/services";
import { CompanyDto, SimulationDto, UserDto } from "app/api/octopus-web/models";
import { LicencesService } from "app/api/licence/services";
import { RoutePaths } from "app/models/menus-routes/route-paths";

@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.scss"],
})
export class CompanyComponent implements OnInit {
  company: CompanyDto = null;
  hasCompanyLicense = false;
  IsReadOnly = false;

  constructor(
    public dialog: MatDialog,
    private companyService: CompanyService,
    private router: Router,
    public authService: AuthService,
    private licenseService: LicencesService
  ) {
    this.authService.currentCompany$.subscribe({
      next: (currentCompany) => {
        this.loadData();
      },
    });
    this.hasCompanyLicense = this.authService.hasCompanyLicense;
    this.authService.hasCompanyLicense$.subscribe({
      next: (hasCompanyLicense) => {
        this.hasCompanyLicense = hasCompanyLicense;
      },
    });
  }

  ngOnInit(): void {
    this.IsReadOnly = !this.authService.isNaxosAdmin && !this.authService.isCompanyAdmin;
    this.loadData();
  }

  loadData(): any {
    this.company = this.authService.currentCompany;
    if (!hasValue(this.company)) {
      this.company = {} as CompanyDto;
    }
    this.company.users = [this.authService.currentUser];
  }

  goToWarning() {
    this.router.navigateByUrl(RoutePaths.unsubscribeWarning.path + this.company.id
    );
  }

  UnsubscribeCompany() {
    this.companyService
      .UnsubscribeCompany({ id: this.company.id })
      .subscribe(() => {
        this.loadData();
      });
  }

  onSaveCompany(company: CompanyDto) {
    if (!hasValue(this.company?.id)) {
      this.authService.currentCompanySubject.next(company);
    }
  }
}
