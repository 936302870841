import { Injectable } from "@angular/core";
import { UserDto } from "app/api/octopus-web/models";
import { SimulationService } from "app/api/octopus-web/services";
import { SidebarService } from "app/components/sidebar/services/sidebar.service";
import { Menu } from "app/models/menus/menu";
import { AuthService } from "app/security/services";
import { hasValue } from "app/layouts/naxos-framework/services";
import { navPublicAccountMenuRoutePaths, RoutePaths, navMenuRoutePaths } from "app/models/menus-routes/route-paths";
import { Observable, Subject } from "rxjs";
import { CurrentSimulationService } from "./current-simulation.service";
import { EnumMenu } from "app/models/menus/enum-menu";

@Injectable({
  providedIn: "root",
})
export class MenusService {
  public modeSimulationActive = false;
  public companyName = "";
  public currentUser: UserDto;
  userFullName: string = "";
  hasCompanyLicense = false;
  isLoggedIn = false;

  navPublicMainMenu: Menu;
  navPublicMainMenu$: Observable<Menu>;
  navPublicMainMenuSubject: Subject<Menu>;

  navManagementMenu: Menu;
  navManagementMenu$: Observable<Menu>;
  navManagementMenuSubject: Subject<Menu>;

  navPublicAccountMenu: Menu;
  navPublicAccountMenu$: Observable<Menu>;
  navPublicAccountMenuSubject: Subject<Menu>;

  sidebarMenu: Menu[];
  sidebarMenu$: Observable<Menu[]>;
  sidebarMenuSubject: Subject<Menu[]>;

  constructor(
    public sidebarservice: SidebarService,
    public simulationService: SimulationService,
    public authService: AuthService,
    private currentSimulationService: CurrentSimulationService,
  ) {
    this.isLoggedIn = this.authService.loggedIn;

    this.navPublicMainMenuSubject = new Subject();
    this.navPublicMainMenu$ = this.navPublicMainMenuSubject.asObservable();
    this.navPublicMainMenu$.subscribe((navPublicMainMenu) => {
      this.navPublicMainMenu = navPublicMainMenu;
    });

    this.navPublicAccountMenuSubject = new Subject();
    this.navPublicAccountMenu$ = this.navPublicAccountMenuSubject.asObservable();
    this.navPublicAccountMenu$.subscribe((navPublicAccountMenu) => {
      this.navPublicAccountMenu = navPublicAccountMenu;
    });

    this.navManagementMenuSubject = new Subject();
    this.navManagementMenu$ = this.navManagementMenuSubject.asObservable();
    this.navManagementMenu$.subscribe((navManagementMenu) => {
      this.navManagementMenu = navManagementMenu;
    });

    this.sidebarMenuSubject = new Subject();
    this.sidebarMenu$ = this.sidebarMenuSubject.asObservable();
    this.sidebarMenu$.subscribe((sidebarMenu) => {
      this.sidebarMenu = sidebarMenu;
    });

    this.hasCompanyLicense = this.authService.hasCompanyLicense;
    this.authService.hasCompanyLicense$.subscribe((hasCompanyLicense) => {
      this.isLoggedIn = this.authService.loggedIn;
      this.setCurrentUserInfo();
      this.hasCompanyLicense = hasCompanyLicense;
      this.loadMenusNavbar();
      this.loadMenusSidebar();
    });

    this.currentSimulationService.simulation$.subscribe({
      next: (simulation) => {
        this.setCurrentUserInfo();
        this.loadMenusNavbar();
        this.loadMenusSidebar();
      }
    });

    this.setCurrentUserInfo();
    this.loadMenusNavbar();
    this.loadMenusSidebar();
  }

  setSimulationState() {
    this.modeSimulationActive = hasValue(
      this.currentSimulationService.simulation
    );
  }

  setCurrentUserInfo() {
    this.currentUser = this.authService.currentUser;
    this.userFullName = this.currentUser?.firstName + " " + this.currentUser?.lastName;
    if (this.currentUser == undefined || this.currentUser == null) {
      this.userFullName = "Compte";
    }
  }

  async loadCompanyName() {
    this.companyName = this.authService.currentCompany?.institutionName;
  }

  async loadMenusNavbar() {
    const navPublicMenuRoutes = [
      {
        path: navMenuRoutePaths.home.path,
        title: navMenuRoutePaths.home.title,
        type: navMenuRoutePaths.home.type,
        icontype: navMenuRoutePaths.home.iconType,
      },
      { path: navMenuRoutePaths.description.path, title: navMenuRoutePaths.description.title, type: navMenuRoutePaths.description.type, icontype: navMenuRoutePaths.description.iconType },
      { path: navMenuRoutePaths.testimonies.path, title: navMenuRoutePaths.testimonies.title, type: navMenuRoutePaths.testimonies.type, icontype: navMenuRoutePaths.testimonies.iconType },
      { path: navMenuRoutePaths.contact.path, title: navMenuRoutePaths.contact.title, type: navMenuRoutePaths.contact.type, icontype: navMenuRoutePaths.contact.iconType },
      {
        path: navMenuRoutePaths.tryIt.path,
        title: navMenuRoutePaths.tryIt.title,
        type: navMenuRoutePaths.tryIt.type,
        icontype: navMenuRoutePaths.tryIt.iconType,
        collapse: EnumMenu[EnumMenu.tryit],
        children: [
          { path: navMenuRoutePaths.subscription.path, title: navMenuRoutePaths.subscription.title, icontype: navMenuRoutePaths.subscription.iconType },
          { path: navMenuRoutePaths.octopusTour.path, title: navMenuRoutePaths.octopusTour.title, icontype: navMenuRoutePaths.octopusTour.iconType }
        ]
      },
      {
        path: navMenuRoutePaths.help.path,
        title: navMenuRoutePaths.help.title,
        type: navMenuRoutePaths.help.type,
        icontype: navMenuRoutePaths.help.iconType,
        collapse: EnumMenu[EnumMenu.help],
        children: [
          { path: navMenuRoutePaths.support.path, title: navMenuRoutePaths.support.title, icontype: navMenuRoutePaths.support.iconType },
          { path: navMenuRoutePaths.files.path, title: navMenuRoutePaths.files.title, icontype: navMenuRoutePaths.files.iconType },
          { path: navMenuRoutePaths.aboutus.path, title: navMenuRoutePaths.aboutus.title, icontype: navMenuRoutePaths.aboutus.iconType }
        ]
      },
    ];
    this.navPublicMainMenuSubject.next({
      id: EnumMenu[EnumMenu.navPublic],
      order: EnumMenu.navPublic,
      routes: navPublicMenuRoutes
    });

    const navPublicAccountMenuRoutes = [
      {
        path: navPublicAccountMenuRoutePaths.account.path,
        title: this.userFullName,
        type: navPublicAccountMenuRoutePaths.account.type,
        icontype: navPublicAccountMenuRoutePaths.account.iconType,
        collapse: EnumMenu[EnumMenu.account],
        children: []
      },
    ];
    if (this.isLoggedIn) {
      navPublicAccountMenuRoutes
        .find((x) => x.title === this.userFullName)
        .children.push(
          ...[
            { path: navPublicAccountMenuRoutePaths.profile.path, title: navPublicAccountMenuRoutePaths.profile.title, icontype: navPublicAccountMenuRoutePaths.profile.iconType },
            { path: navPublicAccountMenuRoutePaths.passwordReset.path, title: navPublicAccountMenuRoutePaths.passwordReset.title, icontype: navPublicAccountMenuRoutePaths.passwordReset.iconType },
            { path: navPublicAccountMenuRoutePaths.logout.path, title: navPublicAccountMenuRoutePaths.logout.title, icontype: navPublicAccountMenuRoutePaths.logout.iconType }
          ]
        );
    } else {
      navPublicAccountMenuRoutes
        .find((x) => x.title === this.userFullName)
        .children.push(
          ...[
            { path: navPublicAccountMenuRoutePaths.login.path, title: navPublicAccountMenuRoutePaths.login.title, icontype: navPublicAccountMenuRoutePaths.login.iconType },
          ]
        );
    }
    this.navPublicAccountMenuSubject.next({ id: EnumMenu[EnumMenu.account], order: EnumMenu.account, routes: navPublicAccountMenuRoutes });

    const navManagementMenuRoutes = [
      {
        path: navMenuRoutePaths.home.path,
        title: navMenuRoutePaths.home.title,
        type: navMenuRoutePaths.home.type,
        icontype: navMenuRoutePaths.home.iconType,
      },
      {
        path: navMenuRoutePaths.support.path,
        title: navMenuRoutePaths.support.title,
        type: navMenuRoutePaths.support.type,
        icontype: navMenuRoutePaths.support.iconType,
      },
      {
        path: navMenuRoutePaths.contact.path,
        title: navMenuRoutePaths.contact.title,
        type: navMenuRoutePaths.contact.type,
        icontype: navMenuRoutePaths.contact.iconType,
      }
    ];
    this.navManagementMenuSubject.next({
      id: EnumMenu[EnumMenu.navManagement],
      order: EnumMenu.navManagement,
      routes: navManagementMenuRoutes
    });
  }

  async loadMenusSidebar() {
    var sidebarMenuRoutes = [];

    var menus: Menu[] = [];
    var title = this.userFullName;

    if (this.currentUser != undefined && this.currentUser != null) {
      //=> Set simulation
      if (this.authService.isCompanyAdmin || this.authService.isNaxosAdmin) {
        await this.loadCompanyName();
        this.setSimulationState();
      }

      //=> Add to user menu (if naxos admin)
      if (!this.authService.isNaxosAdmin)
      sidebarMenuRoutes.push(
        {
          path: RoutePaths.companyDownload.path,
          title: RoutePaths.companyDownload.title,
          type: RoutePaths.companyDownload.type,
          icontype: RoutePaths.companyDownload.iconType,
          disabled: !this.hasCompanyLicense
        });

      //=> Add to company menu if isCompany admin or user role
      if (this.authService.isCompanyAdmin || !this.authService.isNaxosAdmin) {

        title += ";" + this.companyName;

        if (this.authService.hasCompanyLicense)
          sidebarMenuRoutes.push({
            path: RoutePaths.allProjects.path,
            title: RoutePaths.allProjects.title,
            type: RoutePaths.allProjects.type,
            icontype: RoutePaths.allProjects.iconType
          });

        sidebarMenuRoutes.push({
          path: RoutePaths.company.path,
          title: RoutePaths.company.title,
          type: RoutePaths.company.type,
          icontype: RoutePaths.company.iconType
        });
        sidebarMenuRoutes.push({
          path: RoutePaths.companyUsers.path,
          title: RoutePaths.companyUsers.title,
          type: RoutePaths.companyUsers.type,
          icontype: RoutePaths.companyUsers.iconType,
          disabled: false
        });
        sidebarMenuRoutes.push({
          path: RoutePaths.companyLicences.path,
          title: RoutePaths.companyLicences.title,
          type: RoutePaths.companyLicences.type,
          icontype: RoutePaths.companyLicences.iconType,
          disabled: !this.hasCompanyLicense
        });
      }

      //=> Add to company menu if is Naxos admin
      if (this.authService.isNaxosAdmin) {
        sidebarMenuRoutes.push({
          path: RoutePaths.naxosDownload.path,
          title: RoutePaths.naxosDownload.title,
          type: RoutePaths.naxosDownload.type,
          icontype: RoutePaths.naxosDownload.iconType,
        });
        sidebarMenuRoutes.push({
          path: RoutePaths.allUsers.path,
          title: RoutePaths.allUsers.title,
          type: RoutePaths.allUsers.type,
          icontype: RoutePaths.allUsers.iconType,
        });
        sidebarMenuRoutes.push({
          path: RoutePaths.allCompanies.path,
          title: RoutePaths.allCompanies.title,
          type: RoutePaths.allCompanies.type,
          icontype: RoutePaths.allCompanies.iconType,
        });
        sidebarMenuRoutes.push({
          path: RoutePaths.naxosFiles.path,
          title: RoutePaths.naxosFiles.title,
          type: RoutePaths.naxosFiles.type,
          icontype: RoutePaths.naxosFiles.iconType,
        });
        sidebarMenuRoutes.push({
          path: RoutePaths.companyHostings.path,
          title: RoutePaths.companyHostings.title,
          type: RoutePaths.companyHostings.type,
          icontype: RoutePaths.companyHostings.iconType,
        });
      }

      menus.push({
        id: EnumMenu[EnumMenu.user],
        order: EnumMenu.user,
        name: title,
        routes: sidebarMenuRoutes,
      });



      if (this.authService.isNaxosAdmin && this.modeSimulationActive) {
        menus.push({
          id: EnumMenu[EnumMenu.company],
          order: EnumMenu.company,
          name: this.companyName,
          routes: [
            {
              path: RoutePaths.company.path,
              title: RoutePaths.company.title,
              type: RoutePaths.company.type,
              icontype: RoutePaths.company.iconType,
            },
            {
              path: RoutePaths.companyUsers.path,
              title: RoutePaths.companyUsers.title,
              type: RoutePaths.companyUsers.type,
              icontype: RoutePaths.companyUsers.iconType,
              disabled: false,
            },
            {
              path: RoutePaths.companyLicences.path,
              title: RoutePaths.companyLicences.title,
              type: RoutePaths.companyLicences.type,
              icontype: RoutePaths.companyLicences.iconType,
              disabled: !this.hasCompanyLicense,
            },
          ]
        });
      }
      this.sidebarMenuSubject.next(menus);
    }
    else
      this.sidebarMenuSubject.next(null);
  }
}
