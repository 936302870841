import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MaterialModule } from "../naxos-framework/material.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NaxosFrameworkModule } from "../naxos-framework/naxos-framework.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { DynamicFormComponent } from "../naxos-framework/form/dynamic-form/dynamic-form.component";
import { SpecificFormComponent } from './forms/generic/card-form/specific-form/specific-form.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CardFormComponent } from './forms/generic/card-form/card-form.component';
import { CompanyFormComponent } from './forms/specific/company-form/company-form.component';
import { ContactFormComponent } from './forms/specific/contact-form/contact-form.component';
import { FilesComponent } from './components/files/files.component';
import { FileFormComponent } from './forms/specific/file-form/file-form.component';
import { NgxFilesizeModule } from 'ngx-filesize';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    MatDialogModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NaxosFrameworkModule,
    PerfectScrollbarModule,
    NgxFilesizeModule,
  ],

  entryComponents: [
    DynamicFormComponent
  ],
  exports: [
    //MODULE
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    //COMPONENT
    CardFormComponent,
    FilesComponent,
  ],
  declarations: [
    CardFormComponent,
    SpecificFormComponent,
    CompanyFormComponent,
    ContactFormComponent,
    FilesComponent,
    FileFormComponent,
  ]
})
export class SharedLayoutModule {}
