import { NgModule } from "@angular/core";
import { OctopusTourRoutes } from "./octopus-tour-routing.module";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { OctopusTourLayoutComponent } from "./components/octopus-tour-layout/octopus-tour-layout.component";
import { SidebarTourComponent } from "./components/sidebar-tour/sidebar-tour.component";
import { ComponentsModule } from "app/components/components.module";
import { NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { SlideComponent } from './view/slide/slide.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    RouterModule.forChild(OctopusTourRoutes),
    ComponentsModule,
    NgMaterialMultilevelMenuModule,
    PerfectScrollbarModule,
  ],
  declarations: [
    OctopusTourLayoutComponent,
    SidebarTourComponent,
    SlideComponent,
  ],
})
export class OctopusTourModule {}
