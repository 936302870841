import { DownloadLinkDto } from './../../../../api/licence/models/download-link-dto';
import { FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { DownloadLinksService } from 'app/api/licence/services';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, DefaultIterableDiffer, OnInit } from '@angular/core';
import { RoutePaths } from 'app/models/menus-routes/route-paths';

@Component({
  selector: 'app-update-download-view',
  templateUrl: './update-download-view.component.html',
  styleUrls: ['./update-download-view.component.scss']
})
export class UpdateDownloadViewComponent implements OnInit {
  title = "Modifier le lien de téléchargement";
  icon = "cloud_download";

  downloadLinkId: string;
  public downloadLink = {} as DownloadLinkDto;
  form: FormGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private downloadLinkService: DownloadLinksService,
    private builder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.downloadLinkId = this.route.snapshot.paramMap.get('id');
    this.downloadLinkService.GetOne({ id: this.downloadLinkId }).subscribe(data => {
      this.downloadLink = data;
    })

    this.form = this.builder.group({
      cmUrl: [this.downloadLink.cmUrl, Validators.required],
      coreUrl: [this.downloadLink.coreUrl, Validators.required],
    })

  }

  saveLinks(){
    if(this.form.valid){
      this.downloadLink.cmUrl = this.form.controls['cmUrl'].value;
      this.downloadLink.coreUrl = this.form.controls['coreUrl'].value;
    }

    this.downloadLinkService.Put({ id: this.downloadLinkId, body: this.downloadLink }).subscribe();
    this.router.navigateByUrl(RoutePaths.allUsers.path);

  }

}
