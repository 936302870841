import { Component, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { LicenceDto } from "app/api/licence/models";
import { LicencesService } from "app/api/licence/services";
import {
  EnumUserRole,
  SimulationDto,
  UserDto,
} from "app/api/octopus-web/models";
import {
  CompanyService,
  SimulationService,
  UserService,
} from "app/api/octopus-web/services";
import { hasValue } from "app/layouts/naxos-framework/services";
import { CardFormComponent } from "app/layouts/shared/forms/generic/card-form/card-form.component";
import { AuthService } from "app/security/services";
import { CurrentSimulationService } from "app/services/current-simulation.service";
import { popup } from "environments/environment";
import { Subject } from "rxjs";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  @Input() forCompany: boolean;
  title = "Utilisateurs";
  icon = "person";
  items: UserDto[];
  isFormReady = false;
  term = "";
  canUpdate = false;
  license: LicenceDto;
  hasCompanyLicense: boolean = false;
  canEditOctopusRole = false;
  companyLicenseKey: string;
  public userDialogRef: MatDialogRef<CardFormComponent>;
  userRoles;

  public searchTerms = new Subject<string>();

  constructor(
    public dialog: MatDialog,
    private UserDtoService: UserService,
    private authService: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.license = this.authService.companyLicense;
    
    if (this.authService.isCompanyAdmin || this.authService.isNaxosAdmin)
    {
      this.canUpdate = true;
    }   
    else
      this.canUpdate = false;

    this.hasCompanyLicense = this.authService.hasCompanyLicense;
    this.canEditOctopusRole = false; // this.hasOctopusLicence || !this.forCompany;
    this.companyLicenseKey = this.license?.licenceKey;
    this.authService.hasCompanyLicense$.subscribe({
      next: async (hasCompanyLicense) => {
        this.license = this.authService.companyLicense;
        this.hasCompanyLicense = this.authService.hasCompanyLicense;
        this.canEditOctopusRole = false; // this.hasOctopusLicence || !this.forCompany;
        this.companyLicenseKey = this.license?.licenceKey;
        await this.loadData();
      },
    });
    await this.loadData();

    this.userRoles = EnumUserRole;
    
    this.loadData();
  }

  loadData(): any {
    if (this.forCompany) {
      this.UserDtoService.GetAllForCompany()
        .toPromise()
        .then((data) => {
          this.items = data;
          this.isFormReady = true;
        });
    } else {
      this.UserDtoService.GetAllWithoutCompany()
        .toPromise()
        .then((data) => {
          this.items = data;
          this.isFormReady = true;
        });
    }
  }

  SearchTerm(): void {
    if (this.forCompany) {
      this.UserDtoService.GetByLastName({ lastName: this.term })
        .toPromise()
        .then((data) => {
          this.items = data;
        });
    } else {
      this.UserDtoService.GetByLastName({ lastName: this.term })
        .toPromise()
        .then((data) => {
          this.items = data;
        });
    }
  }

  search(term: string): void {
    if (term === "") {
      this.loadData();
      return;
    }
    this.term = term;
    this.searchTerms.next(term);
    this.SearchTerm();
  }

  modifyDialog(item: UserDto) {
    if (item === null) {
      item = {} as UserDto;
      if (this.forCompany) {
        item.companyId = this.authService.currentCompany.id;
      }
      this.userDialogRef = this.dialog.open(CardFormComponent, {
        width: popup.width,
        height: popup.height,
        data: {
          current: item,
          type: 'user',
          EnableDelete: false
        },
      });
      this.dialog.afterAllClosed.subscribe((result) => {
        if (this.companyLicenseKey != undefined && item.license == null) {
          this.assignLicense();
          console.log("abonnement assigné");
        }
        this.loadData();
      });
    } else {
      this.userDialogRef = this.dialog.open(CardFormComponent, {
        width: popup.width,
        height: popup.height,
        data: {
          current: item,
          type: 'user'
        },
      });
      this.dialog.afterAllClosed.subscribe((result) => {
        this.loadData();
      });
    }
  }

  assignLicense() {
    this.UserDtoService.AssignLicense({
      licenseKey: this.companyLicenseKey,
    }).subscribe(() => {
      this.loadData();
    });
  }
}
