import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Router } from "@angular/router";
import { SimulationService } from "app/api/octopus-web/services";
import { hasValue } from "app/layouts/naxos-framework/services";
import { AuthService } from "../services/auth.service";

@Injectable()
export class CompanyRequiredGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private simulationService: SimulationService
  ) {}
  canActivate() {
    return this.authService.hasCompany ? true : this.authService.hasCompany$;
  }
}
