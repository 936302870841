import { NaxosNotificationService } from './../../../../security/services/NaxosNotification.service';
import { Component, OnInit } from '@angular/core';
import { ItemsService } from 'app/api/content-read/services';
import { SortContentItemsService } from 'app/services/sort-content-items.service';

@Component({
  selector: 'app-testimonies',
  templateUrl: './testimonies.component.html',
  styleUrls: ['./testimonies.component.css']
})
export class TestimoniesComponent implements OnInit {

  testimonials;
  assets;

  constructor(
    private itemsService: SortContentItemsService,
    private notifications: NaxosNotificationService,
  ) { }

  ngOnInit(): void {
    this.itemsService
      .GetFlattenItemByCode({
        applicationCode: "octopus-web",
        itemCode: "testimony",
        locale: "fr"
      })
      .subscribe(testimonials => {
        this.testimonials = testimonials;
      }, (error) => {
        this.notifications.Error('Could not load testimonials', error);
      });

      this.itemsService
      .GetFlattenItemByCode({
        applicationCode: "octopus-web",
        itemCode: "assets",
      })
      .subscribe((assets) => {
        this.itemsService.sortSubItems(assets);
        this.assets = assets;
      });
  }

}
