import { SidebarTourService } from './../../service/sidebar-tour.service';
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
  PopStateEvent
} from "@angular/common";
import { NavbarComponent } from "../../../../components/navbar/navbar.component";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { filter, Observable, Subscription} from "rxjs";
import { AuthService } from "app/security/services";

@Component({
  selector: "app-octopus-tour-layout",
  templateUrl: "./octopus-tour-layout.component.html",
  styleUrls: ["./octopus-tour-layout.component.scss"]
})
export class OctopusTourLayoutComponent implements OnInit {
  private _router: Subscription;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  isAuthenticated: Observable<boolean>;
  isLoggedIn = false;

  config;

  title;

  constructor(
    public location: Location,
    private router: Router,
    private authService: AuthService,
    private sidebarTourService: SidebarTourService
  ) {
    this.location = location;
    this.authService.loggedIn$.subscribe(loggedIn => {
      this.isLoggedIn = loggedIn;
    })
    this.sidebarTourService.setSidebarState(false);
  }

  ngOnInit() {
    const isWindows = navigator.platform.indexOf("Win") > -1 ? true : false;

    if (
      isWindows &&
      !document
        .getElementsByTagName("body")[0]
        .classList.contains("sidebar-mini")
    ) {
      // if we are on windows OS we activate the perfectScrollbar function

      document
        .getElementsByTagName("body")[0]
        .classList.add("perfect-scrollbar-on");
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("perfect-scrollbar-off");
    }
    const elemMainPanel = <HTMLElement>document.querySelector(".main-panel");
    const elemSidebar = <HTMLElement>(
      document.querySelector(".sidebar .sidebar-wrapper")
    );

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        }
      } else if (event instanceof NavigationEnd) {
        if (event.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
      }
    });
    this._router = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        elemMainPanel.scrollTop = 0;
      });
  }

  isMaps(path) {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.slice(1);
    if (path === titlee) {
      return false;
    } else {
      return true;
    }
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  isFirstSlide(){
    return this.router.url == '/octopus-tour/slides/1'
  }

}
