import { CurrentSimulationService } from "./../../services/current-simulation.service";
import { CompanyService, UserService } from "app/api/octopus-web/services";
import { BehaviorSubject, forkJoin, from, Observable, Subject, Subscriber } from "rxjs";
import {
  Injectable,
  EventEmitter,
  OnInit,
  Inject,
  OnDestroy,
} from "@angular/core";
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from "@azure/msal-angular";
import {
  isIE,
  b2cPolicies,
  protectedResourceMapConfig,
  apiSettings,
  apiOctopusCoreConfig,
} from "./../../../environments/environment";
import { CompanyDto, EnumUserRole, UserDto } from "app/api/octopus-web/models";
import { filter, first, map, take, takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import {
  AuthenticationResult,
  AuthenticationScheme,
  EndSessionRequest,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
  Logger,
  LogLevel,
  PopupRequest,
  RedirectRequest,
} from "@azure/msal-browser";
import { hasValue } from "app/layouts/naxos-framework/services";
import { HostingsService, LicencesService } from "app/api/licence/services";
import { HostingRequest } from "app/api/licence/models/hostingRequest";
import { CoreService } from "app/api/core/services";
import { hostingEnvironment } from "environments/environment";
import { CmService } from "app/api/cm/services";

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  title = "Azure AD B2C";
  loggedIn = false;
  loggedIn$ = new Subject<boolean>();
  authResult$: Observable<any>;
  previousLoggedIn = false;
  currentUser: UserDto;
  currentUser$: Observable<UserDto>;
  currentUserSubject: Subject<UserDto>;
  currentCompany: CompanyDto;
  currentCompany$: Observable<CompanyDto>;
  currentCompanySubject: Subject<CompanyDto>;
  hasCompany = false;
  hasCompany$: Observable<boolean>;
  companyLicense: CompanyDto;
  companyLicense$: Observable<CompanyDto>;
  companyLicenseSubject: Subject<CompanyDto>;
  hasCompanyLicense = false;
  hasCompanyLicense$: Observable<boolean>;
  hasCompanyLicenseSubject: Subject<boolean>;
  isNaxosAdmin = false;
  isNaxosAdmin$: Observable<boolean>;
  isCompanyAdmin = false;
  isCompanyAdmin$: Observable<boolean>;
  isUser = false;
  isUser$: Observable<boolean>;
  initials = "";
  initials$: Observable<string>;
  loaded = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userService: UserService,
    private router: Router,
    private companyService: CompanyService,
    private currentSimulationService: CurrentSimulationService,
    private licenseService: LicencesService,
    private hostingService: HostingsService,
    private coreService: CoreService,
    private cmService: CmService
  ) {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoggedIn();
      });

    this.currentUserSubject = new Subject();
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.currentUser$.subscribe((currentUser) => {

      this.currentUser = currentUser;

      /*
        1. User login angular => récupération du companyId from Octopus.Dev.Web/Users (web api)
        2. A partir du CompanyId, récupération de la licenceKey à partir de Octopus.Dev.Licence/Licences (licence api)
        3. A partir de la licenceKey, récupération de l'url core api à partir d'Octopus.Dev.Licence/LicenceHostings + hostings (licence api)
      */

      if (this.currentUser) {
        this.licenseService
          .GetLicenseOfACompany({ companyId: this.currentUser.companyId })
          .subscribe({
            next: (lic) => {

              const license = lic;
 

              let hostingRequest: HostingRequest = { hostingEnvironment: hostingEnvironment, licenceKey: license.licenceKey };
              this.hostingService.GetLicenceAndEnvironment(hostingRequest)
                .toPromise()
                .then(host => {
                  if (host != null)
                  {
                    //=> Set de l'url et scope pour l'api web CORE
                    apiSettings.OctopusCoreApiEndPoint = host.coreApi;
                    protectedResourceMapConfig.set(apiSettings.OctopusCoreApiEndPoint + "/api", ["https://b2c.tst.naxosit.com/octopus-api/Core.ReadWrite"]);
                    this.coreService.setService(apiSettings.OctopusCoreApiEndPoint, license);
                    //<= Set de l'url et scope pour l'api web CORE

                    //=> Set de l'url et scope pour l'api web CM
                    apiSettings.OctopusCmApiEndPoint = host.cmApi;
                    protectedResourceMapConfig.set(apiSettings.OctopusCmApiEndPoint + "/api", ["https://b2c.tst.naxosit.com/octopus-api/CM.ReadWrite"]);
                    this.cmService.setService(apiSettings.OctopusCmApiEndPoint, license);
                     //<= Set de l'url et scope pour l'api web CM
                  }
                  else
                  {
                    alert("We cannot display your projects due to an error : No host has been found. Please, contact your administrator. ");
                  }
                });
            },
          });
      };

      this.currentSimulationService.simulation$.subscribe({
        next: (currentSimulation) => {
          this.setCurrentCompany();
        },
      });
    });

    this.currentCompanySubject = new Subject();
    this.currentCompany$ = this.currentCompanySubject.asObservable();
    this.currentCompany$.subscribe((currentCompany) => {
      this.currentCompany = currentCompany;
      this.setCompanyLicense();
    });

    this.hasCompany$ = this.currentCompany$.pipe(
      map((company) => hasValue(company))
    );
    this.hasCompany$.subscribe((hasCompany) => {
      this.hasCompany = hasCompany;
    });

    this.companyLicenseSubject = new Subject();
    this.companyLicense$ = this.companyLicenseSubject.asObservable();
    this.companyLicense$.subscribe((companyLicense) => {
      this.companyLicense = companyLicense;
      this.setHasCompanyLicense();
    });

    this.hasCompanyLicenseSubject = new Subject();
    this.hasCompanyLicense$ = this.hasCompanyLicenseSubject.asObservable();
    this.hasCompanyLicense$.subscribe((hasCompanyLicense) => {
      this.hasCompanyLicense = hasCompanyLicense;
    });

    this.isNaxosAdmin$ = this.currentUser$.pipe(
      map((user) => user?.userRole === EnumUserRole.NaxosAdmin)
    );
    this.isNaxosAdmin$.subscribe((isNaxosAdmin) => {
      this.isNaxosAdmin = isNaxosAdmin;
      if (this.isNaxosAdmin) {
        this.currentSimulationService.setSimulation();
      }
    });

    this.isCompanyAdmin$ = this.currentUser$.pipe(
      map((user) => user?.userRole === EnumUserRole.CompanyAdmin)
    );
    this.isCompanyAdmin$.subscribe((isCompanyAdmin) => {
      this.isCompanyAdmin = isCompanyAdmin;
      if (this.isCompanyAdmin) {
        this.setCurrentCompany();
      }
    });

    this.isUser$ = this.currentUser$.pipe(map((user) => user?.userRole === EnumUserRole.User));
    this.isUser$.subscribe((isUser) => {
      this.isUser = isUser;
      if (this.isUser) {
        this.setCurrentCompany();
      }
    });

    this.initials$ = this.currentUser$.pipe(
      map((user) => user?.firstName.charAt(0) + "" + user?.lastName.charAt(0))
    );
    this.initials$.subscribe((initials) => {
      this.initials = initials;
    });

    this.loggedIn$.subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
      this.setCurrentUser();
    });

    this.setLoggedIn();

    this.authService.setLogger(
      new Logger({
        logLevel: LogLevel.Error,
        loggerCallback: (logLevel, message, piiEnabled) => {
          console.log("MSAL Logging: ", message);
        },
      })
    );
  }

  setLoggedIn() {
    this.loaded = false;
    const account = this.authService.instance.getAllAccounts()[0];

    if (account) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
    this.loggedIn$.next(this.loggedIn);
  }

  async setCurrentUser() {
    this.loaded = false;
    if (this.loggedIn) {
      await this.userService
        .GetCurrentUser()
        .subscribe({
          next: (user) => {
            this.currentUserSubject.next(user);
          }
        });
    } else {
      this.currentUserSubject.next(null);
    }
  }

  async setCurrentCompany() {
    this.loaded = false;
    if (this.currentUser) {
      if (this.isUser) {
        this.currentCompanySubject.next(null);
      } else {
        await this.companyService
          .GetCurrentCompany()
          .toPromise()
          .then((company) => {
            this.currentCompanySubject.next(company);
          });
      }
    } else {
      this.currentCompanySubject.next(null);
    }
  }

  async setCompanyLicense() {
    this.loaded = false;
    if (hasValue(this.currentCompany?.id)) {


      this.licenseService
        .GetAllForCompany({ companyId: this.currentCompany.id })
        .subscribe({
          next: (licenses) => {
            const license = licenses[0];
            if (hasValue(license)) {
              this.companyLicenseSubject.next(license);
            } else {
              this.companyLicenseSubject.next(null);
            }
          },
        });
    } else {
      this.companyLicenseSubject.next(null);
    }
  }

  setHasCompanyLicense() {
    this.loaded = false;
    if (hasValue(this.companyLicense)) {
      this.hasCompanyLicenseSubject.next(true);
      this.loaded = true;
    } else if (hasValue(this.currentUser.companyId)) {
      this.licenseService.ExistsForCompany({ companyId: this.currentUser.companyId }).subscribe({
        next: (hasCompanyLicense) => {
          this.hasCompanyLicenseSubject.next(hasCompanyLicense);
          this.loaded = true;
        }
      });
    } else {
      this.hasCompanyLicenseSubject.next(false);
      this.loaded = true;
    }
  }

  getAllAccounts() {
    return this.authService.instance.getAllAccounts();
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authResult$ = this.authService.loginPopup({
        ...this.msalGuardConfig.authRequest,
        extraQueryParameters: { ui_locales: "fr" },
      } as PopupRequest);

      this.authResult$.subscribe((authResult) => {
        const authres = authResult as AuthenticationResult;

        this.authService
          .acquireTokenPopup({
            scopes: [].concat.apply(
              [],
              Array.from(protectedResourceMapConfig.values())
            ),
          })
          .subscribe((authresult) => {
            this.setLoggedIn();
          });
      });
    } else {
      this.authResult$ = this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        extraQueryParameters: { ui_locales: "fr" },
      } as RedirectRequest);
    }
  }

  signup() {
    const request = {
      ...this.msalGuardConfig.authRequest,
      extraQueryParameters: {
        option: "signup",
        ui_locales: "fr",
      },
    } as RedirectRequest;
    this.authService.loginRedirect(request);
  }

  resetPassword() {
    const request = {
      ...this.msalGuardConfig.authRequest,
      extraQueryParameters: {
        ui_locales: "fr",
        option: "forgotpassword",
      },
    } as RedirectRequest;
    this.authService.loginRedirect(request);
  }

  logout(logoutRequest: EndSessionRequest = null): Observable<void> {
    if (logoutRequest === null) {
      return this.authService.logout();
    }
    return this.authService.logout(logoutRequest);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  getInitials() {
    if (this.currentUser) {
      return (
        this.currentUser.firstName.charAt(0) +
        "" +
        this.currentUser.lastName.charAt(0)
      );
    } else {
      return "";
    }
  }
}
