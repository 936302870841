import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ItemsService } from "app/api/content-read/services";
import { ApiConfiguration as __Configuration } from "../api/content-read/api-configuration";

@Injectable({
  providedIn: "root",
})
export class SortContentItemsService extends ItemsService {
  constructor(
    config: __Configuration,
    http: HttpClient,
    httpBackEnd: HttpBackend
  ) {
    http = new HttpClient(httpBackEnd);
    super(config, http);
  }

  sortSubItems(item: any, depth: number = 5): void {
    if (depth <= 0 || !item) {
      return;
    }
    const items: any[] = item.Items;
    if (items) {
      items.sort(function (a, b) {
        return a.order - b.order;
      });
      items.forEach(subItem => {
        this.sortSubItems(subItem, depth - 1);
      });
    } else {
      return;
    }
  }
}
