/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for CmApi services
 */
@Injectable({
  providedIn: 'root',
})
export class CmApiConfiguration {
  rootUrl: string = '';
}

export interface CmApiConfigurationInterface {
  rootUrl?: string;
}
